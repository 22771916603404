import confirmSignageModalTemplate from './confirm-signage.modal.html';

(function () {
    angular.module("cardspotWeb.areas.enterSales")
        .controller("EnterSales", EnterSales);

    EnterSales.$inject = ["$scope", "$timeout", "$location", "cardFees", "appConfig", "preActivationService", "sundryService", "NAV_URLS", "EVENT_NAMES", "$uibModal", "authService"];

    function EnterSales($scope, $timeout, $location, cardFees, appConfig, preActivationService, sundryService, NAV_URLS, EVENT_NAMES, $uibModal, authService) {
        var model = this;

        model.confirmSignageDialog = confirmSignage;

        function confirmSignage() {
            appConfig.getDisplayFeeBasedSignageMessageFlag(appConfig.selectedMerchantGroup().uniqueTag).then(function (response) {
                var displayFeeBasedSignageMessage = response.data;
                if (!authService.signageConfirmed() && displayFeeBasedSignageMessage && !isSundryPath()) {
                    var modalInstance = $uibModal.open({
                        template: confirmSignageModalTemplate,
                        controller: "SignageConfirm",
                        controllerAs: "model",
                        backdrop: "static",
                        keyboard: false,
                        size: "sm"
                    });
                }
            });
        }

        function cardActivationsComplete() {
            cardFees.getFees(
                appConfig.selectedMerchantGroup().uniqueTag,
                preActivationService.getProgramUniqueTag(),
                preActivationService.getCardsPreActivated()
                )
                .then(function () {
                    $timeout(function () {
                        $scope.$broadcast(EVENT_NAMES.SHOWING_SALE_SUMMARY, {});
                        $scope.$broadcast(EVENT_NAMES.ON_CARD_PREACTIVATED_WITH_CODE);
                        model.changeActiveView(model.VIEW_NAMES.SUMMARY);
                    });
            });
        }

         function sundrySalesComplete() {
            $timeout(function () {
                $scope.$broadcast(EVENT_NAMES.SHOWING_SALE_SUMMARY, {});
                model.changeActiveView(model.VIEW_NAMES.SUMMARY);
            });
        }

         $scope.salesComplete = function () {
            if(preActivationService.hasCards()){
                cardActivationsComplete();
            } else {
                sundrySalesComplete();
            }
        };

        $scope.saleCompleted = function () {
            $scope.$broadcast(EVENT_NAMES.SHOWING_SALE_COMPLETE, {});
            model.changeActiveView(VIEW_NAMES.SALE_COMPLETE);
        };

        $scope.editCards = function() {
            showCardActivations();
        };

         $scope.editSundries = function() {
            showSundrySales();
        };

        $scope.startOver = function() {
            isSundryPath() ? showSundrySales() : showCardActivations();
        };

        function showCardActivations() {
            $scope.$broadcast("showing-card-activations", {});
            model.changeActiveView(VIEW_NAMES.CARD_ACTIVATIONS);
        }

         function showSundrySales() {
            $scope.$broadcast("showing-sundry-sales", {});
            model.changeActiveView(VIEW_NAMES.SUNDRY_SALES);
        }

        var VIEW_NAMES = {
            CARD_ACTIVATIONS: "card-activations",
            SUMMARY: "summary",
            SALE_COMPLETE: "sale-complete",
            SUNDRY_SALES: "sundry-sales"
        };
        model.VIEW_NAMES = VIEW_NAMES;
        var activeView = isSundryPath() && !sundryService.hasSundries() ? VIEW_NAMES.SUNDRY_SALES : VIEW_NAMES.CARD_ACTIVATIONS;

        model.viewVisible = function (viewName) {
            return viewName === activeView;
        };

        model.changeActiveView = function (view) {
            activeView = view;
        };

        function isSundryPath(){
            return $location.path() === NAV_URLS.ENTER_SUNDRIES;
        }

        $scope.isSundryPath = function(){
            return isSundryPath();
        };
    }
}());