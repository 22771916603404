﻿import htmlTemplate from './sale-complete.directive.html';

(function() {
    "use strict";

    angular.module("cardspotWeb.areas.enterSales")
        .directive("emlSaleComplete", configDirective);

    function configDirective() {
        return {
            restrict: "E",
            scope: {
                startOver: "=",
                isTrailingSymbol: "=",
                currencySeparators: "=",
                fractionSize: "="
            },
            controller: SaleSummaryController,
            controllerAs: "model",
            template: htmlTemplate
        };
    }

	SaleSummaryController.$inject = ["$q", "$scope", "$translate", "activationService", "api", "notify", "receipts", "appConfig", "NAV_URLS", "EVENT_NAMES", "authService", "settings", "FileSaver", "Blob"];

	function SaleSummaryController($q, $scope, $translate, activationService, api, notify, receipts, appConfig, NAV_URLS, EVENT_NAMES, authService, settings, FileSaver, Blob) {
        var model = this;
        model.NAV_URLS = NAV_URLS;
        model.getReceiptHtml = getReceiptHtml;
        model.enterAnotherSale = enterAnotherSale;
        model.showEmailReceipt = showEmailReceipt;
        model.emailReceiptVisible = false;
        model.emailInvoiceVisible = false;
        model.email = undefined;
        model.cancelEmailReceipt = cancelEmailReceipt;
        model.sendEmailReceipt = sendEmailReceipt;
        model.sendEmailInvoice = sendEmailInvoice;
        model.showEmailInvoice = showEmailInvoice;
        model.cancelEmailInvoice = cancelEmailInvoice;
        model.supportsCustomerAtCardLevel = appConfig.selectedMerchantGroup().supportsCustomerAtCardLevel;
        model.hasPrinted = false;
        model.canPrint = canPrint;
        model.markAsPrinted = markAsPrinted;
        model.getInvoice = getInvoice;
        model.getIndividualReceiptsHtml = getIndividualReceiptsHtml;
        model.showIndividualReceiptButton = showIndividualReceiptButton;     

        settings.getSettings()
                .then(response => { 
                    model.corporateInvoiceEnabled = response.data.corporateInvoiceEnabled;
                    model.individualCardReceiptsEnabled = response.data.individualCardReceiptsEnabled;
            });
        
        function canPrint() {
            if (!authService.isReprintDisabled()) {
                return true;
            }

            return !model.hasPrinted;
        }

        function markAsPrinted() {
            model.hasPrinted = true;
        }

		$scope.$on(EVENT_NAMES.SHOWING_SALE_COMPLETE, function () {
            updateModel();
        });

        function showEmailReceipt() {
            model.emailReceiptVisible = true;
            model.email = getCustomerEmail();
        }

        function cancelEmailReceipt() {
            model.email = undefined;
            model.emailReceiptVisible = false;
        }

        function showEmailInvoice() {
            model.emailInvoiceVisible = true;
            model.email = getCustomerEmail();
        }

        function cancelEmailInvoice() {
            model.email = undefined;
            model.emailInvoiceVisible = false;
        }

        function sendEmailReceipt() {
            notify.forFormSubmission($scope.emailReceiptForm, function() {
                notify.forHttp(api.activation.sendEmailReceipt(model.transactionId, model.email, $translate.currentLanguage().key), {
                        startTranslationId: "ENTER_SALES.SALE_SUMMARY.SENDING_RECEIPT_EMAIL_PROGRESS_TEXT",
                        endTranslationId: "ENTER_SALES.SALE_SUMMARY.SENT_RECEIPT_EMAIL_CONFIRMATION"
                    })
                    .then(function() {
                        cancelEmailReceipt();
                        activationService.resetActivationCardCustomerEmail();
                    });
            });
        }

        function sendEmailInvoice() {
            notify.forFormSubmission($scope.emailInvoiceForm, function() {
                notify.forHttp(api.activation.sendEmailInvoice(model.transactionId, model.email, $translate.currentLanguage().key), {
                        startTranslationId: "ENTER_SALES.SALE_SUMMARY.SENDING_RECEIPT_INVOICE_PROGRESS_TEXT",
                        endTranslationId: "ENTER_SALES.SALE_SUMMARY.SENT_RECEIPT_INVOICE_CONFIRMATION"
                    })
                    .then(function() {
                        cancelEmailInvoice();
                        activationService.resetActivationCardCustomerEmail();
                    });
            });
        }

        function getInvoice() { 
            notify.forHttp(api.activation.getInvoice(model.transactionId), {
                startTranslationId: "ENTER_SALES.SALE_COMPLETE.GETTING_INVOICE_MESSAGE"
            })
            .then(function (data) {
                var blob = new Blob([data], {
                    type: "application/pdf"
                });
                FileSaver.saveAs(blob, getInvoiceFileName());
            });
        }

        function getInvoiceFileName() {
            return $translate.instant("ENTER_SALES.SALE_COMPLETE.INVOICE_FILE_NAME", {orderId: model.transactionId}) || ("invoice-" + model.transactionId + ".pdf");
        }


        function enterAnotherSale() {
            $scope.startOver();
        }

        function updateModel() {
            var activation = activationService.mostRecentActivation();

            if (activation) {
                model.totalCards = activation.confirmation.cardsActivated;
                model.transactionId = activation.confirmation.systemTransactionId;
                model.totalAmountFunded = activation.confirmation.totalAmountFunded;
                model.totalFees = activation.confirmation.totalCardFees + activation.confirmation.totalTransactionFees;
                model.totalPayment = activation.confirmation.totalPayment;
                model.currencySymbol = activation.currencySymbol;
                model.totalSundries = activation.confirmation.sundryCount;
                model.totalSundryAmount = activation.confirmation.sundryTotal;
                model.totalTransactionAmount = model.totalAmountFunded + model.totalFees + model.totalSundryAmount;
                model.hasPrinted = false;
                model.customerIsCompany = activation.customer && activation.customer.customerType === "Company";
            }
        }

        function getReceiptHtml() {
            var deferred = $q.defer();
            receipts.getReceipt(model.transactionId, $translate.currentLanguage().key).then(function(receipt) {
                deferred.resolve(receipt.receiptHtml);
            }, function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function getIndividualReceiptsHtml() {
            var deferred = $q.defer();
            receipts.getIndividualReceipts(model.transactionId, $translate.currentLanguage().key, model.totalCards).then(function(receipt) {
                deferred.resolve(receipt.receiptHtml);
            }, function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function getCustomerEmail() {
            var email = activationService.getActivationCardCustomerEmail();

            if (email) {
                return email;
            }

            return undefined;
        }

        function showIndividualReceiptButton() {
            return model.individualCardReceiptsEnabled && model.totalCards > 1 && !model.supportsCustomerAtCardLevel;
        }

    }
}());
