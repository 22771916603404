﻿import htmlTemplate from './card-activations.directive.html';
import _ from 'underscore';

(function () {
	"use strict";

	angular.module("cardspotWeb.areas.enterSales")
		.directive("emlCardActivations", configDirective);

	function configDirective() {
		return {
			restrict: "E",
			scope: {
				cardsPreActivated: "=",
				cardActivationsComplete: "=",
				hasSundryTypes: "=",
				editSundries: "=",
				isTrailingSymbol: "=",
				currencySeparators: "=",
				fractionSize: "=",
				currentLanguage: "="
			},
			controller: CardActivationsController,
			controllerAs: "model",
			template: htmlTemplate
		};
	}

	CardActivationsController.$inject = ["$scope", "notify", "preActivationService", "appConfig", "authService", "customerService",
		"campaignService", "navigate", "$location", "lookupValues", "SF_EVENTS", "REGION_TYPES", "EVENT_NAMES", "CLAIMS", "$q",
		"$uibModal", "LOOKUP_TYPES", "$translate", "$window", "modal", "codeService", "$rootScope", "limitService"];

	function CardActivationsController($scope, notify, preActivationService, appConfig, authService, customerService,
		campaignService, navigate, $location, lookupValues, SF_EVENTS, REGION_TYPES, EVENT_NAMES, CLAIMS, $q,
		$uibModal, LOOKUP_TYPES, $translate, $window, modal, codeService, $rootScope, limitService) {
		var model = this;

		$scope.cardsPreActivated = preActivationService.getCardsPreActivated();
		model.preActivateCardByPrintText = preActivateCardByPrintText;
		model.preActivateGroupCardByFirstPrintText = preActivateGroupCardByFirstPrintText;
		model.preActivateGroupCardByLastPrintText = preActivateGroupCardByLastPrintText;
		model.cancelLookup = cancelLookup;
		model.clearCards = clearCards;
		model.cancelClearCards = cancelClearCards;
		model.removeCard = removeCard;
		model.cancelRemoveCard = cancelRemoveCard;
		model.isCardToBeRemoved = isCardToBeRemoved;
		model.minIssueAmountForCardType = minIssueAmountForCardType;
		model.maxIssueAmountForCardType = maxIssueAmountForCardType;
		model.amountTotal = amountTotal;
		model.canAddCampaigns = authService.canAddCampaigns() && !(appConfig.selectedMerchantGroup() && appConfig.selectedMerchantGroup().manageCampaigns);
		model.setPreActivationType = setPreActivationType;
		model.isPreActivationType = isPreActivationType;
		model.getCardQuantity = getCardQuantity;
		model.resetGroupLookup = resetGroupLookup;
		model.supportsCustomerAtCardLevel = appConfig.selectedMerchantGroup().supportsCustomerAtCardLevel;
		model.setCardCustomer = setCardCustomer;
		model.showCustomerEdit = showCustomerEdit;
		model.getCustomerDisplay = getCustomerDisplay;
		model.countrySelectionChanged = countrySelectionChanged;
		model.closeCustomerForm = closeCustomerForm;
		model.resetCustomer = resetCustomer;
		model.addNewCampaign = addNewCampaign;
		model.canActivateWithoutSwipe = authService.hasClaim(CLAIMS.ACTIVATE_WITHOUT_SWIPE);
		model.clearCampaign = clearCampaign;
		model.applyDefaultCampaign = appConfig.selectedMerchantGroup().defaultCampaignSelection;
		model.PRE_ACTIVATION_TYPE = LOOKUP_TYPES;
		model.removeCardConfirmModalPopup = removeCardConfirmModalPopup;
		model.removeCardConfirmModalPopupTrigger = removeCardConfirmModalPopupTrigger;
		model.removeAllCardsConfirmModalPopup = removeAllCardsConfirmModalPopup;
		model.removeAllCardsConfirmModalPopupTrigger = removeAllCardsConfirmModalPopupTrigger;
		model.customerInformationModalPopupTrigger = customerInformationModalPopupTrigger;
		model.removeActivationCustomer = removeActivationCustomer;
		model.currentLanguage = $scope.currentLanguage;
		model.groupActivationLoading = false;
		model.clickAndCollectEnabled = appConfig.selectedMerchantGroup().clickAndCollectEnabled;
		model.clickAndCollectCode;
		model.redeemCode = redeemCode;
		model.amountInputReadOnly = amountInputReadOnly;
		model.codeRedeemed = false;
		model.redeemedCode = {};
		$scope.codesRedeemed = codeService.getCodesRedeemed();
		$rootScope.programHvtLimits = [];

		$scope.datePopup = {
			opened: false
		};

		$scope.openDatePicker = function () {
			$scope.datePopup.opened = true;
		};

		updateActivationCustomer();


		function updateActivationCustomer() {
			model.activationCustomer = customerService.mapCustomerForActivation();
		}

		$scope.$on(EVENT_NAMES.SHOWING_SALE_COMPLETE, function () {
			updateActivationCustomer();
		});

		$scope.$on(EVENT_NAMES.ON_CULTURE_CHANGED, function (event, args) {
			model.currentLanguage = $translate.use();
		})

		function countrySelectionChanged() {
			model.currentCustomer.postalCode = null;
			model.currentCustomer.state = null;
			model.currentCustomer.province = null;

			if (model.currentCustomer.country) {
				lookupValues.getRegions(model.currentCustomer.country.countryKey)
					.then(function (regions) {
						model.regions = regions;
						model.showRegionSelection = model.currentCustomer.country && hasRegions() && model.currentCustomer.country.regionType !== REGION_TYPES.NONE;
					});
			}
		}

		function hasRegions() {
			return angular.isArray(model.regions) && model.regions.length > 0;
		}

		var currentPreActivationType = model.supportsCustomerAtCardLevel ? model.PRE_ACTIVATION_TYPE.INDIVIDUAL : null;
		function setPreActivationType(type) {
			currentPreActivationType = type;
			$rootScope.currentPreactivationType = type;
		}

		function isPreActivationType(type) {
			return type === currentPreActivationType;
		}

		$scope.cardKey = function (card) {
			return card.$$hashKey.replace(":", "");
		};

		model.card = {
			printText: undefined,
			firstPrintText: null,
			lastPrintText: null,
			groupCardCount: null
		};

		$scope.$on(SF_EVENTS.CARDSWIPE_SUCCESS, handleCardSwipeSuccess);

		function minIssueAmountForCardType(cardType) {
			var type = findCardTypeForName(cardType);
			return type ? type.minimumIssueAmount : null;
		}

		function maxIssueAmountForCardType(card) {
			var type = findCardTypeForName(card.currentCardType);
			return type ? type.maximumIssueAmount : null;
		}

		function findCardTypeForName(cardTypeName) {
			return _.find(model.cardTypes, function (aCardType) {
				return cardTypeName === aCardType.cardType;
			});
		}

		function resetLookup() {
			model.card.printText = null;
			$scope.preActivateCardForm.$setPristine();
			$scope.preActivateCardForm.cardNumber.$setPristine();
			notify.dismissErrorAlert();
		}

		function resetGroupLookup() {
			model.card.firstPrintText = null;
			model.card.lastPrintText = null;
			model.card.groupCardCount = null;
			model.firstGroupCard = null;
			model.lastGroupCard = null;
			model.firstCardPreactivated = false;
			model.lastCardPreactivated = false;
			$scope.preActivateGroupCardForm.$setPristine();
			notify.dismissErrorAlert();
		}

		function setFixedFundingAmounts() {
			if (model.haveCards) {
				$scope.cardsPreActivated.forEach(card =>
					card.amount = card.fixedFundingAmount ? card.fixedFundingAmount : card.amount);
			}
		}

		function getFilteredCampaigns() {
			getActiveMerchantGroupCampaigns().then(function () {

				var campaigns = [];

				if (appConfig.selectedMerchantGroup() && appConfig.selectedMerchantGroup().manageCampaigns) {
					// if the property has campaign management enabled, get those campaigns
					var merchantGroupCampaigns = model.merchantGroupCampaigns.map(function (camp) {
						return camp["description"];
					});

					campaigns = campaigns.concat(merchantGroupCampaigns);
				} else {

					// Display program campaigns when manageCampaigns is turned off.
					var programCampaigns = preActivationService.getCampaigns();
					campaigns = campaigns.concat(programCampaigns);
				}
				// remove any duplicate campaign names
				model.campaigns = Array.from(new Set(campaigns));
			});
		}

		function updateModel() {
			$scope.cardsPreActivated = preActivationService.getCardsPreActivated();
			model.haveCards = $scope.cardsPreActivated.length > 0;
			model.currencySymbol = model.haveCards ? $scope.cardsPreActivated[0].bin.currency.currencySymbol : "";
			model.maxActivationAmount = model.haveCards ? $scope.cardsPreActivated[0].bin.currency.amlActivationThresholdAmount : 0;
			model.cardTypes = preActivationService.getCardTypes();
			getFilteredCampaigns();
			if (model.applyDefaultCampaign && $scope.cardsPreActivated.length > 1) {
				const currentCard = $scope.cardsPreActivated[0];
				currentCard.campaign = $scope.cardsPreActivated[$scope.cardsPreActivated.length - 1].campaign;
				currentCard.showAddCampaign = model.canAddCampaigns && !model.campaigns.includes(currentCard.campaign);
			}
			setFixedFundingAmounts();
		}

		updateModel();

		function setCardCustomer() {
			var currentCard = findCard(model.currentCustomer && model.currentCustomer.card ? model.currentCustomer.card : preActivationService.currentPreActivationCard());
			currentCard.customer = model.currentCustomer;
			currentCard.customer.card = currentCard;
			model.showCustomerForm = false;
		}

		function initCardCustomer(card) {
			if (!model.supportsCustomerAtCardLevel) {
				return;
			}

			lookupValues.getCountries()
				.then(function (data) {
					model.countries = data;
					model.showCustomerForm = true;
					model.merchantGroupCountry = preActivationService.getMerchantGroupCountry();
					model.allowMarketingOptIn = preActivationService.getAllowMarketingOptIn();
					card.customer = model.activationCustomer ? angular.copy(model.activationCustomer) : { country: findCountry(model.merchantGroupCountry) };
					model.currentCustomer = card.customer;
					model.currentCustomer.card = angular.copy(card);
					if (!model.activationCustomer) {
						countrySelectionChanged();
						showCustomerEdit(card);
					} else {
						model.showCustomerForm = false;
					}
				});
		}

		function getCustomerDisplay(card) {
			var matchingCard = findCard(card);

			return matchingCard && matchingCard.customer ? matchingCard.customer.firstName + " " + matchingCard.customer.lastName : "";
		}

		function findCard(card) {
			return _.find($scope.cardsPreActivated, preActivatedCard => preActivatedCard.printText === card.printText);
		}

		function closeCustomerForm() {
			model.showCustomerForm = false;
		}

		function resetCustomer() {
			var card = findCard(model.currentCustomer.card);
			if (card) {
				var customer = { country: findCountry(model.merchantGroupCountry) };
				card.customer = customer;
				model.currentCustomer = customer;
				model.currentCustomer.card = card;
				countrySelectionChanged();
			}
		}

		function showCustomerEdit(card) {
			// need to make sure region is loaded correctly
			var customer = angular.copy(card.customer);
			model.currentCustomer = card.customer;
			countrySelectionChanged();
			model.currentCustomer = customer;
			model.currentCustomer.card = card;
			model.showCustomerForm = true;
		}

		function findCountry(customerCountry) {
			return _.find(model.countries, function (country) {
				return country.countryName === customerCountry;
			});
		}

		$scope.$on("showing-card-activations", function () {
			updateModel();
			if (!authService.signageConfirmed()) {
				navigate.toConfirmSignage($location.path());
			}
		});

		model.maxAcivationAmountExceeded = false;

		$scope.$watch("model.amountTotal()", function (newTotal) {
			if (newTotal && newTotal > 0) {
				model.maxAcivationAmountExceeded = newTotal > model.maxActivationAmount;
			}
		});

		function amountTotal() {
			if (!$scope.cardsPreActivated) {
				return 0;
			}
			return _.reduce($scope.cardsPreActivated, function (memo, card) {
				var qty = angular.isArray(card.cards) ? card.cards.length : 1;
				return memo + (card.amount ? card.amount * qty : 0);
			}, 0);
		}

		function doPreActivateCard(printText, primaryAccountNumber) {
			notify.dismissErrorAlert();

			// ensure not already added
			if (preActivationService.isDuplicateCard(printText, primaryAccountNumber)) {
				notify.showError("ENTER_SALES.VALIDATION_TEXT.CARD_ALREADY_ADDED");
				return;
			}

			var preActivateCardFunction;
			if (printText) {
				// need to determine if this should be treated as printText or externalId
				// if startsWith alpha, then treat as externalid search
				if (/^[A-Za-z]/.test(printText)) {
					preActivateCardFunction = preActivationService.preActivateByExternalId(printText, isPreActivationType(model.PRE_ACTIVATION_TYPE.GROUP));
				} else {
					preActivateCardFunction = preActivationService.preActivateByPrintText(printText, isPreActivationType(model.PRE_ACTIVATION_TYPE.GROUP));
				}
			} else if (primaryAccountNumber) {
				preActivateCardFunction = preActivationService.preActivateBySwipe(primaryAccountNumber, isPreActivationType(model.PRE_ACTIVATION_TYPE.GROUP));
			} else {
				notify.showError("LOOKUP_CARD.FORM.INVALID_PRINT_TEXT_OR_SWIPE");
				return;
			}

			notify.forHttp(preActivateCardFunction, { startTranslationId: "LOOKUP_CARD.FORM.PROGRESS_TEXT" })
				.then(function (data) {
					validatePreActivation();
					if (data.canActivate) {
						initCardCustomer(data.card);
						resetLookup();
						updateModel();
						setPreactivatedCard(data.card);
						if (model.clickAndCollectEnabled && isPreActivationType(model.PRE_ACTIVATION_TYPE.REDEEM_CODE)) {
							redeemCodeModalPopup();
						}
						if (model.supportsCustomerAtCardLevel) {
							customerInformationModalPopupTrigger();
						}
						$rootScope.programHvtLimits = getProgramHvtLimits(data.card.programUniqueTag);
					}
				}, function () {
					model.groupActivationLoading = false;
				});
		}

		function setPreactivatedCard(card) {
			if (!isPreActivationType(model.PRE_ACTIVATION_TYPE.GROUP)) {
				return;
			}

			if (!model.firstCardPreactivated) {
				model.card.firstPrintText = Number(card.printText);
				model.firstGroupCard = card;
				model.firstCardPreactivated = true;
				model.groupActivationLoading = false;
				return;
			}
			if (!model.lastCardPreactivated) {
				model.card.lastPrintText = Number(card.printText);
				model.lastGroupCard = card;
				model.lastCardPreactivated = true;
			}
			if (model.firstCardPreactivated && model.lastCardPreactivated) {
				if (model.firstGroupCard.cardOrderId !== model.lastGroupCard.cardOrderId) {
					notify.showError("ENTER_SALES.FORM.VALIDATION_TEXT.MULTIPLE_CARD_ORDERS");
					return;
				}
				// now have the first and last preactivated,
				// so now need to do the preActivateGroup
				doPreActivateGroupCards(model.firstGroupCard.primaryAccountNumber, model.lastGroupCard.primaryAccountNumber);
			}
		}

		function doPreActivateGroupCards(firstCardNumber, lastCardNumber) {
			notify.dismissErrorAlert();

			notify.forHttp(preActivationService.preActivateGroup(firstCardNumber, lastCardNumber), { startTranslationId: "LOOKUP_CARD.FORM.PROGRESS_TEXT" })
				.then(function () {
					validatePreActivation();
					resetGroupLookup();
					updateModel();
					model.groupActivationLoading = false;
				}, function () {
					model.groupActivationLoading = false;
				});
		}

		function preActivateCardByPrintText() {
			notify.forFormSubmission($scope.preActivateCardForm, doPreActivateCard(model.card.printText));
		}

		function preActivateGroupCardByFirstPrintText() {
			notify.forFormSubmission($scope.preActivateGroupCardForm, doPreActivateCard(model.card.firstPrintText));
		}

		function preActivateGroupCardByLastPrintText() {
			model.groupActivationLoading = true;
			notify.forFormSubmission($scope.preActivateGroupCardForm, doPreActivateCard(model.card.lastPrintText));
		}

		function handleCardSwipeSuccess(event, swipe) {
			doPreActivateCard(undefined, swipe.primaryAccountNumber);
		}

		function validatePreActivation() {
			if (preActivationService.hasErrors()) {
				model.groupActivationLoading = false;
				notify.showError(preActivationService.errors().join(", "));
			}
		}

		function removeCardConfirmationVisible(isVisible) {
			model.showRemoveCardConfirmation = isVisible;
			if (isVisible) {
				clearCardsConfirmationVisible(false);
			}
		}

		function clearCardsConfirmationVisible(isVisible) {
			model.showClearCardsConfirmation = isVisible;
			if (isVisible) {
				removeCardConfirmationVisible(false);
			}
		}

		function clearCards(confirmed) {
			if (confirmed) {
				preActivationService.reset();
				updateModel();
				notify.success("ENTER_SALES.CONFIRMATION.ALL_CARDS_REMOVED", undefined, 1000);
			}
		}

		function cancelClearCards() {
			clearCardsConfirmationVisible(false);
		}

		function removeCard(card, confirmed) {
			if (confirmed) {
				preActivationService.removeCard(card);
				model.cardToRemove = undefined;
				updateModel();
				notify.success("ENTER_SALES.CONFIRMATION.CARD_REMOVED", undefined, 1000);
			}
		}

		function cancelRemoveCard() {
			removeCardConfirmationVisible(false);
			model.cardToRemove = undefined;
		}

		function isCardToBeRemoved(card) {
			return card && model.cardToRemove && card.primaryAccountNumber === model.cardToRemove.primaryAccountNumber;
		}

		function cancelLookup() {
			resetLookup();
		}

		function getCardQuantity(card) {
			return angular.isArray(card.cards) ? card.cards.length : 1;
		}

		function addNewCampaign(card) {
			card.showAddCampaign = !card.showAddCampaign;
		}

		$scope.$watch(function () {
			return window.hidSwipeData ? window.hidSwipeData.primaryAccountNumber : null;
		}, handleHidSwipe);

		function handleHidSwipe() {
			if (window.hidSwipeData && window.hidSwipeData.primaryAccountNumber) {
				doPreActivateCard(undefined, window.hidSwipeData.primaryAccountNumber);
				window.hidSwipeData.primaryAccountNumber = null;
			}
		}

		$window.handleHidSwipe = function (primaryAccountNumber) {
			if (primaryAccountNumber) {
				doPreActivateCard(undefined, primaryAccountNumber);
			}
		}

		function clearCampaign($event, $select) {
			$event.stopPropagation();
			$select.selected = undefined;
			$select.search = undefined;
		}

		function getActiveMerchantGroupCampaigns() {
			var deferred = $q.defer();
			campaignService.getAllMerchantGroupCampaigns()
				.then(function (response) {
					var campaigns = response.data;
					deferred.resolve(response.data);
					model.merchantGroupCampaigns = appConfig.selectedMerchantGroup() && appConfig.selectedMerchantGroup().manageCampaigns ? campaigns.filter(function (camp) {
						return camp.isActive === true;
					}) : [];
				}, function (error) {
					deferred.reject(error);
				});
			return deferred.promise;
		}

		function removeCardConfirmModalPopup(card) {
			var removeCardModalInstance = $uibModal.open({
				templateUrl: "enter-sales/modal/removeCardConfirmDialog.html",
				backdrop: "static",
				keyboard: false,
				size: "sm",
				controller: "ConfirmModal",
				scope: $scope
			})

			removeCardModalInstance.result.then(function (status) {
				removeCard(card, status);
				var codeRedeemed = codeService.getCodesRedeemed()[0];
				if (isPreActivationType(model.PRE_ACTIVATION_TYPE.REDEEM_CODE) && codeRedeemed) {
					redeemCodeCancel(codeRedeemed);
				}
			});
		}

		function removeCardConfirmModalPopupTrigger(card) {
			model.removeCardConfirmModalPopup(card);
		}

		function removeAllCardsConfirmModalPopup() {
			var removeAllCardsConfirmModalInstance = $uibModal.open({
				templateUrl: "enter-sales/modal/removeAllCardsConfirmDialog.html",
				backdrop: "static",
				keyboard: false,
				size: "sm",
				controller: "ConfirmModal",
				scope: $scope
			})

			removeAllCardsConfirmModalInstance.result.then(function (status) {
				clearCards(status);
				if (isPreActivationType(model.PRE_ACTIVATION_TYPE.REDEEM_CODE)) {
					codeService.cancelAll();
				}
			});
		}

		function removeAllCardsConfirmModalPopupTrigger() {
			model.removeAllCardsConfirmModalPopup();
		}

		function customerInformationModalPopup() {
			var customerInformationModalInstance = $uibModal.open({
				templateUrl: "enter-sales/modal/customerInformationDialog.html",
				backdrop: "static",
				keyboard: false,
				controller: "ConfirmModal",
				scope: $scope
			})

			customerInformationModalInstance.result.then(function () {
				setCardCustomer();
			});
		}

		function customerInformationModalPopupTrigger(card) {
			// need to make sure region is loaded correctly
			if (card) {
				var customer = angular.copy(card.customer);
				model.currentCustomer = card.customer;
				countrySelectionChanged();
				model.currentCustomer = customer;
				model.currentCustomer.card = card;
			}

			customerInformationModalPopup();
		}

		function removeActivationCustomer() {
			customerService.reset();
			updateActivationCustomer();
		}

		function redeemCode() {
			var card = $scope.cardsPreActivated[0];

			notify.forHttp(codeService.redeemStart(model.clickAndCollectCode, card.programUniqueTag, authService.currentUsername(), card.cardId, card.externalId), { startTranslationId: "ENTER_SALES.CLICK_N_COLLECT_FORM.REDEEM_PROGRESS_TEXT" })
				.then(function (response) {
					if (response.succeeded) {
						model.codeRedeemed = true;
						card.amount = response.data.amount.toFixed(2);
						setRedeemedCode(response.data, card.externalId);

						model.redeemCodeModalInstance.close("close");
					}
				})
		}

		function redeemCodeModalPopup() {
			model.redeemCodeModalInstance = modal.trigger("enter-sales/modal/redeemCodeDialog.html", $scope, "sm");

			model.redeemCodeModalInstance.result.then(function (status) {
				if (!status) {
					redeemCodeCancel();
				}
				resetRedeemedCode();
			});
		}

		function redeemCodeCancel(redeemedCode) {
			model.clickAndCollectCode = null;
			var codeRedeemed = redeemedCode ? codeService.isCodeRedeemed(redeemedCode.code) : false;

			if (codeRedeemed) {
				notify.forHttp(codeService.redeemCancel(redeemedCode), { startTranslationId: "ENTER_SALES.CLICK_N_COLLECT_FORM.CANCEL_PROGRESS_TEXT" })
					.then(function (response) {
						if (response.succeeded) {
							notify.success("ENTER_SALES.CLICK_N_COLLECT_FORM.CANCEL_COMPLETE_TEXT");
						}
					}
					);

			}
		}

		function amountInputReadOnly(card) {
			return card.fixedFundingAmount || (model.clickAndCollectEnabled && isPreActivationType(model.PRE_ACTIVATION_TYPE.REDEEM_CODE));
		}

		function setRedeemedCode(data, cardId) {
			model.redeemedCode.code = data.code;
			model.redeemedCode.amount = data.amount.toFixed(2);
			model.redeemedCode.currency = data.currency;
			model.redeemedCode.sessionId = data.sessionId;
			model.redeemedCode.cardId = cardId;
		}

		function resetRedeemedCode() {
			model.redeemedCode.code = null;
			model.redeemedCode.currency = null;
			model.redeemedCode.sessionId = null;
			model.redeemedCode.amount = null;

			model.clickAndCollectCode = null;
		}

		function getProgramHvtLimits(programUniqueTag) {
			return limitService.getProgramHvtLimits(programUniqueTag);
		}
	}
}());
