﻿import enterSalesTemplate from './enter-sales.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
        .config(configEnterSales);

    configEnterSales.$inject = ["$routeProvider", "NAV_URLS", "CLAIMS"];

    function configEnterSales($routeProvider, NAV_URLS, CLAIMS) {

        $routeProvider            
            .when(NAV_URLS.ENTER_SALES, {
                template: enterSalesTemplate,
                controller: "EnterSales",
                controllerAs: "model",
                requiredClaim: CLAIMS.ACTIVATE_CARD

            });
    }
}());