﻿(function () {
    "use strict";
    angular.module("angular.emlpayments")
            .constant("SF_EVENTS", {
                "CARDSWIPE_SUCCESS": "eml-cardSwipe-success",
                "GLOBAL_SETTINGS_CHANGED": "eml-global-settings-changed",
                "MERCHANT_GROUP_CHANGED": "eml-merchant-group-changed",
                "LOGGED_IN_SUCCESS": "eml-logged-in-success",
                "PROGRAM_SELECTED": "program-selected",
                "HVT_REQUEST_PURCHASER_STEP_SELECTED": "hvt-request-purchaser-step-selected",
                "HVT_REQUEST_COMPANY_STEP_SELECTED": "hvt-request-company-step-selected",
                "HVT_REQUEST_SUCCEEDED": "hvt-request-succeeded"
            })


    ;
}());