(function () {
	"use strict";

	angular.module("angular.emlpayments")
    .factory("receipts", serviceFactory);

	serviceFactory.$inject = ["$q", "$log", "api", "notify", "appConfig"];

	function serviceFactory($q, $log, api, notify, appConfig) {

		return {
			getReceipt,	
			sendEmailReceipt,		
			getReceiptItems,
			setReceiptItems,
			getReceiptCultures,
			getIndividualReceipts
		};


		function getReceipt(transactionId, cultureName, isReprint = false, cardFrontOrderId = null) {
			var deferred = $q.defer();
			notify.forHttp(api.activation.getEmailReceipt(transactionId, cultureName, isReprint, cardFrontOrderId), { startTranslationId: "SALES_RECEIPT.GET_RECEIPT_PROGRESS_TEXT" })
				.then(function (response) {
					var receipt = {
						transactionId: transactionId,
						receiptHtml: response.receiptHtml
					};
					deferred.resolve(receipt);
				}, function (error) {
					deferred.reject(error);
				});			
			return deferred.promise;
		}

		function getIndividualReceipts(transactionId, cultureName, totalCards, isReprint = false) {
			var deferred = $q.defer();
			notify.forHttp(api.activation.getIndividualCardReceipts(transactionId, cultureName, totalCards, isReprint), { startTranslationId: "SALES_RECEIPT.GET_RECEIPT_PROGRESS_TEXT" })
				.then(function (response) {
					var receipt = {
						transactionId: transactionId,
						receiptHtml: response.receiptHtml
					};
					deferred.resolve(receipt);
				}, function (error) {
					deferred.reject(error);
				});			
			return deferred.promise;
		}
		
		function sendEmailReceipt(transactionId, email, cultureName){
			return api.activation.sendEmailReceipt(transactionId, email, cultureName);			
		}				
		
		function getReceiptItems(cultureName) {
			return api.receipts.getReceiptItems(appConfig.selectedMerchantGroup().uniqueTag, cultureName);						
		}
		
		function setReceiptItems(cultureName, receiptItems, termsAndConditions){
			return api.receipts.setReceiptItems(appConfig.selectedMerchantGroup().uniqueTag, cultureName, receiptItems, termsAndConditions);
		}

		function getReceiptCultures(){
			return api.receipts.getReceiptCultures(appConfig.selectedMerchantGroup().uniqueTag);
		}			
	}
}());