import htmlTemplate from './address.directive.html';

(function () {
	"use strict";

	angular.module("cardspotWeb.areas.global")
		.directive("emlAddress", addressDirective);

	function addressDirective() {
		return {
			restrict: "E",
			scope: {
				address: "="
			},			
			template: htmlTemplate
		};
	}
}());
