import htmlTemplate from './company-information.directive.html';

(function() {
    "use strict";

    angular.module("cardspotWeb.areas.hvtRequest")
        .directive("emlCompanyInformation", configDirective);

    function configDirective() {
        return {
            restrict: "E",
            scope: {
                companyInformation:"=",
                companyInformationComplete:"=",
                countries:"=",
                previousStep:"="
            },
            controller: CompanyInformationController,
            controllerAs: "model",
            template: htmlTemplate
        };
    }

	CompanyInformationController.$inject = ["$q", "$scope", "$rootScope", "$translate", "lookupValues", "REGION_TYPES", "SF_EVENTS"];

	function CompanyInformationController($q, $scope, $rootScope, $translate, lookupValues, REGION_TYPES, SF_EVENTS) {
        var model = this;
        model.regions;
        model.showRegionSelection = false;
        model.companyInformationRegionModel = companyInformationRegionModel;
        model.countrySelectionChanged = countrySelectionChanged;

        $rootScope.$on(SF_EVENTS.HVT_REQUEST_COMPANY_STEP_SELECTED, function(event, args) {
            initRegions();
        })

        function countrySelectionChanged() {
            $scope.companyInformation.postalCode = null;
            $scope.companyInformation.state = null;
            $scope.companyInformation.province = null;
            initRegions();
        }

        function initRegions() {
            if ($scope.companyInformation.country) {
                lookupValues.getRegions($scope.companyInformation.country.countryKey)
                    .then(function (regions) {
                        model.regions = regions;
                        model.showRegionSelection = $scope.companyInformation.country && hasRegions() && $scope.companyInformation.country.regionType !== REGION_TYPES.NONE;
                    });
            }
        }

        function companyInformationRegionModel(newRegion) {
            if ($scope.companyInformation && $scope.companyInformation.country) {
                if ($scope.companyInformation.country.regionType === REGION_TYPES.STATE) {
                    return arguments.length ? ($scope.companyInformation.state = newRegion) : $scope.companyInformation.state ?? "";
                } else {
                    return arguments.length ? ($scope.companyInformation.province = newRegion) : $scope.companyInformation.province ?? "";
                }
            }
        }

        function hasRegions() {
            return angular.isArray(model.regions) && model.regions.length > 0;
        }
        
    }
}());