﻿(function() {
    "use strict";

    angular.module("angular.emlpayments")
        .directive("emlFocusWhenTrue", focusWhenTrue);

    focusWhenTrue.$inject = ["$timeout"];

    function focusWhenTrue($timeout) {
        return function (scope, element, attrs) {
            scope.$watch(attrs.emlFocusWhenTrue,
                function(newValue) {
                    if (newValue) {
                        $timeout(function() {
                            element[0].focus();
                        });
                    }
                }, true);
        };
    }
}());