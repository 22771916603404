import _ from 'underscore';

(function () {
    "use strict";

    angular.module("angular.emlpayments")
        .factory("codeService", codeServiceFactory);

        codeServiceFactory.$inject = ["api", "appConfig", "$q"];

    function codeServiceFactory(api, appConfig, $q) {
        var codesRedeemed = [];

        return {
            redeemStart,
            redeemComplete,
            redeemCancel,
            getCodesRedeemed,
            removeRedeemedCode,
            addRedeemedCode,
            isCodeRedeemed,
            cancelAll
        };

        function hasCodes() {
            return angular.isArray(codesRedeemed) && codesRedeemed.length > 0;
        }

        function isCodeRedeemed(code) {
            var index = codesRedeemed.findIndex(c => c.code === code);
            if (index === -1)
                return false;

            return true;
        }

        function removeRedeemedCode(clickNCollectCode) {
            codesRedeemed = _.reject(codesRedeemed, function (aCode) {
                return clickNCollectCode === aCode.code;
            });
            if (!hasCodes()) {
                reset();
            }
        }

        function addRedeemedCode(clickNCollectCode, cardId, program, externalId) {
            var index = codesRedeemed.findIndex(c => c.code === clickNCollectCode.code);

            if (index === -1) {
                clickNCollectCode.cardId = cardId;
                clickNCollectCode.programUniqueTag = program;
                clickNCollectCode.externalId = externalId;
                codesRedeemed.unshift(clickNCollectCode);
            }
        }

        function reset() {
            codesRedeemed = angular.copy([], codesRedeemed);
        }

        function getCodesRedeemed() {
            return codesRedeemed;
        }

        function cancelAll() {
            codesRedeemed.forEach(c => redeemCancel(c));
        }

        function redeemStart(code, program, username, cardId, externalId) {
            return start(api.code.redeemStart(
                code,
                program,
                appConfig.selectedMerchantGroup().uniqueTag,
                username), cardId, program, externalId);
        }

        function start(apiPromise, cardId, program, externalId) {
            var deferred = $q.defer();
            apiPromise
                .then(function (response) {
                    deferred.resolve(response);
                    addRedeemedCode(response.data.data, cardId, program, externalId);
                },
                function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        function redeemComplete(code, program, sessionId, externalId, cardId, buyerDetails = null) {
            return complete(api.code.redeemComplete(
                code,
                program,
                appConfig.selectedMerchantGroup().uniqueTag,
                sessionId,
                externalId,
                cardId,
                buyerDetails
            ), code);
        }

        function complete(apiPromise, code) {
            var deferred = $q.defer();
            apiPromise
                .then(function (response) {
                    removeRedeemedCode(code);
                },
                function (error) {
                    deferred.reject(error);
                })
        }

        function redeemCancel(redeemedCode) {
            return cancel(api.code.redeemCancel(
                redeemedCode.code,
                redeemedCode.programUniqueTag,
                appConfig.selectedMerchantGroup().uniqueTag,
                redeemedCode.sessionId
            ), redeemedCode.code);
        }

        function cancel(apiPromise, code) {
            var deferred = $q.defer();
            apiPromise
                .then(function (response) {
                    removeRedeemedCode(code);
                },
                function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        }
    }
} ());