(function () {
    "use strict"

    angular.module("angular.emlpayments")
        .factory("invoiceService", invoiceServiceFactory);

    invoiceServiceFactory.$inject = ["api", "appConfig", "notify"];

    function invoiceServiceFactory(api, appConfig, notify) { 
        return {
            getCompanyInvoices
        };

        function getCompanyInvoices(searchInvoiceModel) {
            return api.invoice.getCompanyInvoices(appConfig.selectedMerchantGroup().uniqueTag,
                searchInvoiceModel.pageNumber,
                searchInvoiceModel.pageSize,
                searchInvoiceModel.sortAscending,
                searchInvoiceModel.companyName,
				searchInvoiceModel.invoiceNumber,
                searchInvoiceModel.toDate,
				searchInvoiceModel.fromDate);
        }
    }
}());