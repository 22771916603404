﻿import htmlTemplate from './campaign.html';

(function () {
	"use strict";

	angular.module("cardspotWeb")
		.config(configSettings);

	configSettings.$inject = ["$routeProvider", "NAV_URLS", "CLAIMS"];

	function configSettings($routeProvider, NAV_URLS, CLAIMS) {

		$routeProvider
			.when(NAV_URLS.MANAGE_CAMPAIGNS, {
				template: htmlTemplate,
				controller: "Campaigns",
				controllerAs: "model",
				requiredClaim: CLAIMS.MANAGE_CAMPAIGNS
			});
	}

}());