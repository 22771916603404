﻿import htmlTemplate from './lookup-sales.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
        .config(configLookupSales);

    configLookupSales.$inject = ["$routeProvider", "NAV_URLS", "CLAIMS"];

    function configLookupSales($routeProvider, NAV_URLS, CLAIMS) {

        $routeProvider
            .when(NAV_URLS.LOOKUP_SALES + "/:id?", {
                template: htmlTemplate,
                controller: "LookupSales",
                controllerAs: "model",
                requiredClaim: CLAIMS.LOOKUP_ACTIVATION_TRANSACTION
            });
    }

}());