﻿import htmlTemplate from './manage-inventory.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
		.config(configManageInventory);

    configManageInventory.$inject = ["$routeProvider", "NAV_URLS", "CLAIMS"];

    function configManageInventory($routeProvider, NAV_URLS, CLAIMS) {

        $routeProvider
			.when(NAV_URLS.MANAGE_INVENTORY, {
			    template: htmlTemplate,
			    controller: "ManageInventory",
			    controllerAs: "model",
			    requiredClaim: CLAIMS.MANAGE_CARDS
            })
            .otherwise({ redirectTo: NAV_URLS.HOME });
    }

}());