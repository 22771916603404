import htmlTemplate from './cardfront-fulfillment.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
        .config(configFulfillment);

    configFulfillment.$inject = ["$routeProvider", "NAV_URLS", "CLAIMS"];

    function configFulfillment($routeProvider, NAV_URLS, CLAIMS) {

        $routeProvider
            .when(NAV_URLS.FULFILL_CONSUMER, {
                template: htmlTemplate,
                controller: "CardFrontFulfillment",
                controllerAs: "model",
                requiredClaim: CLAIMS.VIEW_CARDFRONT_ORDERS
            })
            .when(NAV_URLS.FULFILL_CORPORATE, {
                template: htmlTemplate,
                controller: "CardFrontFulfillment",
                controllerAs: "model",
                requiredClaim: CLAIMS.VIEW_CARDFRONT_ORDERS
            });
    }
}());