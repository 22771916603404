﻿import htmlTemplate from './manage-locations.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
		.config(configSettings);

    configSettings.$inject = ["$routeProvider", "NAV_URLS", "CLAIMS"];

    function configSettings($routeProvider, NAV_URLS, CLAIMS) {

        $routeProvider
			.when(NAV_URLS.MANAGE_LOCATIONS, {
			    template: htmlTemplate,
			    controller: "ManageLocations",
			    controllerAs: "model",
			    requiredClaim: CLAIMS.MANAGE_LOCATIONS
			});
    }

}());