﻿import htmlTemplate from './online-resources.html';

(function () { 
    "use strict";

    angular.module("cardspotWeb")
        .config(configOnlineResources);

    configOnlineResources.$inject = ["$routeProvider", "NAV_URLS"];

    function configOnlineResources($routeProvider, NAV_URLS) {

        $routeProvider
            .when(NAV_URLS.ONLINE_RESOURCES, {
                template: htmlTemplate,
                controller: "OnlineResources",
                controllerAs: "model"
            });
    }

}());