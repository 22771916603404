import htmlTemplate from './transaction-information.directive.html';

(function() {
    "use strict";

    angular.module("cardspotWeb.areas.hvtRequest")
        .directive("emlTransactionInformation", configDirective);

    function configDirective() {
        return {
            restrict: "E",
            scope: {
                programHvtLimits:"=",
                transactionInformation:"=",
                transactionInformationComplete:"=",
                previousStep:"="
            },
            controller: TransactionInformationController,
            controllerAs: "model",
            template: htmlTemplate
        };
    }

	TransactionInformationController.$inject = ["$q", "$scope", "$rootScope", "$translate", "SF_EVENTS", "limitService"];

	function TransactionInformationController($q, $scope, $rootScope, $translate, SF_EVENTS, limitService) {
        var model = this;
        model.programName;
        model.hvtTransactionMinimum = limitService.getHVTMinimumLimit();
        model.hvtInternalApprovalLimit = limitService.getHVTInternalApprovalLimit().limit

        $rootScope.$on(SF_EVENTS.PROGRAM_SELECTED, handleProgramSelectedChange);

        function handleProgramSelectedChange(event, program) {
            model.programName = program.programName;
        }
    }
}());