(function () {
    "use strict";

    angular.module("angular.emlpayments")
    .factory("transactionService", transactionServiceFactory);

    transactionServiceFactory.$inject = ["api", "appConfig", ];

    function transactionServiceFactory (api, appConfig) {
        var transaction,
            campaignTransactions;

        return {
            lookupTransaction,
            currentTransaction,
            haveTransaction,
            voidTransaction,
            lockTransaction,
            unlockTransaction,
            lookupByCampaign,
            currentCampaignTransactions,
            reset,
            getCampaigns
        };

        function reset(){
            transaction = angular.copy(null, transaction);
            campaignTransactions = angular.copy(null, campaignTransactions);
        }

        function lookupTransaction(transactionId){
            var lookupPromise = api.transaction.getTransaction(transactionId);
            lookupPromise.then(handleLookupResponse);
            return lookupPromise;
        }

        function lookupByCampaign(campaign){
            var lookupPromise = api.transaction.getTransactionsByCampaign(appConfig.selectedMerchantGroup().uniqueTag, campaign);
            lookupPromise.then(handleCampaignTransactionsResponse);
            return lookupPromise;
        }

        function handleLookupResponse(response) {
            transaction = response.data;
        }

        function handleCampaignTransactionsResponse(response) {
            campaignTransactions = response.data;
        }

        function currentTransaction(){
            return transaction;
        }

        function haveTransaction(transactionId) {
            return transaction && transaction.transactionId && transaction.transactionId == transactionId;
        }

        function currentCampaignTransactions(){
            return campaignTransactions;
        }

        function voidTransaction(transactionId, note){
            return api.transaction.voidActivationTransaction(appConfig.selectedMerchantGroup().uniqueTag, transactionId, appConfig.getSelectedLocation().location, note);
        }

        function lockTransaction(transactionId){
            return api.transaction.lockActivationTransaction(appConfig.selectedMerchantGroup().uniqueTag, transactionId, appConfig.getSelectedLocation().location);
        }

        function unlockTransaction(transactionId){
            return api.transaction.unlockActivationTransaction(appConfig.selectedMerchantGroup().uniqueTag, transactionId, appConfig.getSelectedLocation().location);
        }

        function getCampaigns() {
            return api.transaction.getCampaigns(appConfig.selectedMerchantGroup().uniqueTag);
        }
    }

}());
