﻿import angular from "angular";

(function () {
    "use strict";

    angular.module("cardspotWeb")
        .constant("NAV_URLS", {
            "HOME": "/",
            "LOGIN": "/login",
            "ENTER_SALES": "/enter-sales",
            "ENTER_SUNDRIES": "/enter-sundries",
            "LOOKUP_CARD": "/lookup-card",
            "LOOKUP_SALES": "/lookup-sales",
            "LOOKUP_CUSTOMER": "/lookup-customer",
            "TOOLS": "/tools",
            "ONLINE_RESOURCES": "/online-resources",
            "CONFIRM_SIGNAGE": "/confirm-signage",
            "APPLICATION_CONFIGURATION": "/application-configuration",
            "MANAGE_RECEIPTS": "/tools/manage-receipts",
            "MANAGE_SETTINGS": "/tools/manage-settings",
            "FULFILL_CONSUMER": "/fulfillment/consumer",
            "FULFILL_CORPORATE": "/fulfillment/corporate",
            "TRANSFER_CARD": "/transfer-card",
            "PRIVACY_POLICY": "/privacy-policy",
            "TERMS_OF_USE": "/terms-of-use",
            "ORDER_CARDS": "/order-cards",
            "TEST_SWIPER": "/test-swiper",
            "MANAGE_CAMPAIGNS": "/tools/manage-campaigns",
            "MANAGE_LOCATIONS": "/tools/manage-locations",
            "MANAGE_SUNDRIES": "/tools/manage-sundries",
            "DIAGNOSTIC": "/support/diagnostic",
            "MANAGE_INVENTORY": "/manage-inventory",
            "CONFIRM_INVENTORY": "/confirm-inventory",
            "REMOVE_INVENTORY": "/remove-inventory",
            "INTEGRATED_PAYMENT": "/integrated-payment",
            "LOOKUP_INVOICES": "/lookup-invoices",
            "HVT_REQUEST": "/hvt-request"
        })
        .constant("CARDSPOT_SERVICE_FOLDER", "/cardspot")
        .constant("AUTH_SERVICE_FOLDER", "/authorization")
        .constant("DEFAULT_LANG_KEY", "en-us")
        .constant("FOOTER_URLS", {
            "EML_TOU": "https://www.emlpayments.com/termsofuse"
        })
        .constant("LANGUAGES", [
			{ languageName: "Czech-Slovak", name: "CULTURES.CS_CZ", key: "cs-cz", dateFormat: "DMY", language: "cs", currencyFormat: { isTrailingSymbol: true, groupSeparator: ".", decimalSeparator: ",", fractionSize: 2 }, privacyPolicyUrl: "https://www.emlpayments.com/privacy#AUS" },
			{ languageName: "English (United States)", name: "CULTURES.EN_US", key: "en-us", dateFormat: "MDY", language: "en", currencyFormat: { isTrailingSymbol: false, groupSeparator: ",", decimalSeparator: ".", fractionSize: 2 }, privacyPolicyUrl: "https://www.emlpayments.com/privacy#AUS" },
			{ languageName: "English (Canada)", name: "CULTURES.EN_CA", key: "en-ca", dateFormat: "MDY", language: "en", currencyFormat: { isTrailingSymbol: false, groupSeparator: ",", decimalSeparator: ".", fractionSize: 2 }, privacyPolicyUrl: "https://www.emlpayments.com/privacy#AUS" },
			{ languageName: "English (Great Britain)", name: "CULTURES.EN_GB", key: "en-gb", dateFormat: "DMY", language: "en", currencyFormat: { isTrailingSymbol: false, groupSeparator: ",", decimalSeparator: ".", fractionSize: 2 }, privacyPolicyUrl: "https://www.emlpayments.com/privacy#EUEN" },
			{ languageName: "French (Canadian)", name: "CULTURES.FR_CA", key: "fr-ca", dateFormat: "YMD", language: "fr", currencyFormat: { isTrailingSymbol: true, groupSeparator: " ", decimalSeparator: ",", fractionSize: 2 }, privacyPolicyUrl: "https://www.emlpayments.com/privacy#french1" },
			{ languageName: "French (France)", name: "CULTURES.FR_FR", key: "fr-fr", dateFormat: "DMY", language: "fr", currencyFormat: { isTrailingSymbol: true, groupSeparator: " ", decimalSeparator: ",", fractionSize: 2 }, privacyPolicyUrl: "https://www.emlpayments.com/privacy#french1" },
			{ languageName: "German (Germany)", name: "CULTURES.DE_DE", key: "de-de", dateFormat: "YMD", language: "de", currencyFormat: { isTrailingSymbol: true, groupSeparator: " ", decimalSeparator: ",", fractionSize: 2 }, privacyPolicyUrl: "https://www.emlpayments.com/privacy#GERMAN" },
			{ languageName: "German (Austrian)", name: "CULTURES.DE_AT", key: "de-at", dateFormat: "DMY", language: "de", currencyFormat: { isTrailingSymbol: true, groupSeparator: " ", decimalSeparator: ",", fractionSize: 2 }, privacyPolicyUrl: "https://www.emlpayments.com/privacy#GERMAN" },
			{ languageName: "Italian", name: "CULTURES.IT_IT", key: "it-it", dateFormat: "DMY", language: "it", currencyFormat: { isTrailingSymbol: false, groupSeparator: " ", decimalSeparator: ",", fractionSize: 2 }, privacyPolicyUrl: "https://www.emlpayments.com/privacy#italian1" },
			{ languageName: "Spanish", name: "CULTURES.ES_ES", key: "es-es", dateFormat: "DMY", language: "es", currencyFormat: { isTrailingSymbol: true, groupSeparator: " ", decimalSeparator: ",", fractionSize: 2 }, privacyPolicyUrl: "https://www.emlpayments.com/privacy#spanish1" },
			{ languageName: "Dutch", name: "CULTURES.NL_NL", key: "nl-nl", dateFormat: "DMY", language: "nl", currencyFormat: { isTrailingSymbol: false, groupSeparator: " ", decimalSeparator: ",", fractionSize: 2 }, privacyPolicyUrl: "https://www.emlpayments.com/privacy#dutch1" },
			{ languageName: "Flemish", name: "CULTURES.NL_BE", key: "nl-be", dateFormat: "DMY", language: "nl", currencyFormat: { isTrailingSymbol: true, groupSeparator: " ", decimalSeparator: ",", fractionSize: 2 }, privacyPolicyUrl: "https://www.emlpayments.com/privacy#flemish1" },
			{ languageName: "Portuguese", name: "CULTURES.PT_PT", key: "pt-pt", dateFormat: "DMY", language: "pt", currencyFormat: { isTrailingSymbol: false, groupSeparator: " ", decimalSeparator: ",", fractionSize: 2 }, privacyPolicyUrl: "https://www.emlpayments.com/privacy#portuguese1" },
			{ languageName: "Danish", name: "CULTURES.DA_DK", key: "da-dk", dateFormat: "DMY", language: "da", currencyFormat: { isTrailingSymbol: true, groupSeparator: ".", decimalSeparator: ",", fractionSize: 2 }, privacyPolicyUrl: "https://www.emlpayments.com/privacy#AUS" },
			{ languageName: "Estonian", name: "CULTURES.ET_EE", key: "et-ee", dateFormat: "DMY", language: "et", currencyFormat: { isTrailingSymbol: false, groupSeparator: ",", decimalSeparator: ".", fractionSize: 2 }, privacyPolicyUrl: "https://www.emlpayments.com/privacy#AUS" },
			{ languageName: "Finnish", name: "CULTURES.FI_FI", key: "fi-fi", dateFormat: "DMY", language: "fi", currencyFormat: { isTrailingSymbol: false, groupSeparator: ",", decimalSeparator: ".", fractionSize: 2 }, privacyPolicyUrl: "https://www.emlpayments.com/privacy#AUS" },
			{ languageName: "Lithuanian", name: "CULTURES.LT_LT", key: "lt-lt", dateFormat: "DMY", language: "lt", currencyFormat: { isTrailingSymbol: false, groupSeparator: ",", decimalSeparator: ".", fractionSize: 2 }, privacyPolicyUrl: "https://www.emlpayments.com/privacy#AUS" },
			{ languageName: "Romanian", name: "CULTURES.RO_RO", key: "ro-ro", dateFormat: "DMY", language: "ro", currencyFormat: { isTrailingSymbol: false, groupSeparator: ",", decimalSeparator: ".", fractionSize: 2 }, privacyPolicyUrl: "https://www.emlpayments.com/privacy#AUS" },
			{ languageName: "Swedish", name: "CULTURES.SV_SE", key: "sv-se", dateFormat: "YMD", language: "sv", currencyFormat: { isTrailingSymbol: false, groupSeparator: " ", decimalSeparator: ",", fractionSize: 2 }, privacyPolicyUrl: "https://www.emlpayments.com/privacy#AUS" },
			{ languageName: "Norwegian", name: "CULTURES.NB_NO", key: "nb-no", dateFormat: "YMD", language: "nb", currencyFormat: { isTrailingSymbol: true, groupSeparator: ".", decimalSeparator: ",", fractionSize: 2 }, privacyPolicyUrl: "https://www.emlpayments.com/privacy#AUS" },
			{ languageName: "Polish", name: "CULTURES.PL_PL", key: "pl-pl", dateFormat: "DMY", language: "pl", currencyFormat: { isTrailingSymbol: true, groupSeparator: " ", decimalSeparator: ",", fractionSize: 2 }, privacyPolicyUrl: "https://www.emlpayments.com/privacy#AUS" },
        ])
        .constant("CARD_ORDER_LANGUAGES", [
            { name: "LANGUAGES.ENGLISH", key: "en" },
            { name: "LANGUAGES.FRENCH", key: "fr" }
        ])
        .constant("DATE_FORMATS", [
            { name: "YMD", formatString: "yyyy-MM-dd" },
            { name: "MDY", formatString: "MM/dd/yyyy" },
            { name: "DMY", formatString: "dd-MM-yyyy" }
        ])
        .constant("REGION_TYPES", {
            "NONE": "None",
            "STATE": "State",
            "PROVINCE": "Province",
            "COUNTY": "County"
        })
        .constant("CLAIMS", {
            "ADD_SOFTWARE_CLIENT_INSTANCE": "AddSoftwareClientInstance",
            "ADD_CUSTOMER_INFORMATION": "AddCustomerInformation",
            "LOOKUP_CARD": "LookupCard",
            "LOOKUP_ACTIVATION_TRANSACTION": "LookupActivationTransaction",
            "LOCK_CARD": "LockCard",
            "UNLOCK_CARD": "UnlockCard",
            "TRANSFER_CARD": "TransferCard",
            "ADD_FEE": "AddFee",
            "DELETE_FEE": "DeleteFee",
            "MODIFY_ACTIVATION_FEE": "ModifyActivationFee",
            "MODIFY_TRANSFER_FEE": "ModifyTransferFee",
            "ADD_CAMPAIGNS": "AddCampaigns",
            "ACTIVATE_CARD": "ActivateCard",
            "ACTIVATE_WITHOUT_SWIPE": "ActivateWithoutSwipe",
            "VOID_CARD": "VoidCard",
            "SEARCH_CUSTOMER": "SearchCustomer",
            "MANAGE_RECEIPT_ITEMS": "ManageReceiptItems",
            "MANAGE_RECEIPT_TERMS_AND_CONDITIONS": "ManageReceiptTermsAndConditions",
            "MANAGE_SETTINGS": "ManageSettings",
            "VIEW_CARDFRONT_ORDERS": "ViewCardFrontOrders",
            "FULFILL_CARDFRONT_ORDERS": "FulfillCardFrontOrders",
            "ORDER_CARDS": "OrderCards",
            "MANAGE_CAMPAIGNS": "ManageCampaigns",
            "MANAGE_LOCATIONS": "ManageLocations",
            "MANAGE_MERCHANT_GROUP_SUNDRIES": "ManageMerchantGroupSundries",
            "VIEW_RESTRICTED_CARD_NUMBERS": "ViewRestrictedCardNumbers",
            "MANAGE_CARDS": "ManageCards",
            "DISABLE_REPRINT": "DisableReprint",
            "RECONCILE_CARD_INVENTORY": "ReconcileCardInventory",
            "FULFILL_CORPORATE_ORDERS": "FulfillCorporateOrders",
            "EXTEND_ACCOUNT_EXPIRATION": "ExtendAccountExpiration"
        })
        .constant("CONTROLLERS", {
            "LOGIN": "Login",
            "CONFIRM_SIGNAGE": "ConfirmSignage",
            "APPLICATION_CONFIGURATION": "ApplicationConfiguration",
            "ENTER_SALES": "EnterSales"
        })
        .constant("DEFAULT_SALES_CHANNEL", "InPerson")
        .constant("DEFAULT_DATE_FORMAT", "MDY")
        .constant("DEFAULT_DATE_TIME_FORMAT", "h:mm:ss a")
        .constant("AUTH_CLIENT_ID", "com.storefinancial.cardspotweb")
            .constant("STORE_KEYS", {
                "JWT": "eml-jwt",
                "SELECTED_MERCHANT_GROUPS": "eml-selected-merchant-groups",
                "SELECTED_LOCATIONS": "eml-selected-locations",
                "DIAGNOSTIC_DATA": "eml-diagnostic-data",
                "CUSTOMER_EMAIL": "eml-customer-email",
                "EML_CONNECT_JWT": "eml-connect-jwt"
            })
            .constant("JWT_PERMISSION_KEY", "https://storefinancial.net/permission")
            .constant("JWT_USERNAME_KEY", "nameid")
        .constant("JWT_CONTACT_ID_KEY", "https://storefinancial.net/contactId")
        .constant("DEFAULT_VALUES", {
            "CAMPAIGN": "No Campaign"
        })
        .constant("EVENT_NAMES", {
            "PASSWORD_CHANGE_REQUIRED": "password_change_required",
            "UNAUTHORIZED": "unauthorized",
            "SHOWING_SALE_COMPLETE": "showing-sale-complete",
            "SHOWING_SALE_SUMMARY": "showing-sale-summary",
            "PANEL_COLLAPSE": "panel-collapse",
            "PANEL_EXPAND": "panel-expand",
            "ON_CULTURE_CHANGED": "on-culture-changed",
            "ON_PAYMENT_TYPE_ARRAY_CHANGED": "on-payment-type-array-changed",
            "ON_CARD_PREACTIVATED_WITH_CODE": "on-card-preactivated-with-code"
        })
        .constant("UPLOAD_ENDPOINTS",
        {
            "CARD_IMAGE": "/cardspot/inventory/uploadcardimage/merchantgroup/"
        })
        .constant("REG_EX", {
            "NUMBERS_ONLY": /^[0-9]+$/
        })
        .constant("CONTACT_INFO", {
            "CANADA": {
                "EMAIL": "support@emlpayments.com",
                "PHONE": "800.840.5793"
            },
            "UNITED_STATES": {
                "EMAIL": "support@emlpayments.com",
                "PHONE": "800.755.0019"
            },
            "EUROPE": {
                "EMAIL": "cardsupport@emlpayments.com",
                "PHONE": "+44 (0) 121 268 3210"
            },
            "GERMANY": {
                "EMAIL": "kundenservice@emlpayments.com",
                "PHONE": "+0049 (0) 40 87407465"
            }
        })
        .constant("CARD_ORDER_RECONCILIATION_STATUS_TYPES", {
            "USABLE": "Usable",
            "UNUSABLE": "Unusable"
        })
        .constant("LOOKUP_TYPES", {
            "INDIVIDUAL": "individual",
            "GROUP": "group",
            "REDEEM_CODE": "redeemCode"
        })
        .constant("EML_CONNECT_SERVICE_FOLDER", "/OpenApi/V1/ClickNCollect")
        .constant("HVT_LIMITS", {
              "HVT_MINIMUM": "HVTMinimum",
              "HVT_INTERNAL_APPROVAL": "HVTInternalApproval",
              "HVT_BANK_APPROVAL": "HVTBankApproval",
              "CARD_MAXIMUM_EXEMPTION_MINIMUM": "CardMaximumExemptionMinimum",
              "CARD_MAXIMUM_EXEMPTION_MAXIMUM": "CardMaximumExemptionMaximum"
        })
        .constant("HVT_VIEW_NAMES", {
            "PROGRAM": "program",
            "PURCHASER_INFORMATION": "purchaser-information",
            "COMPANY_INFORMATION": "company-information",
            "USE_OF_CARDS": "use-of-cards",
            "TRANSACTION_INFORMATION": "transaction-information"
        })
        .constant("USE_OF_CARD_OPTIONS", {
            "GIFT": {
                "NAME": "Gift",
                "SELECTED": false
            },
            "PROMOTION": {
                "NAME": "Promotion",
                "SELECTED": false 
            },
            "NONPROFIT_FUNDRAISER": {
                "NAME": "NonProfit/Fundraiser",
                "SELECTED": false
            },
            "CUSTOMER_INCENTIVE": {
                "NAME": "Customer Incentive",
                "SELECTED": false
            },
            "OTHER": {
                "NAME": "Other",
                "SELECTED": false
            }
        })
        .constant("FULFILLMENT_ORDER_TYPES", {
            "ANONYMOUS_PHYSICAL": "Anonymous Physical",
            "DIGITAL": "Digital",
            "REGISTERED_PHYSICAL": "Registered Physical",
            "VIRTUAL": "Virtual"
        })
    ;
}());