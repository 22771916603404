(function () {
	"use strict";

	angular.module("angular.emlpayments")
    .factory("fulfillmentService", fulfillmentServiceFactory);

	fulfillmentServiceFactory.$inject = ["$q", "api", "notify", "appConfig", "FULFILLMENT_ORDER_TYPES"];

	function fulfillmentServiceFactory($q, api, notify, appConfig, FULFILLMENT_ORDER_TYPES) {
		
		var shippingMethodsPromise;
			
		return {
			getFulfillmentQueueCount,
			getFulfillmentQueue,
			beginFulfillment,			
			getFulfillmentOrder,
			preActivateByPrintText,
            preActivateBySwipe,
			cancelFulfillment,	
			cancelOrder,
			isDuplicateCard,			
			fulfillOrder,
			getFulfillmentHistory,
			updateTrackingInformation,
			preActivateGroup,
			getShippingMethods,
			getOrderItemCardAmountCardholdersExport,
			reset
		};
		
		function getFulfillmentQueueCount(){
			return api.fulfillment.getFulfillmentQueueCount(appConfig.selectedMerchantGroup().uniqueTag);				
		}

		function getFulfillmentQueue(            
			searchValue = null,
			orderDateBegin = null,
			orderDateEnd = null,
			isCorporate = false
			) 
		{
			return api.fulfillment.getFulfillmentQueue(
				appConfig.selectedMerchantGroup().uniqueTag, 
				searchValue, 
				orderDateBegin, 
				orderDateEnd,
				isCorporate
				);	            
		}
		
		function beginFulfillment(orderId, modifyWhileInProgress = false){
			
			return modifyWhileInProgress ?
				api.fulfillment.overrideBeginFulfillment(orderId) :
				api.fulfillment.beginFulfillment(orderId);			
		}		
		
        function getFulfillmentOrder(orderId, isCorporate = false) {						
			return api.fulfillment.getFulfillmentOrder(orderId, isCorporate);
		}									
		
		function preActivateByPrintText(orderId, printText) {
            return api.fulfillment.preActivateByPrintText(orderId, printText);				
        }

        function preActivateBySwipe(orderId, primaryAccountNumber) {
            return api.fulfillment.preActivateBySwipe(orderId, primaryAccountNumber);				
        }				
		
		function isDuplicateCard(printText, primaryAccountNumber) {
			var isDupe = true;

            if (primaryAccountNumber) {
				isDupe = cardsPreActivated.findIndex(card => { return card.primaryAccountNumber.toString() === primaryAccountNumber.toString()}) === -1;                                
            }
            if (printText) {
            	isDupe = cardsPreActivated.findIndex(card => { return card.printText.toString() === printText.toString()}) === -1;
            }
            return isDupe && cardsPreActivated.length > 0;
        }								
		
		function cancelFulfillment(orderId) {
            return api.fulfillment.cancelFulfillment(orderId);				
        }	
		
		function cancelOrder(orderId) {
            return api.fulfillment.cancelOrder(orderId);				
        }	
		
		function fulfillOrder(orderId, orderItems, lockTransactionOnActivation, orderType) {
			switch (orderType) {
				case FULFILLMENT_ORDER_TYPES.DIGITAL:
					return api.fulfillment.fulfillDigitalOrder(orderId, appConfig.getSelectedLocation().location, orderItems);
				case FULFILLMENT_ORDER_TYPES.REGISTERED_PHYSICAL:
					return api.fulfillment.fulfillOrder(orderId, appConfig.getSelectedLocation().location, orderItems, lockTransactionOnActivation);
				case FULFILLMENT_ORDER_TYPES.ANONYMOUS_PHYSICAL:
					return api.fulfillment.fulfillOrder(orderId, appConfig.getSelectedLocation().location, orderItems, lockTransactionOnActivation);
			}			
		}

		function updateTrackingInformation(orderId, orderItemId, trackingInformation){            
            return api.fulfillment.updateTrackingInformation(orderId, orderItemId, trackingInformation);                        
        } 

		function getFulfillmentHistory(
			pageNumber = 0, 
            pageSize = 10, 
            searchValue = null,
			orderDateBegin = null,
            orderDateEnd = null, 
            columnSort = 'DateFulfilled', 
            sortAscending = false,
            isCorporate = false) {
			return api.fulfillment.getFulfillmentHistory(
				appConfig.selectedMerchantGroup().uniqueTag,
				pageNumber,
				pageSize,
				searchValue,
				orderDateBegin,
				orderDateEnd,
				columnSort,
                sortAscending,
                isCorporate);	            
		}

		function preActivateGroup(orderId, firstCardNumber, lastCardNumber) {
            return api.fulfillment.preActivateCardGroup(orderId, firstCardNumber, lastCardNumber);				
		}
		
		function getShippingMethods(){
			if (!shippingMethodsPromise) {
				shippingMethodsPromise = api.fulfillment.getShippingMethods(appConfig.selectedMerchantGroup().uniqueTag);
			}
			return shippingMethodsPromise;			
		}

		function getOrderItemCardAmountCardholdersExport(orderId) {
			return api.fulfillment.getOrderItemCardAmountCardholdersExport(orderId);
		}

		function reset() {
			shippingMethodsPromise = null;
		}
	}
}());