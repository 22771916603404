(function() {
    "use strict";

    angular.module("cardspotWeb.areas.settings")
        .controller("Settings", Settings);

    Settings.$inject = ["notify", "appConfig", "settings", "$rootScope", "SF_EVENTS"];

    function Settings(notify, appConfig, settings, $rootScope, SF_EVENTS) {
        var model = this;

        model.save = save;
        model.reset = reset;

        model.supportsCustomerAtCardLevel = false;
        model.supportsSearchByClientTrackingId = false;
        model.supportSearchByCarrierNumber = false;
        model.defaultCampaignSelection = false;
        model.manageCampaigns = false;
        model.receiptGroupingThreshold = undefined;
        model.manageLocations = false;
        model.corporateInvoiceEnabled = false;
        model.individualCardReceiptsEnabled = false;
        model.clickAndCollectEnabled = false;
        model.hvtRequestEnabled = false;

        init();

        function init() {
            getSettings();
        }

        function getSettings() {
            notify
                .forHttp(settings.getSettings(), {
                    startTranslationId: "SETTINGS.GET_SETTINGS_PROGRESS"
                })
                .then(data => assignSettings(data));
        }

        function assignSettings(data) {
            appConfig.updateMerchantGroupSettings(
                data.supportsCustomerAtCardLevel,
                data.supportsSearchByClientTrackingId,
                data.defaultCampaignSelection,
				data.manageCampaigns,
                data.receiptGroupingThreshold,
                data.manageLocations,
                data.supportSearchByCarrierNumber,
                data.corporateInvoiceEnabled,
                data.individualCardReceiptsEnabled,
                data.clickAndCollectEnabled,
                data.hvtRequestEnabled);

            model.master = {
                supportsCustomerAtCardLevel: data.supportsCustomerAtCardLevel,
                supportsSearchByClientTrackingId: data.supportsSearchByClientTrackingId,
                defaultCampaignSelection: data.defaultCampaignSelection,
                manageCampaigns: data.manageCampaigns,
                receiptGroupingThreshold: data.receiptGroupingThreshold,
                manageLocations: data.manageLocations,
                supportSearchByCarrierNumber: data.supportSearchByCarrierNumber,
                corporateInvoiceEnabled: data.corporateInvoiceEnabled,
                individualCardReceiptsEnabled: data.individualCardReceiptsEnabled,
                clickAndCollectEnabled: data.clickAndCollectEnabled,
                hvtRequestEnabled: data.hvtRequestEnabled};

            model.supportsCustomerAtCardLevel = data.supportsCustomerAtCardLevel;
            model.supportsSearchByClientTrackingId = data.supportsSearchByClientTrackingId;
            model.defaultCampaignSelection = data.defaultCampaignSelection;
            model.manageCampaigns = data.manageCampaigns;
            model.receiptGroupingThreshold = data.receiptGroupingThreshold;
            model.manageLocations = data.manageLocations;
            model.supportSearchByCarrierNumber = data.supportSearchByCarrierNumber;
            model.corporateInvoiceEnabled = data.corporateInvoiceEnabled;
            model.individualCardReceiptsEnabled = data.individualCardReceiptsEnabled;
            model.clickAndCollectEnabled = data.clickAndCollectEnabled;
            model.hvtRequestEnabled = data.hvtRequestEnabled;
        }

        function save() {
            notify
                .forHttp(settings.setSettings(
                    model.supportsCustomerAtCardLevel,
                    model.supportsSearchByClientTrackingId,
                    model.defaultCampaignSelection,
					model.manageCampaigns,
                    model.receiptGroupingThreshold,
                    model.manageLocations,
                    model.supportSearchByCarrierNumber,
                    model.corporateInvoiceEnabled,
                    model.individualCardReceiptsEnabled,
                    model.clickAndCollectEnabled,
                    model.hvtRequestEnabled), {
                    startTranslationId: "SETTINGS.SET_SETTINGS_PROGRESS"
                })
                .then(() => getSettings());

            handleGlobalSettingsChange();
        }

        function reset() {
            model.supportsCustomerAtCardLevel = model.master.supportsCustomerAtCardLevel;
            model.supportsSearchByClientTrackingId = model.master.supportsSearchByClientTrackingId;
            model.defaultCampaignSelection = model.master.defaultCampaignSelection;
            model.manageCampaigns = model.master.manageCampaigns;
            model.manageLocations = model.master.manageLocations;
            model.supportSearchByCarrierNumber = model.master.supportSearchByCarrierNumber;
            model.corporateInvoiceEnabled = model.master.corporateInvoiceEnabled;
            model.individualCardReceiptsEnabled = model.master.individualCardReceiptsEnabled;
            model.clickAndCollectEnabled = model.master.clickAndCollectEnabled;
            model.hvtRequestEnabled = model.master.hvtRequestEnabled;
        }

        function handleGlobalSettingsChange() {
            $rootScope.$emit(SF_EVENTS.GLOBAL_SETTINGS_CHANGED, {
                manageCampaigns: model.manageCampaigns,
                manageLocations: model.manageLocations,
                allowCorporateInvoicing: model.corporateInvoiceEnabled,
                allowHvtRequest: model.hvtRequestEnabled });
        }
    }
}());
