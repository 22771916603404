﻿(function() {
    "use strict";

    angular.module("cardspotWeb.areas.home")
        .controller("Home", Home);

    Home.$inject = ["$scope", "navigate"];

    function Home($scope, navigate) {
        var model = this;

        init();

        function init() {
            navigate.toReferrerOrDefault();
        }
    }
}());