import htmlTemplate from './order-cards.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
        .config(configOrderCards);

    configOrderCards.$inject = ["$routeProvider", "NAV_URLS", "CLAIMS"];

    function configOrderCards($routeProvider, NAV_URLS, CLAIMS) {

        $routeProvider
             .when(NAV_URLS.ORDER_CARDS + "/:cardDesignId?", {
                 template: htmlTemplate,
                 controller: "OrderCards",
                 controllerAs: "model",
                requiredClaim: CLAIMS.ORDER_CARDS
             });
    }
}());