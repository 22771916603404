﻿(function () {
    "use strict";

    angular.module("cardspotWeb.areas.diagnostic")
        .controller("Diagnostic", Diagnostic);

    Diagnostic.$inject = ["$scope", "authService", "diagnosticService", "notify", "storeLinks"];

    function Diagnostic($scope, authService, diagnosticService, notify, storeLinks) {
        var model = this;
        
        model.message = diagnosticService.getDiagnosticData().lastMessage;
        model.username = authService.currentUsername();
        model.errorDate = diagnosticService.getDiagnosticData().dateTime;
        model.tlsCheckerUrl = storeLinks.sslLabsLinkUrl;
        model.getClientIPAddress = getClientIPAddress();
        model.browser = diagnosticService.getClientBrowserInfo();
        model.device = diagnosticService.getClientDeviceInfo();
        model.os = diagnosticService.getClientOSInfo();
        model.engine = diagnosticService.getClientEngineInfo();
        
        init();
        
        function init() {
            getClientIPAddress();
        }

        function getClientIPAddress() {
            notify.forHttp(diagnosticService.getClientIPAddress(),
                { startTranslationId: "DIAGNOSTIC.MESSAGES.GETTING_CLIENT_IP" })
                .then(data => setClientIPAddress(data));
        }

        function setClientIPAddress(data) {
            model.clientIPAddress = data;
        }
    }
}());