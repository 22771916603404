﻿(function () {
    "use strict";

    angular.module("cardspotWeb.areas.manageSundries")
		.controller("ManageSundries", ManageSundries);

    ManageSundries.$inject = ["$scope", "$translate", "notify", "authService", "sundryService", "appConfig", "store", "STORE_KEYS", "preActivationService"];

    function ManageSundries($scope, $translate, notify, authService, sundryService, appConfig, store, STORE_KEYS, preActivationService) {
        var model = this;

        model.sundryStatuses = [
            { status: false, description: $translate.instant("SUNDRIES.FORM.LABEL_TEXT.INACTIVE") }, // TODO: implement this in template rather than controller. When in template it was causing undesirable behavior. Will look into later.
            { status: true, description: $translate.instant("SUNDRIES.FORM.LABEL_TEXT.ACTIVE") }
        ];

        model.newSundryItemType = {
            type: null,
            unitPrice: null,
            currency: {
                description: null,
                numericCode: null,
                currencySymbol: null
            },
            status: angular.copy(model.sundryStatuses[0].status)
        };

        model.addFormIsShown = false;
        model.canManageSundries = authService.canManageMerchantGroupSundries();
        model.loadActiveSundries = loadActiveSundries;
        model.loadInactiveSundries = loadInactiveSundries;
        model.saveNewSundry = saveNewSundry;
        model.activeSundriesPageChanged = activeSundryPageChanged;
        model.activeSundryPageSizeChanged = activeSundryPageSizeChanged;
        model.inactiveSundriesPageChanged = inactiveSundryPageChanged;
        model.inactiveSundryPageSizeChanged = inactiveSundryPageSizeChanged;
        model.activeSortChanged = activeSortChanged;
        model.inactiveSortChanged = inactiveSortChanged;
        model.editingActiveData = {};
        model.editingInactiveData = {};
        model.modifyActive = modifyActiveSundry;
        model.modifyInactive = modifyInactiveSundry;
        model.updateActive = updateActiveSundry;
        model.updateInactive = updateInactiveSundry;
        model.cancelActiveUpdate = cancelActiveUpdate;
        model.cancelInactiveUpdate = cancelInactiveUpdate;
        model.showAddForm = showAddForm;
        model.selectedCurrencySymbol = "$";
        model.setSelectedCurrencySymbol = setSelectedCurrencySymbol;
        model.currencySymbol = getCurrencySymbol();
        

        $scope.pageSizeOptions = [5, 10, 25, 50];
        $scope.pageSize = 10;
        $scope.activeSundryPageSize = 10;
        $scope.activeSundryCurrentPage = 1;
        $scope.inactiveSundryPageSize = 10;
        $scope.inactiveSundryCurrentPage = 1;
        $scope.activeSortAscending = true;
        $scope.inactiveSortAscending = true;

        model.pagination = {
            pageSize: $scope.pageSizeOptions[1]
        };

        var TAB_NAMES = {
            ACTIVE: "active",
            INACTIVE: "inactive"
        };

        $scope.TAB_NAMES = TAB_NAMES;
        var activeTab = TAB_NAMES.ACTIVE;

        init();

        function init() {
            getSundryCurrencies(appConfig.selectedMerchantGroup().uniqueTag);
            loadActiveSundries();
            getAllSundries();
        }

        function resetSundries() {
            model.activeSundries = null;
            model.inactiveSundries = null;
        }

        function resetCurrencies() {
            model.sundryCurrencies = null;
        }

        function getAllSundries() {
            sundryService.getAllSundryItemTypes(appConfig.selectedMerchantGroup().uniqueTag)
			    .then(response => setAllSundries(response));
        }

        function getSundryCurrencies(merchantGroupUniqueTag) {
            notify.forHttp(sundryService.getSundryCurrencies(merchantGroupUniqueTag),
                    { startTranslationId: "SUNDRIES.FORM.LOADING_CURRENCIES_PROGRESS_TEXT" })
                .then(data => setCurrencies(data));
        }

        function setCurrencies(data) {
            resetCurrencies();
            model.sundryCurrencies = data;
        }

        function loadActiveSundries() {
            activeTab = TAB_NAMES.ACTIVE;
            notify.forHttp(sundryService.getActiveSundryItemTypes(
                appConfig.selectedMerchantGroup().uniqueTag,
				$scope.activeSundryCurrentPage,
				$scope.activeSundryPageSize,
				$scope.activeSortAscending), { startTranslationId: 'SUNDRIES.FORM.LOADING_ACTIVE_SUNDRIES_TEXT' })
            .then(data => setActiveSundries(data));
        }

        function loadInactiveSundries() {
            activeTab = TAB_NAMES.INACTIVE;
            notify.forHttp(sundryService.getInactiveSundryItemTypes(
                appConfig.selectedMerchantGroup().uniqueTag,
				$scope.inactiveSundryCurrentPage,
				$scope.inactiveSundryPageSize,
				$scope.inactiveSortAscending), { startTranslationId: 'SUNDRIES.FORM.LOADING_INACTIVE_SUNDRIES_TEXT' })
            .then(data => setInactiveSundries(data));
        }

        function setAllSundries(response) {
            model.allSundries = response.data;
        }

        function setActiveSundries(data) {
            resetSundries();
            model.activeSundries = data.items;
            model.activeSundries.filteredItems = data.filteredItems;
            model.activeSundries.totalItems = data.totalItems;
            for (var i = 0, length = model.activeSundries.length; i < length; i++) {
                model.editingActiveData[model.activeSundries[i].sundryItemTypeId] = false;
            }
        }

        function setInactiveSundries(data) {
            resetSundries();
            model.inactiveSundries = data.items;
            model.inactiveSundries.filteredItems = data.filteredItems;
            model.inactiveSundries.totalItems = data.totalItems;
            for (var i = 0, length = model.inactiveSundries.length; i < length; i++) {
                model.editingInactiveData[model.inactiveSundries[i].sundryItemTypeId] = false;
            }
        }

        function saveNewSundry() {
            notify.dismissErrorAlert();

            if (isDuplicateSundry(model.newSundryItemType.type, model.newSundryItemType.currency.numericCode)) {
                notify.showError("SUNDRIES.FORM.VALIDATION_MESSAGES.SUNDRY_ALREADY_ADDED");
                resetAddSundryForm();
                return;
            }

            if (model.canManageSundries) {
                notify.forHttp(sundryService.addSundryItemType(appConfig.selectedMerchantGroup().uniqueTag, model.newSundryItemType),
					{ startTranslationId: 'SUNDRIES.FORM.INSERTING_NEW_SUNDRY' })
					.then(function (response) {
					    if (response.succeeded) {
					        resetAddSundryForm();
					        init();
					        notify.success("SUNDRIES.FORM.INSERTING_SUCCESS", undefined, 1000);
					    }
					})
                    .catch(function (response) {
                        showErrorAndScrollToTop(response)
                    });;
            }
        }

        function isDuplicateSundry(sundryDescription, currencyCode) {
            var isDupe = true;

            if (sundryDescription && currencyCode && model.allSundries) {
                isDupe = model.allSundries.findIndex(l => l.description.toLowerCase() === sundryDescription.toLowerCase() && l.currencyNumericCode === currencyCode) > -1;
            }

            return isDupe;
        }

        function isUpdateDuplicateSundry(rowData) {
            var isDupe = true;
            
            if (rowData && model.allSundries) {
                isDupe = model.allSundries.findIndex(l => l.description.toLowerCase() === rowData.description.toLowerCase() && l.currencyNumericCode === rowData.currencyNumericCode && l.sundryItemTypeId !== rowData.sundryItemTypeId) > -1;
            }

            return isDupe;
        }

        function refreshActiveSundriesGrid() {
            loadActiveSundries();
            getAllSundries();
        }

        function refreshInactiveSundriesGrid() {
            loadInactiveSundries();
            getAllSundries();
        }

        function activeSundryPageChanged(newPageNumber) {
            if (newPageNumber) {
                $scope.activeSundryCurrentPage = newPageNumber;
                loadActiveSundries();
            }
        }

        function inactiveSundryPageChanged(newPageNumber) {
            if (newPageNumber) {
                $scope.inactiveSundryCurrentPage = newPageNumber;
                loadInactiveSundries();
            }
        }

        function activeSundryPageSizeChanged() {
            if (model.pagination.pageSize !== $scope.activeSundryPageSize) {
                $scope.activeSundryPageSize = model.pagination.pageSize;
                loadActiveSundries();
            }
        }

        function inactiveSundryPageSizeChanged() {
            if (model.pagination.pageSize !== $scope.inactiveSundryPageSize) {
                $scope.inactiveSundryPageSize = model.pagination.pageSize;
                loadInactiveSundries();
            }
        }

        function activeSortChanged() {
            $scope.activeSortAscending = !$scope.activeSortAscending;
            loadActiveSundries();
        }

        function inactiveSortChanged() {
            $scope.inactiveSortAscending = !$scope.inactiveSortAscending;
            loadInactiveSundries();
        }

        function modifyActiveSundry(rowData) {
            model.editingActiveData[rowData.sundryItemTypeId] = true;
        }

        function modifyInactiveSundry(rowData) {
            model.editingInactiveData[rowData.sundryItemTypeId] = true;
        }

        function updateActiveSundry(rowData) {
            notify.dismissErrorAlert();

            if (isUpdateDuplicateSundry(rowData)) {
                showErrorAndScrollToTop("SUNDRIES.FORM.VALIDATION_MESSAGES.SUNDRY_ALREADY_ADDED");
                return;
            }

            if (model.canManageSundries) {
                rowData.merchantGroupUniqueTag = appConfig.selectedMerchantGroup().uniqueTag;
                notify.forHttp(sundryService.updateSundryItemType(rowData),
					{ startTranslationId: 'SUNDRIES.FORM.SAVING_SUNDRY_TEXT' })
					.then(function (response) {
                        if (response.succeeded) {
                            model.editingActiveData[rowData.sundryItemTypeId] = false;
                            if (!rowData.isActive) {
                                removeFromArray(model.activeSundries, rowData);
                            }

                            refreshActiveSundriesGrid();

                            notify.success("SUNDRIES.FORM.UPDATING_SUCCESS", undefined, 1000);
                        }
					})
                    .catch(function (response) {
                        showErrorAndScrollToTop(response);
                    });
            }
        }

        function updateInactiveSundry(rowData) {
            notify.dismissErrorAlert();

            if (isUpdateDuplicateSundry(rowData)) {
                showErrorAndScrollToTop("SUNDRIES.FORM.VALIDATION_MESSAGES.SUNDRY_ALREADY_ADDED");
                return;
            }

            if (model.canManageSundries) {
                rowData.merchantGroupUniqueTag = appConfig.selectedMerchantGroup().uniqueTag;
                notify.forHttp(sundryService.updateSundryItemType(rowData),
					{ startTranslationId: 'SUNDRIES.FORM.SAVING_SUNDRY_TEXT' })
					.then(function (response) {
                        if (response.succeeded) {
                            model.editingInactiveData[rowData.sundryItemTypeId] = false;
                            if (rowData.isActive) {
                                removeFromArray(model.inactiveSundries, rowData);
                            }

                            refreshInactiveSundriesGrid();

                            notify.success("SUNDRIES.FORM.UPDATING_SUCCESS", undefined, 1000);
                        }
					})
                    .catch(function(response) {
                        showErrorAndScrollToTop(response);
                    });
            }
        }

        function showErrorAndScrollToTop(response) {
            notify.showError(response);
            
            window.scrollTo(0, 0);
        }

        function setSelectedCurrencySymbol() {
            if (model.newSundryItemType.currency.currencySymbol !== null) {
                model.selectedCurrencySymbol = model.newSundryItemType.currency.currencySymbol;
            }
        }

        function removeFromArray(array, item) {
            var index = array.indexOf(item);
            if (index > -1) {
                array.splice(index, 1);
            }
        }

        function cancelActiveUpdate(rowData) {
            notify.dismissErrorAlert();
            refreshActiveSundriesGrid();
            model.editingActiveData[rowData.sundryItemTypeId] = false;
        }

        function cancelInactiveUpdate(rowData) {
            notify.dismissErrorAlert();
            refreshInactiveSundriesGrid();
            model.editingInactiveData[rowData.sundryItemTypeId] = false;
        }

        $scope.tabClass = function (tabName) {
            var cssClass = "";
            if (tabName === activeTab) {
                cssClass = "active";
            }
            return cssClass;
        }

        $scope.tabVisible = function (tabName) {
            return tabName === activeTab;
        }

        function showAddForm(show) {
            if (!show) {
                resetAddSundryForm();
            }

            model.addFormIsShown = show;
        }

        function resetAddSundryForm() {
            model.newSundryItemType.type = null;
            model.newSundryItemType.unitPrice = null;
            model.newSundryItemType.currency = {
                description: null,
                numericCode: null,
                currencySymbol: null
            }
            model.newSundryItemType.status = angular.copy(model.sundryStatuses[0].status);
            model.selectedCurrencySymbol = "$";
        }

        function getCurrencySymbol() {
            if (preActivationService.hasCards()) {
                return preActivationService.getCardsPreActivated()[0].bin.currency.currencySymbol;
            } else if (sundryService.hasSundries()) {
                return sundryService.getSundries()[0].currency.currencySymbol;
            }
            return "$";
        }
    }
}());