﻿(function () {
    "use strict";

    angular.module("cardspotWeb.areas.tools")
        .controller("Tools", Tools);

    Tools.$inject = ["authService", "appConfig"];

    function Tools (authService, appConfig) {
        var model = this;        
        model.canManageReceiptItems = authService.canManageReceiptItems();
        model.canManagePropertySettings = authService.canManagePropertySettings();
        model.canManageCampaigns = authService.canManageCampaigns() && (appConfig.selectedMerchantGroup() && appConfig.selectedMerchantGroup().manageCampaigns);
        model.canManageLocations = appConfig.selectedMerchantGroup() && appConfig.selectedMerchantGroup().manageLocations && authService.canAddSoftwareClientInstance() && authService.canManageLocations();
        model.canManageMerchantGroupSundries = authService.canManageMerchantGroupSundries();
    }
}());
