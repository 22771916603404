﻿(function () {
    "use strict";

    angular.module("cardspotWeb.areas.onlineResources")
        .controller("OnlineResources", OnlineResources);

    OnlineResources.$inject = ["$scope", "$timeout", "SF_EVENTS", "storeLinks", "$translate"];

    function OnlineResources($scope, $timeout, SF_EVENTS, storeLinks, $translate) {
        var model = this;        
        model.cardSpotMobileInstallUrl = storeLinks.cardspotMobileInstallUrl;
        model.documentsRepositoryUrl = storeLinks.documentsRepositoryUrl;   
        model.infoCenterReportsUrl = storeLinks.infoCenterReportsUrl;
        model.currentLang = () => {
            return $translate.use();
        };
    }
}());