(function () {
	"use strict";

	angular.module("cardspotWeb.areas.enterSales")
        .directive("emlValidateZeroDollar", validateZeroDollar);

	validateZeroDollar.$inject = [];

	function validateZeroDollar() {

		return {
			restrict: "A",
			require: "ngModel",
			scope: {
				card: "="				
			},
			link: function (scope, element, attrs, ctrl) {

				ctrl.$validators.zeroDollar = function(modelValue, viewValue) {

					if (modelValue === 0 && scope.card.isReloadable === false){
						return false;
					}
					return true;
				};									
			}
		}
	}
}());