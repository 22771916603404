(function () {
    "use strict";

    angular.module("cardspotWeb.areas.integratedpayment")
        .controller("IntegratedPaymentModal", IntegratedPaymentModal);

    IntegratedPaymentModal.$inject = ["$scope", "$uibModalInstance", "paymentType", "paymentAmount", "modalType","currencySymbol"];

    function IntegratedPaymentModal($scope, $uibModalInstance, paymentType, paymentAmount, modalType, currencySymbol) {
        var model = this;
        model.okVisible = false;
        model.paymentType = paymentType;
        model.paymentAmount = paymentAmount;
        model.titles = { payment: 'INTEGRATED_PAYMENT.AUTHORIZING', refund: 'INTEGRATED_PAYMENT.REFUND', reversal: 'INTEGRATED_PAYMENT.REVERSAL' }
        model.bodyTitle = model.titles[modalType];
        model.bodyText = '';
        model.close = close;
        model.currencySymbol = currencySymbol;

        $scope.$on('updateModalText', function (event, args) {
            $scope.$apply(function () {
                model.bodyText = args.text;

                if (args.complete) {
                    model.okVisible = true;
                }
            });
        });

        $scope.$on('updateModalPaymentInfo', function (event, args) {
            $scope.$apply(function () {
                model.paymentType = args.paymentType;
                model.paymentAmount = args.paymentAmount;
            });
        });

        function close() {
            $uibModalInstance.dismiss("cancel");
        }
    }
}());
