import htmlTemplate from './character-count.directive.html';

(function() {
    "use strict";

    angular.module("cardspotWeb.areas.global")
        .directive("emlCharacterCount", configDirective);

    function configDirective() {
        return {
            restrict: "E",
            scope: {
                inputMaxLength: "=",
                formInputLength: "="
            },
            controller: CharacterCountController,
            controllerAs: "model",
            template: htmlTemplate
        };
    }

    CharacterCountController.$inject = ["$scope"];

    function CharacterCountController($scope) {
        var model = this;
        
        model.getCharacterCountClass = getCharacterCountClass;
        model.getCharactersRemaining = getCharactersRemaining;

        function getCharacterCountClass(charactersRemaining) {
            return { "error": charactersRemaining <= 0, "bubble": charactersRemaining <= 0 };
        }

        function getCharactersRemaining() {
            return $scope.inputMaxLength - $scope.formInputLength;
        }
    }
}());