﻿import appConfigTemplate from './application-configuration.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
        .config(configApplicationConfiguration);

    configApplicationConfiguration.$inject = ["$routeProvider", "NAV_URLS"];

    function configApplicationConfiguration($routeProvider, NAV_URLS) {

        $routeProvider
             .when(NAV_URLS.APPLICATION_CONFIGURATION, {
                 template: appConfigTemplate,
                 controller: "ApplicationConfiguration",
                 controllerAs: "model"
             });
    }
}());