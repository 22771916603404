import _ from 'underscore';

(function () {
	"use strict";

	angular.module("angular.emlpayments")
		.factory("lookupValues", lookupValuesFactory);

	lookupValuesFactory.$inject = ["$q", "api", "notify", "appConfig"];

	function lookupValuesFactory($q, api, notify, appConfig) {
		var lockCardReasonsPromise,
			salesChannelsPromise,
			countriesPromise,
			regionCollections = [],
			accountExpirationExtensionReasonsPromise,
			countryCodePromise,
			kycDocumentTypePromise,
			activeMerchantGroupProgramsPromise,
			riskAssessmentLevelsPromise

		return {
			getLockCardReasons,
			getSalesChannels,
			getCountries,
			getRegions,
			getAccountExpirationExtensionReasons,
			getCountryCode,
			reset,
			getKYCDocumentTypes,
			getActiveMerchantGroupPrograms,
			getRiskAssessmentLevels
		};

		function getLockCardReasons() {

			if (!lockCardReasonsPromise) {
				lockCardReasonsPromise = api.lookupValues.getLockCardReasons();
			}

			return lockCardReasonsPromise;
		}

		function getSalesChannels() {
			salesChannelsPromise = notify.forHttp(api.activation.getSalesChannels(), { startTranslationId: "LOOKUP_VALUES.GET_SALES_CHANNELS_PROGRESS_TEXT" });
			return salesChannelsPromise;
		}

		function getCountries() {
			if (!countriesPromise) {
				countriesPromise = notify.forHttp(api.lookupValues.getCountries(), { startTranslationId: "LOOKUP_VALUES.GET_COUNTRIES_PROGRESS_TEXT" });
			}
			return countriesPromise;
		}

		function getRegions(countryKey) {

			var deferred = $q.defer();

			var countryRegion = _.find(regionCollections, function (regionCollection) {
				return regionCollection.countryKey === countryKey;
			});

			if (countryRegion) {
				deferred.resolve(countryRegion.regions);
			} else {
				notify.forHttp(api.lookupValues.getRegions(countryKey), { startTranslationId: "LOOKUP_VALUES.GET_REGIONS_PROGRESS_TEXT" })
					.then(function (data) {
						var newCountryRegion = {
							countryKey: countryKey,
							regions: data
						};
						regionCollections.push(newCountryRegion);
						deferred.resolve(newCountryRegion.regions);

					}, function (error) {
						deferred.reject(error);
					});
			}
			return deferred.promise;
		}

		function getAccountExpirationExtensionReasons() {

			if (!accountExpirationExtensionReasonsPromise) {
				accountExpirationExtensionReasonsPromise = api.lookupValues.getAccountExpirationExtensionReasons();
			}

			return accountExpirationExtensionReasonsPromise;
		}

		function getCountryCode(countryDescription) {
			if (!countryCodePromise) {
				countryCodePromise = api.lookupValues.getCountryCode(countryDescription);
			}

			return countryCodePromise;
		}

		function getKYCDocumentTypes() {
			if (!kycDocumentTypePromise) {
				kycDocumentTypePromise = api.lookupValues.getKYCDocumentTypes();
			}

			return kycDocumentTypePromise;
		}

		function getActiveMerchantGroupPrograms() {
			if (!activeMerchantGroupProgramsPromise) {
				activeMerchantGroupProgramsPromise = api.lookupValues.getActiveMerchantGroupPrograms(appConfig.selectedMerchantGroup().uniqueTag);
			}

			return activeMerchantGroupProgramsPromise;
		}

		function getRiskAssessmentLevels() {
			if (!riskAssessmentLevelsPromise) {
				riskAssessmentLevelsPromise = api.lookupValues.getRiskAssessmentLevels();
			}

			return riskAssessmentLevelsPromise;
		}

		function reset() {
			lockCardReasonsPromise = null;
			salesChannelsPromise = null;
			countriesPromise = null;
			regionCollections = [];
			accountExpirationExtensionReasonsPromise = null;
			kycDocumentTypePromise = null;
			activeMerchantGroupProgramsPromise = null;
		}
	}
}());
