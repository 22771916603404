import htmlTemplate from '../enter-sales/enter-sales.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
        .config(configEnterSundries);

    configEnterSundries.$inject = ["$routeProvider", "NAV_URLS", "CLAIMS"];

    function configEnterSundries($routeProvider, NAV_URLS, CLAIMS) {

        $routeProvider
             .when(NAV_URLS.ENTER_SUNDRIES, {
                 template: htmlTemplate,
                 controller: "EnterSales",
                 controllerAs: "model",
                 requiredClaim: CLAIMS.ACTIVATE_CARD

             });
    }
}());