import htmlTemplate from './integrated-payment.modal.html';

(function () {
    "use strict";

    angular.module("cardspotWeb.areas.integratedpayment")
        .directive("integratedPayment", configDirective);

    function configDirective() {
        return {
            restrict: "E",
            scope: {
                payments: "=",
                isTrailingSymbol: "=",
                currencySeparators: "=",
                fractionSize: "=",
                currentLanguage: "=",
                currencySymbol: "="
            },
            controller: IntegratedPaymentController,
            controllerAs: "model",
        };
    }

    IntegratedPaymentController.$inject = ["$scope", "$window", "$uibModal", "integratedPaymentService"];

    function IntegratedPaymentController($scope, $window, $uibModal, integratedPaymentService) {
        var model = this;
        model.modal = undefined;
        model.integratedPayments = [];
        model.integratedReversals = [];
        model.currentPayment = undefined;
        model.types = {payment: 'payment', refund: 'refund', reversal: 'reversal'};
        model.currentType = undefined;
        
        $window.setPosTransactionId = function (value) {
            if(model.currentType != model.types.reversal){
                $scope.payments[model.currentPayment.key].externalAuthorizationTransactionId = value;
            }
        }

        $window.updatePaymentModal = function (value, complete, success) {
            if (complete) {
                integratedPaymentService.logIntegratedPayment(model.currentType, model.currentPayment.value.paymentType.displayName, 
                    model.currentPayment.value.amount, complete, success, value);

                if (model.integratedPayments.length > 0 && success) {
                    
                    $scope.$broadcast('updateModalText', { text: value, complete: false });
                    processPayment();
                }
                else if (model.integratedReversals.length > 0 && success) {
                    
                    $scope.$broadcast('updateModalText', { text: value, complete: false });
                    processReversal();
                }
                else {
                    $scope.$broadcast('updateModalText', { text: value, complete: complete });

                    if(model.currentType === model.types.reversal)
                        $scope.$emit('integratedReversalHandled', success);
                    else
                        $scope.$emit('integratedPaymentsHandled', success);
                    
                    if (success)
                        model.modal.close();
        
                    model.modal = undefined;
                }
            }
            else {
                $scope.$broadcast('updateModalText', { text: value, complete: complete });
            }
        }

        function openModal(paymentType, paymentAmount) {

            model.modal = $uibModal.open({
                template: htmlTemplate,
                controller: "IntegratedPaymentModal",
                controllerAs: "model",
                backdrop: "static",
                keyboard: false,
                size: "s-md",
                scope: $scope,
                resolve: {
                    paymentType: function () { return paymentType; },
                    paymentAmount: function () { return paymentAmount; },
                    modalType: function() {return model.currentType},
                    currencySymbol: function(){return $scope.currencySymbol}
                }
            });
        }

        $scope.$on('handleIntegratedPayments', function (event) {
            model.currentType = model.types.payment;
            processIntegratedPayment();
        });

        $scope.$on('handleIntegratedRefunds', function (event){
            model.currentType = model.types.refund;
            processIntegratedPayment();
        });
        
        $scope.$on('handleIntegratedReversal', function(event, payment){
            model.currentPayment = {key: 0, value: payment};
            model.currentType = model.types.reversal;
            integratedPaymentService.processReversal(payment);
            openModal(payment.paymentType.displayName, payment.amount);
        });

        $scope.$on('handleIntegratedReverseAll', function(event, payment){
             // clear the current payment
             model.currentPayment = undefined;
             model.integratedReversals = [];
             model.currentType = model.types.reversal;

            for (var i = 0; i < $scope.payments.length; i++) {
                if ($scope.payments[i].paymentType.isCreditCard &&
                    $scope.payments[i].externalAuthorizationTransactionId) {
                    model.integratedReversals.push({ key: i, value:$scope.payments[i]});
                }
            }
            if (model.integratedReversals.length > 0) {
                processReversal();
            }
            else {
                $scope.$emit('integratedReversalHandled', true);
            }
        });

        function processReversal(){
           model.currentPayment = model.integratedReversals.shift();
           integratedPaymentService.processReversal(model.currentPayment.value);

            let paymentType = model.currentPayment.value.paymentType.displayName;
            let paymentAmount = model.currentPayment.value.amount;
            if (!model.modal)
                openModal(paymentType, paymentAmount);
            else {
                $scope.$broadcast('updateModalPaymentInfo', { paymentType: paymentType, paymentAmount: paymentAmount });
            }
        }

        function processIntegratedPayment(){
            if (!integratedPaymentService.integratedPaymentsEnabled()) {
                $scope.$emit('integratedPaymentsHandled', true);
                return;
            }
            model.integratedPayments = [];

            for (var i = 0; i < $scope.payments.length; i++) {
                if ($scope.payments[i].paymentType.isCreditCard &&
                    !$scope.payments[i].externalAuthorizationTransactionId) {
                    model.integratedPayments.push({ key: i, value: $scope.payments[i] });
                }
            }

            if (model.integratedPayments.length > 0) {
                    processPayment();
            }
            else {
                $scope.$emit('integratedPaymentsHandled', true);
            }
        }

        function processPayment() {
            model.currentPayment = model.integratedPayments.shift();

            var paymentType = model.currentPayment.value.paymentType.displayName;
            var paymentAmount = model.currentPayment.value.amount;

            if(model.currentType === model.types.refund)
                integratedPaymentService.processRefund(model.currentPayment.value);
            else
                integratedPaymentService.processPayment(model.currentPayment.value);

            if (!model.modal)
                openModal(paymentType, paymentAmount);
            else {
                $scope.$broadcast('updateModalPaymentInfo', { paymentType: paymentType, paymentAmount: paymentAmount });
            }
        }
    }
}());