﻿(function () {
	"use strict";
	angular.module("cardspotWeb.areas.lookupCustomer")
    .controller("ConfirmActivateCards", ConfirmActivateCards);

	ConfirmActivateCards.$inject = ["$scope", "$uibModalInstance"];

	function ConfirmActivateCards($scope, $uibModalInstance) {
		
		$scope.ok = function () {
			$uibModalInstance.close();
		};

		$scope.cancel = function () {
			$uibModalInstance.dismiss("cancel");
		};
	}
}());
