﻿import _ from 'underscore';

(function () {
	"use strict";

	angular.module("angular.emlpayments")
    .factory("cardFees", cardFeesServiceFactory);

	cardFeesServiceFactory.$inject = ["$q", "api", "notify"];

	function cardFeesServiceFactory($q, api, notify) {
		var perCardFees = [],
			transactionFees = [],
			cardsToBeActivated;

		return {
			getFees,
			getTransactionFees,
			haveTransactionFees,
			removeTransactionFee,
			addTransactionFee,
			getPerCardFees,
			havePerCardFees,
			removePerCardFee,
			addPerCardFee,
			hasPerCardFeeForCardType,
			reset: reset
		};

		function addTransactionFee(transactionFee) {
			if (angular.isArray(transactionFees)) {
				transactionFees.push(transactionFee);
			}
		}

		function addPerCardFee(perCardFee) {
			if (angular.isArray(perCardFees) && !hasPerCardFeeForCardType(perCardFee)) {
				perCardFees.push(perCardFee);
			}
		}

		function hasPerCardFeeForCardType(perCardFee) {
			return perCardFees.length > 0
				&& _.find(perCardFees, function (aPerCardFee) {
					return perCardFee.cardType === aPerCardFee.cardType;
			});
		}

		function removeTransactionFee(transactionFee) {
			transactionFees = _.reject(transactionFees, function(aFee) {
				return aFee.$$hashKey === transactionFee.$$hashKey;
			});
		}

		function removePerCardFee(perCardFee) {
			perCardFees = _.reject(perCardFees, function(aFee) {
				return aFee.$$hashKey === perCardFee.$$hashKey;
			});
		}

		function mapCards() {
			
			const model = [];

			cardsToBeActivated.forEach(card => {
				if (card.cards) {
					card.cards.forEach(groupCard => {
						model.push({
							cardType: card.currentCardType,
							amount: card.amount
						});
					})
				} else {
					model.push({
						cardType: card.currentCardType,
						amount: card.amount
					});
				}
			})

			return model;
		}

		function getFees(merchantGroupUniqueTag, programUniqueTag, cards) {

			cardsToBeActivated = cards;
			var deferred = $q.defer();
			notify.forHttp(api.activation.getFees(merchantGroupUniqueTag, programUniqueTag, mapCards()), { startTranslationId: "ENTER_SALES.FEES.GETTING_FEES_PROGRESS_TEXT" })
				.then(function(response) {
					perCardFees = response.cardTypeFees;
					transactionFees = response.transactionFees;
					deferred.resolve(response);
				}, function(error) {
					deferred.reject(error);
				});
			return deferred.promise;

		}

		function reset() {
			perCardFees = [];
			transactionFees = [];
		}

		function getPerCardFees() {
			return perCardFees;
		}
		function getTransactionFees() {
			return transactionFees;
		}
		function havePerCardFees() {
			return angular.isArray(perCardFees) && perCardFees.length > 0;
		}
		function haveTransactionFees() {
			return angular.isArray(transactionFees) && transactionFees.length > 0;
		}
	}
}());