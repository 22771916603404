﻿import htmlTemplate from './search-form.directive.html';

(function () {
	"use strict";

	angular.module("cardspotWeb.areas.fulfillment")
        .directive("emlSearchForm", configDirective);

	function configDirective() {
		return {
			restrict: "E",
			scope: {
				search: "=",
				onSearchClick: "=",
				dateFormat: "="
			},
			controller: SearchFormController,
			controllerAs: "model",
			template: htmlTemplate
		};
	}

	SearchFormController.$inject = ["$scope"];

	function SearchFormController($scope) {
		var model = this;
		model.resetSearchForm = resetSearchForm;
       

		$scope.beginDatePopup = {
			opened: false
		};

		$scope.openBeginDatePicker = function () {
			$scope.beginDatePopup.opened = true;
		};

		$scope.endDatePopup = {
			opened: false
		};

		$scope.openEndDatePicker = function () {
			$scope.beginDatePopup.opened = false;
			$scope.endDatePopup.opened = true;
		};

		function resetSearchForm() {
			$scope.search.filterText = null;
			$scope.search.orderBeginDate = null;
			$scope.search.orderEndDate = null;
			$scope.searchForm.$setPristine();
		}
	}
}());
