﻿import _ from 'underscore';

angular.module("cardspotWeb")
    .config(configModule);
configModule.$inject = ["$provide"];

function configModule($provide) {

    $provide.decorator("$translate", decorateTranslateService);
    decorateTranslateService.$inject = ["$delegate", "LANGUAGES", "DEFAULT_LANG_KEY"];

    function decorateTranslateService($delegate, LANGUAGES, DEFAULT_LANG_KEY) {

        var getLanguageFromKey = function (langKey) {
            langKey = angular.isDefined(langKey) ? langKey : DEFAULT_LANG_KEY;
            return _.find(LANGUAGES, function (lang) {
                return lang.key === langKey.toLowerCase();
            });
        };

        $delegate.currentLanguage = function () {
            return getLanguageFromKey($delegate.use());
        };

        return $delegate;
    }
}