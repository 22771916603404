﻿import login from './login.html';
(function () {
    "use strict";

    angular
        .module("cardspotWeb")
        .config(configLogin);

    configLogin.$inject = ["$routeProvider", "NAV_URLS"];

    function configLogin($routeProvider, NAV_URLS) {

        $routeProvider
          .when(NAV_URLS.LOGIN + "/:id?", {
              template: login,
              controller: "Login",
              controllerAs: "model"
          });
    }

} ());