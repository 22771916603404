(function() {
    "use strict";

    angular.module("angular.emlpayments")
        .factory("settings", settingsServiceFactory);

    settingsServiceFactory.$inject = ["api", "appConfig"];

    function settingsServiceFactory(api, appConfig) {
        return {
            getSettings,
            setSettings
        };

        function getSettings() {
            return api.configuration.getMerchantGroupSettings(appConfig.selectedMerchantGroup().uniqueTag);
        }

        function setSettings(
            supportsCustomerAtCardLevel,
            supportsSearchByClientTrackingId,
            defaultCampaignSelection,
            manageCampaigns,
            receiptGroupingThreshold,
            manageLocations,
            supportSearchByCarrierNumber,
            corporateInvoiceEnabled,
            individualCardReceiptsEnabled,
            clickAndCollectEnabled,
            hvtRequestEnabled
        ) {
            return api.configuration.setMerchantGroupSettings(
                appConfig.selectedMerchantGroup().uniqueTag,
                supportsCustomerAtCardLevel,
                supportsSearchByClientTrackingId,
                defaultCampaignSelection,
				manageCampaigns,
                receiptGroupingThreshold,
                manageLocations,
                supportSearchByCarrierNumber,
                corporateInvoiceEnabled,
                individualCardReceiptsEnabled,
                clickAndCollectEnabled,
                hvtRequestEnabled);
        }
    }
}());
