(function () {
	"use strict";

	angular.module("angular.emlpayments")
    .factory("hvtRequestService", hvtRequestServiceFactory);

	hvtRequestServiceFactory.$inject = ["$q", "api", "notify"];

	function hvtRequestServiceFactory($q, api, notify) {
		var selectedProgramTag,
        purchaserInformation,
        companyInformation,
        useOfCards,
        transactionInformation;

		return {
            addSelectedProgramTag,
            getCurrentSelectedProgramTag,
			addPurchaserInformation,
            getPurchaserInformation,
            addCompanyInformation,
            getCompanyInformation,
            addUseOfCards,
            getUseOfCards,
            addTransactionInformation,
            getTransactionInformation,
            submitHvtRequest,
            reset
		};

        function addSelectedProgramTag(programTag) {
            selectedProgramTag = programTag
        }

        function getCurrentSelectedProgramTag() {
            return selectedProgramTag;
        }

		function addPurchaserInformation(purchaserInfo) {
            purchaserInformation = purchaserInfo;
        }

        function getPurchaserInformation() {
            return purchaserInformation;
        }

        function addCompanyInformation(companyInfo) {
            companyInformation = companyInfo;
        }

        function getCompanyInformation() {
            return companyInformation;
        }

        function addUseOfCards(cardUse) {
            useOfCards = cardUse;
        }

        function getUseOfCards() {
            return useOfCards;
        }

        function addTransactionInformation(transactionInfo) {
            transactionInformation = transactionInfo;
        }

        function getTransactionInformation() {
            return transactionInformation;
        }

        function submitHvtRequest(programUniqueTag, purchaserInformation, companyInformation, useOfCards, transactionInformation) {
            return api.hvt.submitHvtRequest(programUniqueTag, purchaserInformation, companyInformation, useOfCards, transactionInformation);
        }

        function reset() {
            selectedProgramTag = null;
            purchaserInformation = null;
            companyInformation = null;
            useOfCards = null;
            transactionInformation = null;
        }
	}
}());