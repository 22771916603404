(function () {
	"use strict";

	angular.module("angular.emlpayments")
        .directive("emlPrintIframe", printIframe);

	printIframe.$inject = [];

	function printIframe() {

		return {
			restrict: "A",
			scope: {
				getIframeContentPromise: "=",
				getIframeContentPromiseArgument: "=",
				iframeContent: "@"
			},
			link: function (scope, element, attrs) {

				var iframe = attrs.printIframe ? $("#" + attrs.printIframe) :
						$("#print-iframe").length > 0 ? $("#print-iframe")
						: $('<iframe id="print-iframe" style="height:1px;width:1px;border:0;padding:0;margin:0"></iframe>').appendTo("body");

				$(element)
					.on("click", function (e) {
						e.preventDefault();
						var iFrameContent = scope.iframeContent;
						if (iFrameContent){
							var contentWindow = iframe[0].contentWindow;
							iFrameContent = iFrameContent.replace("<body>", "<body onload=\"window.focus();window.print()\">");
							contentWindow.document.open();
							contentWindow.document.write(iFrameContent);
							contentWindow.document.close();
							return true;
						} else {
							scope.getIframeContentPromise(scope.getIframeContentPromiseArgument)
								.then(function (html) {
									var contentWindow = iframe[0].contentWindow;
									html = html.replace("<body>", "<body onload=\"window.focus();window.print()\">");
									contentWindow.document.open();
									contentWindow.document.write(html);
									contentWindow.document.close();
									return true;
							});
						}
					});
			}
		};
	}
}());