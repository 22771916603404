﻿(function () {
    "use strict";

    angular.module("cardspotWeb")
    .config(configJwtInterceptorProvider);

    configJwtInterceptorProvider.$inject = ["jwtInterceptorProvider", "STORE_KEYS"];

    function configJwtInterceptorProvider(jwtInterceptorProvider, STORE_KEYS) {

        jwtInterceptorProvider.tokenGetter = ["store", function (store) {
            return store.get(STORE_KEYS.JWT);
        }];
    }

}());