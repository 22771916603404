import _ from 'underscore';

(function () {
    "use strict";

    angular.module("angular.emlpayments")
    .factory("activationService", activationServiceFactory);

    activationServiceFactory.$inject = ["$q", "$log", "api", "appConfig", "notify", "preActivationService", "cardFees", "sundryService", "REGION_TYPES"];

    function activationServiceFactory($q, $log, api, appConfig, notify, preActivationService, cardFees, sundryService, REGION_TYPES) {
        var activations = [],
            paymentTypesPromise,
            activationCardCustomerEmail;        
        
        return {
            activate,
            activateCardPerCustomer, 
            activations: getActivations,
            mostRecentActivation: getMostRecentActivation,
            hasActivations,
            reset,
            getPaymentTypes,
            setActivationCardCustomerEmail,
            getActivationCardCustomerEmail,
            resetActivationCardCustomerEmail
        };

        function setActivationCardCustomerEmail(email) {
            activationCardCustomerEmail = email;
        }

        function getActivationCardCustomerEmail() {
            return activationCardCustomerEmail;
        }

        function resetActivationCardCustomerEmail() {
            activationCardCustomerEmail = undefined;
        }

        function getMostRecentActivation() {
            return hasActivations() ? activations[0] : null;
        }

        function activate(payments, salesChannel, note, customer, lockOnActivation) {                        
            // two possible activation processes here
            // hasCards --> activate
            // !hasCards (meaning sundry only) --> sundryPurchase
            if(preActivationService.hasCards()){
                return cardActivate(payments, salesChannel, note, customer, lockOnActivation);
            }
            else {
                return sundryOnlyPurchase(payments, salesChannel);
            }            
        }
        
        function activateCardPerCustomer(payments, salesChannel, note, lockOnActivation) {                        
            var deferred = $q.defer();
                                                            
            var merchantGroupUniqueTag = appConfig.selectedMerchantGroup().uniqueTag,
                programUniqueTag = preActivationService.getProgramUniqueTag(),
                location = appConfig.getSelectedLocation().location,
                activationCardPerCustomer = {
                    salesChannel: salesChannel,
                    location: location,
                    note: note,                    
                    cardCustomerActivations: mapCardCustomers(),
                    payments: mapPayments(payments),                    
                    cardTypeFees: cardFees.getPerCardFees(),
                    lockOnActivation: lockOnActivation                    
                };    
                
            notify.forHttp(api.activation.activateCardPerCustomer(merchantGroupUniqueTag, programUniqueTag, activationCardPerCustomer),
                { startTranslationId: "ENTER_SALES.SALE_SUMMARY.COMPLETE_SALE_PROGRESS_TEXT" })
                .then(function (data) {
                    activationCardPerCustomer.currencySymbol = preActivationService.getCurrencySymbol();
                    activationCardPerCustomer.confirmation = data;
                    activations.unshift(activationCardPerCustomer);                    
                    preActivationService.reset();
                    cardFees.reset();
                    sundryService.reset();
                    deferred.resolve(activationCardPerCustomer);
                }, function(error) {
                    deferred.reject(error);
                });

            return deferred.promise;    
        }
        
        function cardActivate(payments, salesChannel, note, customer, lockOnActivation){
            var deferred = $q.defer();
            
            var merchantGroupUniqueTag = appConfig.selectedMerchantGroup().uniqueTag,
                programUniqueTag = preActivationService.getProgramUniqueTag(),
                location = appConfig.getSelectedLocation().location,
                activation = {
                    salesChannel: salesChannel,
                    location: location,
                    note: note,
                    customer: customer,
                    cardActivations: mapCards(),
                    payments: mapPayments(payments),
                    transactionFees: cardFees.getTransactionFees(),
                    cardTypeFees: cardFees.getPerCardFees(),
                    lockOnActivation: lockOnActivation,
                    sundries: mapSundries()
                };

            notify.forHttp(api.activation.activate(merchantGroupUniqueTag, programUniqueTag, activation),
                { startTranslationId: "ENTER_SALES.SALE_SUMMARY.COMPLETE_SALE_PROGRESS_TEXT" })
                .then(function (data) {
                    activation.currencySymbol = preActivationService.getCurrencySymbol();
                    activation.confirmation = data;
                    activations.unshift(activation);                    
                    preActivationService.reset();
                    cardFees.reset();
                    sundryService.reset();
                    deferred.resolve(activation);
                }, function(error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }
        
        function sundryOnlyPurchase(payments, salesChannel){
            var deferred = $q.defer();
            
            var merchantGroupUniqueTag = appConfig.selectedMerchantGroup().uniqueTag,                
                location = appConfig.getSelectedLocation().location,
                sundrySale = {
                    salesChannel: salesChannel,
                    location: location,                    
                    payments: mapPayments(payments),                    
                    sundries: mapSundries()
                };

            notify.forHttp(api.sundries.sundryPurchase(merchantGroupUniqueTag, sundrySale),
                { startTranslationId: "ENTER_SALES.SALE_SUMMARY.COMPLETE_SALE_PROGRESS_TEXT" })
                .then(function (data) {
                    sundrySale.currencySymbol = sundryService.getCurrencySymbol();
                    sundrySale.confirmation = data;
                    activations.unshift(sundrySale);                                        
                    sundryService.reset();
                    deferred.resolve(sundrySale);
                }, function(error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }                

        function mapCards() {
                        
            var activationCards = _.map(_.filter(preActivationService.getCardsPreActivated(), function (card) {
                return !card.cards;
            }), function (card) {
                return {
                    cardId: card.cardId,
                    cardNumber: card.primaryAccountNumber,
                    cardType: card.currentCardType,
                    amount: card.amount,
                    campaign: card.campaign
                };
            });
            
            var groupActivations = _.filter(preActivationService.getCardsPreActivated(), function (card) {
                return card.cards;
            });
            
            var groupActivationCards = [];
            
            angular.forEach(groupActivations, function (groupActivation) {
                angular.forEach(groupActivation.cards,
                    function(card) {
                        groupActivationCards.push({
                            cardId: card.cardId,
                            cardNumber: card.primaryAccountNumber,
                            cardType: groupActivation.currentCardType,
                            amount: groupActivation.amount,
                            campaign: groupActivation.campaign
                        });
                    });
            });                        
                            
            return _.union(activationCards,groupActivationCards);                       
        }
        
        function mapCardCustomers() {
            return  _.map(preActivationService.getCardsPreActivated(), function(card){
               return {
                       cardActivationModel: mapCardActivation(card),                    
                    customer: mapCustomer(card.customer)                                    
               };
            });
        }

        function mapCardActivation(card) {
            return {
                cardId: card.cardId,
                cardNumber: card.primaryAccountNumber,
                cardType: card.currentCardType,
                amount: card.amount,
                campaign: card.campaign
            };
        }

        function mapPayments(payments) {
            return _.map(payments, function (payment) {
                return {
                    paymentType: payment.paymentType.paymentType,
                    amount: payment.amount
                };
            });
        }

        function mapSundries() {
            
            if(!sundryService.hasSundries()){
                return;
            }        
            
            var sundries = _.map(sundryService.getSundries(), function(sundry){
                return {
                    sundryItemTypeId: sundry.sundryItemTypeId,
                    unitPrice: sundry.unitPrice,
                    currencyNumericCode: sundry.currency.numericCode,
                    quantity: sundry.quantity
                };
            });
                                        
            return sundries;                       
        }
        
        function mapCustomer(cardCustomer) {
            
            if(!cardCustomer){
                return;
            }
            
            var customer = angular.copy(cardCustomer);
            customer.card = null;    
            if (cardCustomer.selectedRegion && cardCustomer.country.regionType === REGION_TYPES.STATE) {
                customer.state = cardCustomer.selectedRegion.regionKey;
            } else if (cardCustomer.selectedRegion) {
                customer.province = cardCustomer.selectedRegion.regionKey;
            }                                   
            customer.country = cardCustomer.country ? cardCustomer.country.countryKey : null;
            return customer;
        }

        function hasActivations() {
            return angular.isArray(activations) && activations.length > 0;
        }

        function getActivations() {
            return angular.isArray(activations) ? activations : [];
        }

        function reset() {
            activations = [];
        }

        function getPaymentTypes(){
            if (!paymentTypesPromise) {
                paymentTypesPromise = notify.forHttp(api.activation.getPaymentTypes(appConfig.selectedMerchantGroup().uniqueTag), { startTranslationId: "ENTER_SALES.SALE_SUMMARY.GET_PAYMENT_TYPES" });
            }
            return paymentTypesPromise;
        }
    }

}());