import htmlTemplate from './program.directive.html';

(function() {
    "use strict";

    angular.module("cardspotWeb.areas.hvtRequest")
        .directive("emlProgram", configDirective);

    function configDirective() {
        return {
            restrict: "E",
            scope: {
                programComplete:"="
            },
            controller: ProgramController,
            controllerAs: "model",
            template: htmlTemplate
        };
    }

	ProgramController.$inject = ["$q", "$scope", "$rootScope", "$translate", "lookupValues", "SF_EVENTS"];

	function ProgramController($q, $scope, $rootScope, $translate, lookupValues, SF_EVENTS) {
        var model = this;
        model.activePrograms;
        model.programUniqueTag = null;
        model.onProgramChange = onProgramChange;
        model.program;

        $rootScope.$on(SF_EVENTS.HVT_REQUEST_SUCCEEDED, handleHvtRequestSucceeded);

        init();

        function init() {
            lookupValues.getActiveMerchantGroupPrograms()
            .then((resp) => {
                model.activePrograms = resp.data;
            })
        }

        function onProgramChange() {
            model.program = model.activePrograms.filter(p => p.uniqueTag === model.programUniqueTag)[0];
            $rootScope.$emit(SF_EVENTS.PROGRAM_SELECTED, { programUniqueTag: model.programUniqueTag, programName: model.program.programName })
        }

        function handleHvtRequestSucceeded() {
            model.programUniqueTag = null;
        }
    }
}());