(function () {
    "use strict";

    angular.module("cardspotWeb.areas.lookupSales")
        .controller("LookupSales", LookupSales);

    LookupSales.$inject = ["$scope", "$q", "notify", "$routeParams","transactionService", "receipts", "authService", "appConfig", "navigate", "$translate", "cardService", "$uibModal"];

    function LookupSales ($scope, $q, notify, $routeParams, transactionService, receipts, authService, appConfig, navigate, $translate, cardService, $uibModal) {
        var model = this;

       model.search = {
            transactionId: null
        };

        model.getReceiptHtml = getReceiptHtml;
        model.transactionDetailsExpanded = true;
        model.cardDetailsExpanded = true;
        model.lookupByTransactionId = lookupByTransactionId;
        model.lookupByCampaign = lookupByCampaign;
        model.usedNewActivation = usedNewActivation;
        model.canVoidTransaction = canVoidTransaction;
        model.canActivationLock = canActivationLock;
        model.showActivationLock = showActivationLock;
        model.canActivationUnlock = canActivationUnlock;
        model.toggleVoidTransactionForm = toggleVoidTransactionForm;
        model.voidTransaction = voidTransaction;
        model.doActivationLock = doActivationLock;
        model.doActivationUnlock = doActivationUnlock;
        model.supportsCustomerAtCardLevel = appConfig.selectedMerchantGroup().supportsCustomerAtCardLevel;
        model.canReprint = !authService.isReprintDisabled();
        model.setSearchType = setSearchType;
        model.isSearchByTransactionId = isSearchByTransactionId;
        model.isSearchByCampaign = isSearchByCampaign;
        model.hasCampaignTransactions = hasCampaignTransactions;
        model.currentCampaignDescription =  currentCampaignDescription;
        model.clearCampaign = clearCampaign;
        model.sort = sort;
        model.sortKey = "cardNumber";
        model.reverse = false;
        model.canViewRestrictedCardNumbers = authService.canViewRestrictedCardNumbers();
        model.maskPrintText = cardService.maskPrintText;
        model.voidTransactionModalPopupTrigger = voidTransactionModalPopupTrigger;
        model.changeTab = changeTab;
        model.transactionType = null;
        model.isTransferTransaction = false;
        model.getTransactionIdTranslationId = getTransactionIdTranslationId;
        model.getPurchaseTitleTranslationId = getPurchaseTitleTranslationId;
        model.isProcessingVoid = false;
        $scope.pageSize = 10;
        model.SEARCH_TYPE = {
            "TRANSACTION_ID": "transactionId",
            "CAMPAIGN": "campaign"
        };

        var TAB_NAMES = {
            PURCHASE_INFO: "purchaseInfo",
            PURCHASED_ITEMS: "purchasedItems"
        };

        $scope.TAB_NAMES = TAB_NAMES;
        var activeTab = TAB_NAMES.PURCHASE_INFO;

        $scope.tabClass = function (tabName) {
            var cssClass = "";
            if (tabName === activeTab) {
                cssClass = "active";
            }
            return cssClass;
        }

        $scope.tabVisible = function (tabName) {
            return tabName === activeTab;
        }

        init();

        function init(){

            loadCampaigns();            

            if($routeParams.id) {
                doLookupTransaction($routeParams.id);
            } else if (model.supportsCustomerAtCardLevel) {
                var campaignTransactions = transactionService.currentCampaignTransactions();
                if (campaignTransactions && campaignTransactions.transactions){
                    setCampaignTransactions(campaignTransactions.transactions);
                }
            }
        }

        function changeTab(tabName) {
            activeTab = tabName;
        }
        
        function loadCampaigns() {
            if (model.supportsCustomerAtCardLevel) {                
                notify.forHttp(transactionService.getCampaigns(),{ startTranslationId: 'LOOKUP_SALES.FORM.LOADING_CAMPAIGNS_TEXT' })
                .then(data => model.campaigns = data );
            }
        }      

        function resetLookup() {
            model.search.transactionId = null;
            model.search.campaign = null;
            notify.dismissErrorAlert();
            if ($scope.lookupTransactionForm) {
                $scope.lookupTransactionForm.$setPristine();
            }
            if ($scope.lookupTransactionCampaignForm) {
                $scope.lookupTransactionCampaignForm.$setPristine();
            }
        }

        function resetTransactions(){
            model.campaignTransactions = null;
            model.transaction = null;
        }

        function lookupByTransactionId(form){
            notify.forFormSubmission(form, doLookupTransaction(model.search.transactionId));
        }

        function lookupByCampaign(){
            notify.forFormSubmission($scope.lookupTransactionCampaignForm, doLookupTransaction(null, model.search.campaign));
        }

         function doLookupTransaction(transactionId, campaign) {
            notify.dismissErrorAlert();
            resetTransactions();

            if(transactionId){
                if (transactionService.haveTransaction(transactionId)) {
                    setTransaction(transactionService.currentTransaction());
                } else {
                    lookupTransaction(transactionId);
                }
            } else {
                lookupTransactionsByCampaign(campaign);
            }
        }

        function lookupTransaction(transactionId) {

            if (!transactionId) {
                notify.showError("LOOKUP_SALES.FORM.INVALID_TRANSACTION_ID");
                return;
            }

            notify.forHttp(transactionService.lookupTransaction(transactionId), { startTranslationId: "LOOKUP_SALES.FORM.PROGRESS_TEXT" })
                .then(function () {
                    setTransaction(transactionService.currentTransaction());
                });
        }

        function lookupTransactionsByCampaign(campaign) {

            if (!campaign) {
                notify.showError("LOOKUP_SALES.FORM.INVALID_CAMPAIGN");
                return;
            }

            notify.forHttp(transactionService.lookupByCampaign(campaign), { startTranslationId: "LOOKUP_SALES.FORM.PROGRESS_TEXT" })
                .then(function (response) {
                    setCampaignTransactions(response.transactions);
                });
        }

        function setTransaction(transaction) {
            model.transaction = transaction;
            model.hasCards = transaction.purchaseDetails.cards.length > 0;
            model.hasSundries = transaction.purchaseDetails.sundries.length > 0;
            model.hasFees = transaction.feeDetails.fees.length > 0;
            model.purchaseTotal = transaction.purchaseDetails.cardsAmount + transaction.purchaseDetails.sundriesAmount;
            model.transactionType = transaction.transactionType;
            model.isTransferTransaction = model.transactionType === "Transfer";
            resetLookup();
        }

         function setCampaignTransactions(campaignTransactions){
            model.campaignTransactions = campaignTransactions;
            resetLookup();
            navigate.toLookupSales();
        }

        function hasCampaignTransactions() {
            return model.campaignTransactions && angular.isArray(model.campaignTransactions) && model.campaignTransactions.length > 0;
        }

        function currentCampaignDescription() {
            return hasCampaignTransactions() ? model.campaignTransactions[0].campaignDescription : "";
        }

        function sort(columnKey) {
            model.sortKey = columnKey;
            model.reverse = !model.reverse;
        }

        function getReceiptHtml() {
            let deferred = $q.defer(),
                isReprint = true;
            receipts.getReceipt(model.transaction.transactionId, $translate.currentLanguage().key, isReprint).then(function(receipt) {
                deferred.resolve(receipt.receiptHtml);
            }, function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function usedNewActivation(){
            return model.transaction && model.transaction.isActivatedUsingNewActivation;
        }

        function canVoidTransaction(){
             return !model.isProcessingVoid && model.transaction && model.transaction.isVoidable && authService.canVoidTransaction();
        }

        function canActivationLock(){
             return isLockable();
        }

        function showActivationLock() {
            return !model.isTransferTransaction;
        }

        function canActivationUnlock(){
             return isUnlockable();
        }

        function isLockable() {
            return model.transaction && model.transaction.isLockable && authService.canLockCard();
        }

        function isUnlockable() {
            return model.transaction && model.transaction.isUnlockable && authService.canUnlockCard();
        }

        function voidTransaction() {
            model.isProcessingVoid = true;
            $scope.$broadcast('handleIntegratedRefunds');
        }

        $scope.$on('integratedPaymentsHandled', function(event, success){
            if(success){
                notify.forHttp(transactionService.voidTransaction(model.transaction.transactionId, model.void.note),{
                     startTranslationId: "LOOKUP_SALES.FORM.VOID_TRANSACTION_PROGRESS" })
                .then(function(){refreshTransaction();},function(){resetProcessingVoidFlag();});
            }
            else {
                resetProcessingVoidFlag();
            }
        });
        

        function refreshTransaction() {
            lookupTransaction(model.transaction.transactionId);
            model.showVoidForm = false;
            resetProcessingVoidFlag();
        }

         function toggleVoidTransactionForm() {
            model.showVoidForm = !model.showVoidForm;
            if (!model.showVoidNote) {
                resetVoidTransactionForm();
            }
        }

        function resetVoidTransactionForm(){
            model.void = {
                note: null
            };
            resetProcessingVoidFlag();
        }

        function doActivationLock(){
            notify.forHttp(transactionService.lockTransaction(model.transaction.transactionId),
                    { startTranslationId: "LOOKUP_SALES.FORM.LOCK_TRANSACTION_PROGRESS" })
                    .then(refreshTransaction);
        }

        function doActivationUnlock(){
            notify.forHttp(transactionService.unlockTransaction(model.transaction.transactionId),
                    { startTranslationId: "LOOKUP_SALES.FORM.UNLOCK_TRANSACTION_PROGRESS" })
                    .then(refreshTransaction);
        }

        var transactionSearchType = model.SEARCH_TYPE.CAMPAIGN;
        function setSearchType(searchType) {
            transactionSearchType = searchType;
            if(transactionSearchType === model.SEARCH_TYPE.TRANSACTION_ID) {
                resetTransactions();
            }
        }

        function isSearchByTransactionId() {
            return transactionSearchType && transactionSearchType === model.SEARCH_TYPE.TRANSACTION_ID;
        }

        function isSearchByCampaign() {
            return transactionSearchType && transactionSearchType === model.SEARCH_TYPE.CAMPAIGN;
        }

        function clearCampaign($event, $select){
			$event.stopPropagation(); 			
			$select.selected = undefined;			
			$select.search = undefined;						
        }

        function voidTransactionModalPopup() {
            var voidTransactionModalInstance = $uibModal.open({
                templateUrl: "lookup-sales/modal/void-dialog.html",
                backdrop: "static",
                keyboard: "false",
                controller: "ConfirmModal",
                scope: $scope,
                size: "sm"
            });

            voidTransactionModalInstance.result.then(function (status) {
                if (status) {
                    voidTransaction();
                } else {
                    resetVoidTransactionForm();
                }
            })
        }

        function voidTransactionModalPopupTrigger() {
            voidTransactionModalPopup();
        }

        function getTransactionIdTranslationId() {
            return model.isTransferTransaction ?
                'LOOKUP_SALES.TRANSACTION_PROPERTIES.TRANSFER_ID' :
                'LOOKUP_SALES.TRANSACTION_PROPERTIES.TRANSACTION_ID';
        }
        
        function getPurchaseTitleTranslationId() {
            return model.isTransferTransaction ?
                'LOOKUP_SALES.TRANSACTION_PROPERTIES.TRANSFER_TOTAL' :
                'LOOKUP_SALES.TRANSACTION_PROPERTIES.PURCHASE_TITLE';
        }

        function resetProcessingVoidFlag() {
            model.isProcessingVoid = false;
        }
    }
}());
