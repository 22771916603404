import htmlTemplate from './hvt-request.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
        .config(configHvtRequest);

    configHvtRequest.$inject = ["$routeProvider", "NAV_URLS", "CLAIMS"];

    function configHvtRequest($routeProvider, NAV_URLS, CLAIMS) {

        $routeProvider
            .when(NAV_URLS.HVT_REQUEST, {
                template: htmlTemplate,
                controller: "HvtRequest",
                controllerAs: "model"
            });
    }
}());