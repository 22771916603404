﻿(function () {
    "use strict";

    angular.module("cardspotWeb.areas.manageInventory")
        .controller("ManageInventory", ManageInventory);

    ManageInventory.$inject = ["$scope", "$timeout", "inventoryService", "notify", "NAV_URLS", "authService", "$uibModal", "api", "$translate"];

    function ManageInventory($scope, $timeout, inventoryService, notify, NAV_URLS, authService, $uibModal, api, $translate) {
        var owlApi;
        var model = this;
        
        model.canConfirmInventory = authService.canReconcileCardInventory();
        model.cardOrderDesigns = [];
        model.NAV_URLS = NAV_URLS;
        model.canOrderCards = authService.canOrderCards();
        model.canRetireOrder = canRetireOrder;
        model.isOrderReceived = isOrderReceived;
        model.retireOrderModalPopup = retireOrderModalPopup;
        model.properties = {
            responsive: {
                0: {
                    items: 1,
                },
                768: {
                    items: 1,
                },
                992: {
                    items: 2,
                },
                1200: {
                    items: 2,
                },
                1800: {
                    items: 3,
                }
            }
		};
	    model.getCardOrderStatusTranslation = getCardOrderStatusTranslation;
        model.ready = function ($api) {
            owlApi = $api;
        }

        init();

        function init() {
            getCardOrderDesigns();
        }

        function getCardOrderDesigns() {
            notify.forHttp(inventoryService.getCardOrderDesigns(), { startTranslationId: "MANAGE_CARDS.MANAGE_INVENTORY.NOTIFICATION_MESSAGES.LOADING_CARD_ORDER_DESIGNS" })
                .then(data => setCardOrderDesigns(data));
        }

		function setCardOrderDesigns(data) {
			model.cardOrderDesigns = data.cardOrderDesigns;						
		}

        function isOrderReceived(status) {
            return status === "Received";
        }

        function canRetireOrder(cardOrder) {
            var isPhysicalCard = cardOrder.distributionType === 'Standard';

            var percentSold = Math.floor((cardOrder.cardSoldCount / cardOrder.cardCount) * 100);
            var percentRemaining = 100 - percentSold;
            var lessThanFivePercentRemaining = percentRemaining < 5;
            
            var canRetireOrder = isPhysicalCard && lessThanFivePercentRemaining;

            return canRetireOrder;
        }

        function retireOrderModalPopup(cardOrder) {
            model.orderId = cardOrder.cardOrderId;

            var retireOrderModal = $uibModal.open({
                templateUrl: "manage-inventory/modal/retire-order-dialog.html",
                backdrop: "static",
                keyboard: true,
                controller: "ConfirmModal",
                scope: $scope
            });

	        retireOrderModal.result.then(function(status) {
		        model.orderId = null;

		        if (status == false) {
			        return;
		        }

		        api.inventory.retireInventoryCardOrder(cardOrder.cardOrderId).then(function(response) {
			        getCardOrderDesigns();
		        });
	        });
		}

		function getCardOrderStatusTranslation(status) {
			var translationId = "CARD_ORDER_STATUS_TYPE." + status.toUpperCase();
			var translatedValue = $translate.instant(translationId);
			//angular translate service returns the translationId if the translation isn't found. 
			//We'll default to the original status in this case
			return translatedValue === translationId ? status : translatedValue;
		}
    }
}());