import _ from 'underscore';

(function () {
    "use strict";

    angular.module("cardspotWeb.areas.transferCard")
        .controller("TransferCard", TransferCard);

    TransferCard.$inject = ["$scope", "$q", "$timeout", "$log", "notify", "cardService", "authService", "preActivationService", "CLAIMS", "SF_EVENTS", "navigate", "appConfig", "transferService", "receipts", "$translate", "NAV_URLS"];

    function TransferCard($scope, $q, $timeout, $log, notify, cardService, authService, preActivationService, CLAIMS, SF_EVENTS, navigate, appConfig, transferService, receipts, $translate, NAV_URLS) {
        var model = this;               
        var transferToCard = null;                                  
        
        model.NAV_URLS = NAV_URLS;
        model.preActivateCardByPrintText = preActivateCardByPrintText;
        model.transferToCard = {
                printText: null
        };
        model.transferCard = transferCard;
        model.cancelTransfer = cancelTransfer;
        model.toggleFeeEdit = toggleFeeEdit;
        model.canModifyFee = authService.canModifyTransferFee();
        model.canDeleteFee = authService.canDeleteFee();
        model.hasFee = hasFee;
        model.getReceiptHtml =  getReceiptHtml;
        model.validatePaymentType = validatePaymentType;
        model.removeTransferFee = removeTransferFee;
        model.haveSufficientFunds = haveSufficientFunds;
        model.sendEmailReceipt = sendEmailReceipt;
        model.showEmailReceipt = showEmailReceipt;
        model.cancelEmailReceipt = cancelEmailReceipt;
        model.canActivateWithoutSwipe = authService.hasClaim(CLAIMS.ACTIVATE_WITHOUT_SWIPE);
        model.canViewRestrictedCardNumbers = authService.canViewRestrictedCardNumbers();
        model.maskPrintText = cardService.maskPrintText;
        model.isProcessing=false
        
        init();               
        
        $scope.$on(SF_EVENTS.CARDSWIPE_SUCCESS, handleCardSwipeSuccess);
        
        function init() {
            model.fromCard = cardService.getTransferCard();                     
            if(!model.fromCard){
               return navigate.toLookupCard();
            }                       
            getPaymentTypes(model.fromCard.programUniqueTag);            
        } 
                
        function removeTransferFee() {
            model.transferFromCard.fee = 0;
        }

        function hasFee() {
            return model.transferFromCard && model.transferFromCard.fee && model.transferFromCard.fee > 0;
        }

        function getPaymentTypes(programUniqueTag) {
            preActivationService.ensureMerchantGroupProgramInformation(programUniqueTag)
                .then(function(data) {
                    model.paymentTypes = data.paymentTypes;
                    refreshData();
                });
        }  
        
        function refreshData(){
            var fillteredPaymentTypes =  _.filter(model.paymentTypes,function(pt){return !pt.isAuthorizable;});
             model.transferFromCard = {
                cardNumber: model.fromCard.printText,
                toCard: null,
                balance: model.fromCard.availableBalance,
                fee: model.fromCard.transferFeeAmount,
                paymentTypes: fillteredPaymentTypes,                                
                amountDue: model.fromCard.transferFeeAmount
            };
        }                            

       function doPreActivateCard(printText, primaryAccountNumber) {
            notify.dismissErrorAlert();

            var preActivateCardFunction;
            if (printText) {
                preActivateCardFunction = preActivationService.preActivateByPrintText(printText, true, true);
            } else if (primaryAccountNumber) {
                preActivateCardFunction = preActivationService.preActivateBySwipe(primaryAccountNumber, true, true);
            } else {
                notify.showError("LOOKUP_CARD.FORM.INVALID_PRINT_TEXT_OR_SWIPE");
                return;
            }

            notify.forHttp(preActivateCardFunction, { startTranslationId: "LOOKUP_CARD.FORM.PROGRESS_TEXT" })
                .then(function (data) {
                    validatePreActivation();
                    setTransferToCard(data.card);                    
                });
        }
        
       function setTransferToCard(card) {
           if (card) {
                transferToCard = card;
                model.transferToCard.printText = transferToCard.printText;
           }
        }

        function preActivateCardByPrintText() {
            if(model.transferToCard.printText){
                 doPreActivateCard(model.transferToCard.printText);
            }
        }

        function handleCardSwipeSuccess(event, swipe) {
            doPreActivateCard(undefined, swipe.primaryAccountNumber);
        }

        function validatePreActivation() {
            if (preActivationService.hasErrors()) {
                notify.showError(preActivationService.errors().join(", "));
            }
        }                       
        
        function transferCard(){
             model.isProcessing=true;
            var transferModel = {
                fromCardNumber:  model.fromCard.cardNumber,
                fromCardPrintText: model.fromCard.printText,
                toCardNumber: transferToCard.primaryAccountNumber,
                toCardPrintText: transferToCard.printText,
                paymentType: model.transferToCard.paymentType,
                transferFeeAmount: model.transferFromCard.fee,
                note: model.transferFromCard.note,
                activationLock: model.transferToCard.activationLock                
            };
            
            //if the payment type is gift card and the fee is greater than the balance, put the brakes on the process
            if (haveSufficientFunds()) {
                notify.forFormSubmission($scope.transferCardForm, function() {
                    notify.forHttp(transferService.transferCard(transferModel), { startTranslationId: "TRANSFER_CARD.TRANSFER_CARD_PROGRESS" })
                        .then(function(data) {                            
                            model.transferCompleted = true;
                            model.isProcessing=false;
                            model.transferSystemTransactionId = data.transferSystemTransactionId;
                            model.transferCompleteBalance = calculateTransferCompleteBalance();
                        });
                });                 
            } else {
                model.isProcessing=false;
                notify.showError("TRANSFER_CARD.FORM.VALIDATION_MESSAGES.INSUFFICIENT_FUNDS");
            }
        }

        function haveSufficientFunds() {
            var transferCompleteBalance = calculateTransferCompleteBalance();
            return transferCompleteBalance >= 0;
        }
        
        function calculateTransferCompleteBalance() {
            if (!model.transferFromCard) {
                return 0;
            }
            //the "to" card balance should really come back from the server on the transfer call so this logic isn't duplicated in the 
            //client. This would require some changes to Tantalus.Processes.Server.TransferCard so it 
            //returns the newly calculated balance of the "to" card and not just the transfer system transaction ID
            if (hasFee() && isGiftCardPaymentType()) {
                return model.transferFromCard.balance - model.transferFromCard.fee;
            }
            return model.transferFromCard.balance;
        }

        function isGiftCardPaymentType() {
            return model.transferToCard && model.transferToCard.paymentType === "GiftCardAccount";
        }

        function toggleFeeEdit(){
           model.showFeeEditInput = !model.showFeeEditInput; 
        } 
        
        function cancelTransfer(){
            navigate.toLookupCard();
        }  
        
        function getReceiptHtml(){
            var deferred = $q.defer();
            receipts.getReceipt(model.transferSystemTransactionId, $translate.currentLanguage().key ).then(function(receipt) {
                deferred.resolve(receipt.receiptHtml);
            }, function(error) {
                deferred.reject(error);
            });

            return deferred.promise;            
        }  
        
        function validatePaymentType(){
            if(model.transferFromCard && model.transferFromCard.fee <= 0){
                model.transferToCard.paymentType = null;
            }
        } 
        
        function sendEmailReceipt() {
             
            notify.forFormSubmission($scope.emailReceiptForm, function() {
                notify.forHttp(receipts.sendEmailReceipt(model.transferSystemTransactionId, model.email, $translate.currentLanguage().key),
                    {
                        startTranslationId: "ENTER_SALES.SALE_SUMMARY.SENDING_RECEIPT_EMAIL_PROGRESS_TEXT",
                        endTranslationId: "ENTER_SALES.SALE_SUMMARY.SENT_RECEIPT_EMAIL_CONFIRMATION"                    
                    })
                    .then(function() {
                        cancelEmailReceipt();
                    });
            });
        }  
        
        function cancelEmailReceipt() {
            model.email = undefined;
            model.emailReceiptVisible = false;
        }  
        
        function showEmailReceipt() {
            model.emailReceiptVisible = true;
        }                 
    }
}());