import htmlTemplate from './settings.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
        .config(configSettings);

    configSettings.$inject = ["$routeProvider", "NAV_URLS", "CLAIMS"];

    function configSettings($routeProvider, NAV_URLS, CLAIMS) {

        $routeProvider
            .when(NAV_URLS.MANAGE_SETTINGS, {
                template: htmlTemplate,
                controller: "Settings",
                controllerAs: "model",
                requiredClaim: CLAIMS.MANAGE_SETTINGS
            });
    }

}());
