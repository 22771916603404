import htmlTemplate from './sundry-sales.directive.html';
import _ from 'underscore';

(function () {
    "use strict";

    angular.module("cardspotWeb.areas.enterSundries")
        .directive("emlSundrySales", configDirective);

    function configDirective() {
        return {
            restrict: "E",
            scope: {
                sundryTypes: "=",
                sundrySalesComplete: "=",
                editCards: "=",
                hasSundryTypes: "=",
                isSundryPath: "=",
                isTrailingSymbol: "=",
                currencySeparators: "=",
                fractionSize: "="
            },
            controller: SundrySalesController,
            controllerAs: "model",
            template: htmlTemplate
        };
    }

    SundrySalesController.$inject = ["$scope", "sundryService", "notify", "appConfig", "$uibModal"];

    function SundrySalesController($scope, sundryService, notify, appConfig, $uibModal) {
        var model = this;               
        
        model.filteredSundryTypes = [];
        model.sundries = sundryService.getSundries();                       
        model.clearSundriesConfirmationVisible = clearSundriesConfirmationVisible;
        model.removeSundryConfirmationVisible = removeSundryConfirmationVisible;
        model.cancelRemoveSundry = cancelRemoveSundry;
        model.removeSundry = removeSundry;
        model.isSundryToBeRemoved = isSundryToBeRemoved;
        model.clearSundries = clearSundries;
        model.cancelClearSundries = cancelClearSundries;
        model.addSundry = addSundry;
        model.subTotal = subTotal;
        model.amountTotal = amountTotal;
        model.removeSundryConfirmModalPopup = removeSundryConfirmModalPopup;
        model.removeSundryConfirmModalPopupTrigger = removeSundryConfirmModalPopupTrigger;
        model.removeAllConfirmModalPopup = removeAllConfirmModalPopup;
        model.removeAllConfirmModalPopupTrigger = removeAllConfirmModalPopupTrigger;
        
        init();

        function init() {  
            if($scope.hasSundryTypes()){                                
              getSundryTypes();           
            }                 
        }
        
        function reset() {  
            model.sundry.quantity = null;
            model.sundry.sundryType = null;                      
            $scope.addSundriesForm.$setPristine();                                          
        }
        
        function updateModel() {
            model.haveSundries = model.sundries.length > 0;
            updateSundryTypesList();
        }
        
        function updateSundryTypesList(){
            // filter the already added sundry types from the drop down
            var addedSundryItemTypeIds = _.map(model.sundries,function(sundry){
                return sundry.sundryItemTypeId;
            });
            angular.copy(_.reject($scope.sundryTypes, function(sundryType){
                return _.contains(addedSundryItemTypeIds,sundryType.id);
            }),model.filteredSundryTypes);     
        }

        updateModel();
        
        $scope.$on("showing-sundry-sales", function() {
            init();
            updateModel();
        });
        
        function removeSundryConfirmationVisible(isVisible) {
            model.showRemoveSundryConfirmation = isVisible;
            if (isVisible) {
                clearSundriesConfirmationVisible(false);
            }
        }

        function clearSundriesConfirmationVisible(isVisible) {
            model.showClearSundriesConfirmation = isVisible;
            if (isVisible) {
                removeSundryConfirmationVisible(false);
            }
        }
        
        function clearSundries(confirmed) {
            if (confirmed) {
                sundryService.reset();
                updateModel();
                clearSundriesConfirmationVisible(false);
                notify.success("ENTER_SUNDRIES.CONFIRMATION.ALL_SUNDRIES_REMOVED", undefined, 1000);
            }
        }
        
        function cancelClearSundries() {
            clearSundriesConfirmationVisible(false);
        }
        
        function removeSundry(sundry, confirmed) {
            if (confirmed) {
                sundryService.removeSundry(sundry.sundryItemTypeId);                                
                model.sundryToRemove = undefined;
                removeSundryConfirmationVisible(false);
                updateModel();
                notify.success("ENTER_SUNDRIES.CONFIRMATION.SUNDRY_REMOVED", undefined, 1000);
            }
        }
        
        function cancelRemoveSundry() {
            removeSundryConfirmationVisible(false);
            model.sundryToRemove = undefined;
        }
        
        function isSundryToBeRemoved(sundry) {
            return sundry && model.sundryToRemove && sundry.sundryItemTypeId === model.sundryToRemove.sundryItemTypeId;
        }
        
        function addSundry(){
            
            var selectedSundry = _.find($scope.sundryTypes, function (sundryType) {
                return sundryType.id === model.sundry.sundryType;
            });
                     
            var sundry = {
                sundryItemTypeId: selectedSundry.id,
                unitPrice: selectedSundry.currentUnitPrice,
                quantity: model.sundry.quantity,
                description: selectedSundry.description,
                currency: selectedSundry.currency
            };
            
            sundryService.addSundry(sundry);
            
            updateModel(); 
            reset();                   
        }
        
        function subTotal(sundry){
            sundry.subTotal = sundry.unitPrice * sundry.quantity;
            return sundry.subTotal;
        }
        
        function amountTotal() {
            if (!model.sundries) {
                return 0;
            }
            return _.reduce(model.sundries, function (memo, sundry) {                
                return memo + sundry.subTotal;
            }, 0);
        }
        
        function getSundryTypes() {
            $scope.sundryTypes = appConfig.selectedMerchantGroup().sundryItemTypes;            
            updateSundryTypesList();
            model.currencySymbol = $scope.hasSundryTypes() ? $scope.sundryTypes[0].currency.currencySymbol : "";               
        }

        function removeSundryConfirmModalPopup(sundry) {
            var removeSundryModalInstance = $uibModal.open({
                templateUrl: "enter-sundries/modal/removeSundryConfirmDialog.html",
                backdrop: "static",
                keyboard: false,
                size: "sm",
                controller: "ConfirmModal",
                scope: $scope
            })

            removeSundryModalInstance.result.then(function (status) {
                removeSundry(sundry, status);
            });
        }

        function removeSundryConfirmModalPopupTrigger(sundry) {
            model.removeSundryConfirmModalPopup(sundry);
        }

        function removeAllConfirmModalPopup() {
            var removeAllConfirmModalInstance = $uibModal.open({
                templateUrl: "enter-sundries/modal/removeAllConfirmDialog.html",
                backdrop: "static",
                keyboard: false,
                size: "sm",
                controller: "ConfirmModal",
                scope: $scope
            })

            removeAllConfirmModalInstance.result.then(function (status) {
                clearSundries(status);
            });
        }

        function removeAllConfirmModalPopupTrigger() {
            model.removeAllConfirmModalPopup();
        }
    }
}());