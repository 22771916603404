﻿import htmlTemplate from './diagnostic.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
    .config(configDiagnostic);

    configDiagnostic.$inject = ["$routeProvider", "NAV_URLS"];
    function configDiagnostic($routeProvider, NAV_URLS) {

        $routeProvider
        .when(NAV_URLS.DIAGNOSTIC, {
            template: htmlTemplate,
            controller: "Diagnostic",
            controllerAs: "model"
        });
    }
}());