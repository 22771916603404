﻿import htmlTemplate from './payments.directive.html';
import _ from 'underscore';

(function () {
	"use strict";

	angular.module("cardspotWeb.areas.enterSales")
		.directive("emlPayments", configDirective);

	function configDirective() {
		return {
			restrict: "E",
			scope: {
				payments: "=",
				currencySymbol: "=",
				paymentTypes: "=",
				paymentsChangedEvent: "=",
				havePayments: "=",
				paymentToRemove: "=",
				remainingBalance: "=",
				isTrailingSymbol: "=",
				currencySeparators: "=",
				fractionSize: "=",
				currentLanguage: "="
			},
			controller: PaymentsController,
			controllerAs: "model",
			template: htmlTemplate
		};
	}

	PaymentsController.$inject = ["$scope", "notify", "$uibModal", "EVENT_NAMES", "$rootScope"];

	function PaymentsController($scope, notify, $uibModal, EVENT_NAMES, $rootScope) {
		var model = this;
		model.payment = {
			amount: undefined,
			paymentType: undefined
		};
		model.tryAddPayment = tryAddPayment;
		model.cancelAddPayment = cancelAddPayment;
		model.removePayment = removePayment;
		model.showingEditForPayment = showingEditForPayment;
		model.editingPayment = editingPayment;
		model.tryApplyPaymentChange = tryApplyPaymentChange;
		model.cancelEditPayment = cancelEditPayment;
		model.paymentTypeSelectionChanged = paymentTypeSelectionChanged;
		model.excessivePaymentConfirmModalPopup = excessivePaymentConfirmModalPopup;
		model.currentLanguage = $scope.currentLanguage;
		model.currentlyRemovingPayment = undefined;
		model.paymentTypes = $scope.paymentTypes;

		$scope.$on(EVENT_NAMES.ON_PAYMENT_TYPE_ARRAY_CHANGED, function () {
			model.payment.paymentType = $rootScope.selectedPaymentType;
			model.payment.amount = Number($rootScope.remainingBalance.toFixed(2));
		})

		function tryApplyPaymentChange(payment) {
			if (paymentAmountExcessive(model.paymentEdited.amount - model.paymentEditedMaster.amount)) {
				model.doPaymentOperation = applyPaymentChange;
				model.cancelPaymentOperation = function () { cancelEditPayment(payment); };
				excessivePaymentConfirmModalPopupTrigger(payment);
			} else {
				applyPaymentChange();
			}
		}

		function applyPaymentChange() {
			notify.forFormSubmission($scope.editPaymentForm, function () {
				resetEditPayment();
				handlePaymentChange();
				hideExcessPaymentConfirmation();
			});
		}

		function cancelEditPayment(payment) {
			angular.copy(model.paymentEditedMaster, payment);
			resetEditPayment();
			hideExcessPaymentConfirmation();
		}

		function resetEditPayment() {
			$scope.editPaymentForm.$setPristine();
			model.paymentEditedMaster = undefined;
			model.paymentEdited = undefined;
			resetPaymentOperationFunctions();
		}

		function showingEditForPayment(payment) {
			return model.paymentEdited
				&& model.paymentEdited.$$hashKey === payment.$$hashKey;
		}

		function editingPayment(payment) {
			model.paymentEditedMaster = angular.copy(payment);
			model.paymentEdited = payment;
		}

		function handlePaymentChange() {
			$scope.$emit($scope.paymentsChangedEvent, {});
		}

		function removePayment(paymentToRemove) {

			if (paymentToRemove.externalAuthorizationTransactionId) {
				var removeConfirmModalInstance = $uibModal.open({
					templateUrl: "enter-sales/payment/modal/removePaymentConfirmDialog.html",
					backdrop: "static",
					keyboard: false,
					size: "sm",
					controller: "ConfirmModal",
					scope: $scope
				});

				removeConfirmModalInstance.result.then(function (confirmed) {
					if (confirmed) {
						$scope.paymentToRemove = paymentToRemove;
						$scope.$parent.$broadcast('handleIntegratedReversal', paymentToRemove);
					}
				});
			}
			else
				completeRemovePayment(paymentToRemove);
		}

		$scope.$on('completeRemovePayment', function(event) {
			completeRemovePayment($scope.paymentToRemove);
			$scope.paymentToRemove = undefined;
		});

		function completeRemovePayment(paymentToRemove){
			angular.copy(_.reject($scope.payments, function (payment) {
				return payment.$$hashKey === paymentToRemove.$$hashKey;
			}), $scope.payments);

			handlePaymentChange();
		}

		function tryAddPayment() {
			model.payment.amount = parseFloat(model.payment.amount);

			if (paymentAmountExcessive(model.payment.amount)) {
				model.doPaymentOperation = addPayment;
				model.cancelPaymentOperation = cancelAddPayment;
				excessivePaymentConfirmModalPopupTrigger();
			} else {
				addPayment();
			}
		}

		function resetPaymentOperationFunctions() {
			model.doPaymentOperation = undefined;
			model.cancelPaymentOperation = undefined;

		}

		function paymentAmountExcessive(newAmount) {
			return newAmount && (newAmount > 0) && (Number($scope.remainingBalance.toFixed(2)) >= 0) && (newAmount > Number($scope.remainingBalance.toFixed(2)));
		}

		function showExcessPaymentConfirmation() {
			model.showExcessPaymentConfirmation = true;
		}

		function hideExcessPaymentConfirmation() {
			model.showExcessPaymentConfirmation = false;
		}

		function addPayment() {
			notify.forFormSubmission($scope.addPaymentForm, function () {
				$scope.payments.push(angular.copy(model.payment));
				resetPaymentForm();
				notify.dismissErrorAlert();
				handlePaymentChange();
				hideExcessPaymentConfirmation();
			});
		}

		function cancelAddPayment() {
			hideExcessPaymentConfirmation();
			resetPaymentForm();
		}

		function resetPaymentForm() {
			model.payment.amount = undefined;
			model.payment.paymentType = undefined;
			$scope.addPaymentForm.$setPristine();
			$scope.addPaymentForm.$setUntouched();
			resetPaymentOperationFunctions();
		}

		function paymentTypeSelectionChanged() {
			model.payment.amount = Number($scope.remainingBalance.toFixed(2));
		}

		function excessivePaymentConfirmModalPopup(payment) {
			var excessivePaymentModalInstance = $uibModal.open({
				templateUrl: "enter-sales/payment/modal/excessivePaymentConfirmDialog.html",
				backdrop: "static",
				keyboard: false,
				size: "sm",
				controller: "ConfirmModal",
				scope: $scope
			})

			excessivePaymentModalInstance.result.then(function (status) {
				if (status) {
					if (payment) {
						applyPaymentChange();
					} else {
						addPayment();
					}
				}
			});
		}

		function excessivePaymentConfirmModalPopupTrigger(payment) {
			model.excessivePaymentConfirmModalPopup(payment);
		}
	}
}());
