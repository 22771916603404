﻿import htmlTemplate from './contact.directive.html';

(function () {
    "use strict";

    angular.module("cardspotWeb.areas.global")
        .directive("emlContact", configDirective);

    function configDirective() {
        return {
            restrict: "E",
            scope: true,
            controller: ContactController,
            controllerAs: "model",
            template: htmlTemplate
        };
    }

    ContactController.$inject = ["$scope", "CONTACT_INFO", "$rootScope", "diagnosticService", "authService", "$translate"];

    function ContactController($scope, CONTACT_INFO, $rootScope, diagnosticService, authService, $translate) {
        var model = this;
        var regex = new RegExp(/\./g);
        var newLine = "%0D%0A"

        model.CONTACT_INFO = CONTACT_INFO;
        model.formattedCAPhone = CONTACT_INFO.CANADA.PHONE.replace(regex, "");
        model.formattedUSPhone = CONTACT_INFO.UNITED_STATES.PHONE.replace(regex, "");
        model.diagnosticEmailBody = setDiagnosticEmailBody();
        model.usMailToLink = setUSMailToLink();
        model.caMailToLink = setCAMailToLink();
        model.europeMailToLink = setEuropeMailToLink();
        model.germanyMailToLink = setGermanyMailToLink();

        function setDiagnosticEmailBody() {
            var lastMessage = diagnosticService.getDiagnosticData() ? diagnosticService.getDiagnosticData().lastMessage : "";
            return "Error Message: " + lastMessage + newLine;
                   + "Error Date: " + diagnosticService.getDiagnosticData().dateTime + newLine
                   + "Username: " + authService.currentUsername() + newLine
                   + "Device: " + diagnosticService.getClientDeviceInfo().vendor + " " + diagnosticService.getClientDeviceInfo.model + newLine
                   + "OS: " + diagnosticService.getClientOSInfo().name + " " + diagnosticService.getClientOSInfo().version + newLine
                   + "Browser: " + diagnosticService.getClientBrowserInfo().name + " " + diagnosticService.getClientBrowserInfo().version + newLine
                   + "Engine: " + diagnosticService.getClientEngineInfo().name + " " + diagnosticService.getClientEngineInfo().version + newLine
                   + "Selected Language: " + $translate.instant($scope.currentLanguage().name);
        }

        function setUSMailToLink() {
            if ($rootScope.isDiagnosticPage()) {
                return CONTACT_INFO.UNITED_STATES.EMAIL + "?subject=Diagnostic Results" + "&body=" + model.diagnosticEmailBody;
            } else {
                return CONTACT_INFO.UNITED_STATES.EMAIL;
            }
        }

        function setCAMailToLink() {
            if ($rootScope.isDiagnosticPage()) {
                return CONTACT_INFO.CANADA.EMAIL + "?subject=Diagnostic Results" + "&body=" + model.diagnosticEmailBody;
            } else {
                return CONTACT_INFO.CANADA.EMAIL;
            }
        }

        function setEuropeMailToLink() {
            if ($rootScope.isDiagnosticPage()) {
                return CONTACT_INFO.EUROPE.EMAIL + "?subject=Diagnostic Results" + "&body=" + model.diagnosticEmailBody;
            } else {
                return CONTACT_INFO.EUROPE.EMAIL;
            }
        }

        function setGermanyMailToLink() {
            if ($rootScope.isDiagnosticPage()) {
                return CONTACT_INFO.GERMANY.EMAIL + "?subject=Diagnostic Results" + "&body=" + model.diagnosticEmailBody;
            } else {
                return CONTACT_INFO.GERMANY.EMAIL;
            }
        }
    }
}());