﻿import htmlTemplate from './confirm-signage.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
        .config(configConfirmSignage);

    configConfirmSignage.$inject = ["$routeProvider", "NAV_URLS"];

    function configConfirmSignage($routeProvider, NAV_URLS) {

        $routeProvider
            .when(NAV_URLS.CONFIRM_SIGNAGE, {
                template: htmlTemplate,
                controller: "ConfirmSignage",
                controllerAs: "model"
            });
    }


}());