﻿(function() {
	"use strict";

	angular.module("angular.emlpayments")
		.factory("campaignService", campaignServiceFactory);

	campaignServiceFactory.$inject = ["api", "appConfig", "preActivationService"];

	function campaignServiceFactory(api, appConfig, preActivationService) {
		return {
			getAllMerchantGroupCampaigns,
			getActiveCampaigns,
			getInactiveCampaigns,
			addMerchantGroupCampaign,
			updateMerchantGroupCampaign
		};

		function getAllMerchantGroupCampaigns() {
			var promise = api.campaign.getAllMerchantGroupCampaigns(appConfig.selectedMerchantGroup().uniqueTag);
			return promise;
		}

		function getActiveCampaigns(pageNumber = 1, pageSize = 10, sortAscending = true, isActive = true) {
			var campaignPromise = api.campaign.getMerchantGroupCampaigns(appConfig.selectedMerchantGroup().uniqueTag, pageNumber, pageSize, sortAscending, isActive);
			return campaignPromise;
		}

		function getInactiveCampaigns(pageNumber = 1, pageSize = 10, sortAscending = true, isActive = false) {
			var campaignPromise = api.campaign.getMerchantGroupCampaigns(appConfig.selectedMerchantGroup().uniqueTag, pageNumber, pageSize, sortAscending, isActive);
			return campaignPromise;
		}

		function addMerchantGroupCampaign(description) {
			var merchantGroupUniqueTag = appConfig.selectedMerchantGroup().uniqueTag;
			
			var promise = api.campaign.addMerchantGroupCampaign(merchantGroupUniqueTag, description);
			return promise;
		}

		function updateMerchantGroupCampaign(rowData) {
			var model = {
				merchantGroupCampaignId: rowData.merchantGroupCampaignId,
				description: rowData.description,
				isActive: rowData.isActive
			};

			var promise = api.campaign.updateMerchantGroupCampaign(model);
			return promise;
		}
	}
}());