import _ from 'underscore';

(function () {
    angular.module("cardspotWeb.areas.applicationConfiguration")
        .controller("ApplicationConfiguration", ApplicationConfiguration);

    ApplicationConfiguration.$inject = ["$scope", "$timeout", "$log", "notify", "appConfig", "preActivationService", "api", "authService", "sundryService", "settings", "$window", "SF_EVENTS", "$rootScope"];

    function ApplicationConfiguration($scope, $timeout, $log, notify, appConfig, preActivationService, api, authService, sundryService, settings, $window, SF_EVENTS, $rootScope) {
        var model = this;
        model.configurationInitialized = false;
        model.selectedMerchantGroup = undefined;
        model.isSelectedMerchantGroup = isSelectedMerchantGroup;
        model.cancelLocationEdit = cancelLocationEdit;
        model.merchantGroupLocations = undefined;
        model.selectLocation = selectLocation;
        model.isSelectedLocation = isSelectedLocation;
        model.cancelAddLocation = cancelAddLocation;
        model.showAddLocation = showAddLocation;
        model.saveNewLocation = saveNewLocation;
        model.canAddLocation = authService.canAddSoftwareClientInstance();
        model.manageLocations = false;

        appConfig.init(function () {
            updateModelProperties();
            model.configurationInitialized = true;
        });

        model.selectMerchantGroup = function(merchantGroup) {
            appConfig.setSelectedMerchantGroup(merchantGroup);
            appConfig.refreshSelectedMerchantGroup(merchantGroup, handleMerchantGroupChange);
            appConfig.removeLocationFromLocalStorage();
            model.location = undefined;
            updateModelProperties();
            preActivationService.reset(); //if user changes merchant groups, need to reset any preactivation cards or merchant group info
            sundryService.reset(); 
            notify.success("APPLICATION_CONFIGURATION.MERCHANT_GROUP_CHANGED", undefined, 750);
        };

        function updateModelProperties() {
            model.hasMerchantGroups = appConfig.hasMerchantGroups();
            model.merchantGroups = appConfig.getMerchantGroups(),
            model.hasSelectedMerchantGroup = appConfig.hasSelectedMerchantGroup();
            model.selectedMerchantGroup = appConfig.selectedMerchantGroup();

            if (model.hasSelectedMerchantGroup) {
                appConfig.getMerchantGroupLocations(model.selectedMerchantGroup.uniqueTag).then(function(locations) {
                    model.merchantGroupLocations = locations;
                    var configLocation = appConfig.getSelectedLocation();
                    if (configLocation && !_.find(model.merchantGroupLocations, function(location) {
                        return location.location === configLocation.location;
                    })) {
                        appConfig.resetLocation();
                        model.location = undefined;
                    } else {
                        model.location = appConfig.getSelectedLocation();
                    }
                    getSettings();
                });
            }                                   
        }

        function selectLocation(location) {
            model.location = location;
            appConfig.setSelectedLocation(model.location);
            notify.success("APPLICATION_CONFIGURATION.LOCATION_UPDATED", undefined, 750);
        }

        function isSelectedLocation(location) {
            return location && model.location && location.location === model.location.location;
        }

        function isSelectedMerchantGroup(merchantGroup) {
            return merchantGroup && model.selectedMerchantGroup && merchantGroup.uniqueTag === model.selectedMerchantGroup.uniqueTag;
        }

        function cancelLocationEdit() {
            model.location = appConfig.getSelectedLocation().location;
            $scope.locationForm.$setPristine();
        }

        function saveNewLocation() {
            if (model.canAddLocation) {
                notify.forFormSubmission($scope.addLocationForm, function() {
                    notify.forHttp(api.configuration.addMerchantGroupLocation(model.selectedMerchantGroup.uniqueTag, model.newLocationName), {
                        startTranslationId: "APPLICATION_CONFIGURATION.SAVING_LOCATION_PROGRESS_MESSAGE"
                    }).then(function() {
                        var newLocation = {
                            location: model.newLocationName
                        };
                        model.merchantGroupLocations.unshift(newLocation);
                        selectLocation(newLocation);
                        cancelAddLocation();
                    });
                });
            }
        }

        function showAddLocation() {
            model.addLocationFormVisible = true;
        }

        function cancelAddLocation() {
            model.addLocationFormVisible = false;
            $scope.addLocationForm.$setPristine();
            model.newLocationName = undefined;
        }

        function getSettings() {
            notify
                .forHttp(settings.getSettings(), {
                    startTranslationId: "SETTINGS.GET_SETTINGS_PROGRESS"
                })
                .then(data => assignManageLocations(data));
        }

        function assignManageLocations(data) {
            model.manageLocations = data.manageLocations;
        };

        function handleMerchantGroupChange() {
            $rootScope.$emit(SF_EVENTS.MERCHANT_GROUP_CHANGED);
        }
    }
}());