import htmlTemplate from './integrated-payment.modal.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
        .config(configIntegratedPayment);

        configIntegratedPayment.$inject = ["$routeProvider", "NAV_URLS"];

    function configIntegratedPayment($routeProvider, NAV_URLS) {

        $routeProvider
            .when(NAV_URLS.INTEGRATED_PAYMENT, {
                template: htmlTemplate,
                controller: "IntegratedPaymentModal",
                controllerAs: "model"
            });
    }
}());