(function() {
    "use strict";

    angular.module("angular.emlpayments")
        .directive("emlTogglePanelContents", togglePanelContents);

    togglePanelContents.$inject = ["EVENT_NAMES"];

    function togglePanelContents(EVENT_NAMES) {
        return function (scope, element, attrs) {
           element.on("click", function(){
               $(this).siblings(".collapse").toggleClass("in");
               $(this).find(".glyphicon").toggleClass("glyphicon-triangle-bottom glyphicon-triangle-top");               
           });

           scope.$on(EVENT_NAMES.PANEL_COLLAPSE, () => {               
                $(element).siblings(".collapse").removeClass("in");
                const glyphs = $(element).find(".glyphicon");
                glyphs.addClass("glyphicon-triangle-bottom");
                glyphs.removeClass("glyphicon-triangle-top");   
           });

           scope.$on(EVENT_NAMES.PANEL_EXPAND, () => {               
                $(element).siblings(".collapse").addClass("in");
                const glyphs = $(element).find(".glyphicon");
                glyphs.addClass("glyphicon-triangle-top"); 
                glyphs.removeClass("glyphicon-triangle-bottom");   
           });
        };
    }
}());