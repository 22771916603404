(function () {
    "use strict";

    angular.module("cardspotWeb.areas.lookupInvoices")
        .controller("LookupInvoices", LookupInvoices);

    LookupInvoices.$inject = ["$scope", "$q", "notify", "$routeParams", "invoiceService", "api", "navigate", "$translate", "FileSaver", "Blob", "$uibModal", "moment"];

    function LookupInvoices ($scope, $q, notify, $routeParams, invoiceService, api, navigate, $translate, FileSaver, Blob, $uibModal, moment) {
        var model = this;
        
        model.search = {
            companyName: null,
            fromDate: null,
            toDate: null,
            invoiceNumber: null
        };

        model.sort = sort;
        model.sortKey = "invoiceNumber";
        model.reverse = true;
        $scope.currentPage = 1;
        $scope.pageSize = 10;

        model.invoices = null;
        model.searchInvoices = searchInvoices;
        model.isValidSearchCriteria = isValidSearchCriteria;
        model.getInvoice = getInvoice;
        model.reset = reset;
        model.totalInvoiceItems = 0;
        model.invoicePageChanged = invoicePageChanged;
        model.emailInvoiceModalPopupTrigger = emailInvoiceModalPopupTrigger;
        model.currentEmail = null;
        model.isValidEmail = isValidEmail;
        
        $scope.dateFormat = $scope.currentDateFormat();

        $scope.beginDatePopup = {
			opened: false
		};

		$scope.openBeginDatePicker = function () {
			$scope.beginDatePopup.opened = true;
		};

		$scope.endDatePopup = {
			opened: false
		};

		$scope.openEndDatePicker = function () {
			$scope.beginDatePopup.opened = false;
			$scope.endDatePopup.opened = true;
		};

        function formatSearchDate(dateToFormat) {
			return dateToFormat ? moment(dateToFormat).format("YYYY-MM-DD") : null;
		}

        function searchInvoices() {
            model.invoices = null;

            let fromDate = formatSearchDate(model.search.fromDate);
            let toDate = formatSearchDate(model.search.toDate);

            var searchInvoiceModel = {
                pageNumber : $scope.currentPage,
                pageSize : $scope.pageSize,
                sortAscending : model.reverse,
                companyName : model.search.companyName,
                invoiceNumber : model.search.invoiceNumber,
                fromDate : fromDate,
                toDate : toDate
            }

            notify.forFormSubmission($scope.searchInvoiceForm, doSearchInvoices(searchInvoiceModel));
        }

        function reset()
        {
            model.search.companyName = null;
            model.search.invoiceNumber = null;
            model.search.fromDate = null;
            model.search.toDate = null;
            model.invoices = null;
            $scope.currentPage = 1;
            $scope.pageSize = 10;
        }

        function isValidSearchCriteria(){
            return (model.search.companyName || model.search.invoiceNumber || (model.search.fromDate 
                && model.search.toDate));
        }

        function isValidEmail() {
            if (model.currentEmail)
                return true;
            
            return false;
        }

        function doSearchInvoices(searchInvoiceModel) {
            notify.dismissErrorAlert();                      
            if (!isValidSearchCriteria()) {
                notify.showError("LOOKUP_INVOICES.FORM.INVALID_SEARCH_CRITERIA");
                return;
            }           

            notify.forHttp(invoiceService.getCompanyInvoices(searchInvoiceModel), { startTranslationId: "LOOKUP_INVOICES.FORM.PROGRESS_TEXT" })
                .then(function (response) {
                    setSearchResults(response);
                });
        }

        function setSearchResults(response){
            if(!response && response.items < 1){
                notify.showError("LOOKUP_INVOICES.NO_RESULTS_FOUND");               
            } else {
                model.totalInvoiceItems = response.totalItems
                model.invoices = response.items;
            }
        }

        function sort(columnKey) {
            model.sortKey = columnKey; 
            model.reverse = !model.reverse;
       }

       function invoicePageChanged(newPageNumber) {
           if (newPageNumber) {
            $scope.currentPage = newPageNumber;
            searchInvoices();
           }
       }

       function getInvoice(invoiceNumber) { 
            notify.forHttp(api.activation.getInvoice(invoiceNumber), {
                startTranslationId: "ENTER_SALES.SALE_COMPLETE.GETTING_INVOICE_MESSAGE"
            })
            .then(function (data) {
                var blob = new Blob([data], {
                    type: "application/pdf"
                });
                FileSaver.saveAs(blob, getInvoiceFileName(invoiceNumber));
            });
        }

        function getInvoiceFileName(invoiceNumber) {
            return $translate.instant("ENTER_SALES.SALE_COMPLETE.INVOICE_FILE_NAME", {orderId: invoiceNumber}) || ("invoice-" + invoiceNumber + ".pdf");
        }

        function invoiceModalPopup(invoiceNumber) {
            var modalInstance = $uibModal.open({
                templateUrl: "lookup-invoices/modal/emailAddressModal.html",
                backdrop: "static",
                keyboard: false,
                controller: "ConfirmModal",
                scope: $scope
            });

            modalInstance.result.then(function (confirmed) {
                if (confirmed) {
                    sendEmailInvoice(invoiceNumber);
                } else { cancelEmailInvoice(); }
            })
        }

        function emailInvoiceModalPopupTrigger(invoiceNumber) {

            invoiceModalPopup(invoiceNumber);
        }

        function cancelEmailInvoice() {
            model.currentEmail = undefined;
        }

        function sendEmailInvoice(invoiceNumber) {
            notify.forHttp(api.activation.sendEmailInvoice(invoiceNumber, model.currentEmail, $translate.currentLanguage().key), {
                    startTranslationId: "ENTER_SALES.SALE_SUMMARY.SENDING_RECEIPT_INVOICE_PROGRESS_TEXT",
                    endTranslationId: "ENTER_SALES.SALE_SUMMARY.SENT_RECEIPT_INVOICE_CONFIRMATION"
                })
                .then(function() {
                    cancelEmailInvoice();
                });
        }
        
    }
}());