(function () {
    "use strict";

    angular.module("cardspotWeb.areas.orderCards")
        .controller("OrderCards", OrderCards);

    OrderCards.$inject = ["$scope", "notify", "lookupValues", "orderCardsService", "REGION_TYPES", "CARD_ORDER_LANGUAGES", "$timeout", "$translate", "EVENT_NAMES", "UPLOAD_ENDPOINTS", "appConfig", "Upload", "$uibModal", "$routeParams"];

    function OrderCards($scope, notify, lookupValues, orderCardsService, REGION_TYPES, CARD_ORDER_LANGUAGES, $timeout, $translate, EVENT_NAMES, UPLOAD_ENDPOINTS, appConfig, Upload, $uibModal, $routeParams) {
        var model = this,
            stateRegionLabel = "State",
            frenchLanguageName = "LANGUAGES.FRENCH",
            supportedCountryKeys = ["Canada", "United States"];

        model.languages = CARD_ORDER_LANGUAGES;
        model.cancel = cancel;
        model.countrySelectionChanged = countrySelectionChanged;
        model.getCardDesigns = getCardDesigns;
        model.postalCodeRegexPattern = postalCodeRegexPattern;        
        model.editContact = editContact;                
        model.contactUpdateInProcess = false;
        model.requiresChaEntry = requiresChaEntry;
        model.hasValidCHAs = hasValidCHAs;
        model.cancelOrder = cancelOrder;
        model.completeOrder = completeOrder;

        model.LANGUAGE_NAME = {
            "ENGLISH": "en",
            "FRENCH": "fr"
        };

        model.uploadFiles = uploadFiles;
        model.removeCardImage = removeCardImage;
        model.hasSelectedCardDesign = hasSelectedCardDesign;
        model.orderCardsSuccessConfirmationModalPopup = orderCardsSuccessConfirmationModalPopup;

        init();

        function init() {                          
                        
            lookupValues.getCountries()
                .then(function(data) {
                    model.countries = getFilteredCountries(data);
                    getMerchantGroupContact();
                });
            getCardDesigns();
            model.order = {};
            
            if ($routeParams.cardDesignId) {
                model.order.selectedCardDesign = $routeParams.cardDesignId;
            }
        }

        function getMerchantGroupContact() {                      
            notify.forHttp(orderCardsService.getMerchantGroupContact(), { startTranslationId: "ORDER_CARDS.FORM.CONTACT_PROGRESS_TEXT" })
                .then(response => setContact(response));
        }

        function hasRegions() {
            return angular.isArray(model.regions) && model.regions.length > 0;
        } 

        function setContact(contact){                    
            if (Object.keys(contact).length === 0){
                model.contact = { country: "United States"};
            } else {
                model.contact = contact;
            }            

            var country = model.countries.find(x => x.countryKey === model.contact.country);
                
            model.contact.selectedCountry = country;
            if (country) {               
            lookupValues.getRegions(model.contact.country)
                    .then(regions => {
                        model.regions = regions;
                        model.showRegionSelection =  model.contact.selectedCountry && hasRegions() &&  model.contact.selectedCountry.regionType !== REGION_TYPES.NONE;
                        var region = model.regions.find(x => x.regionName === model.contact.stateOrProvince);                       
                        model.contact.selectedRegion = region;                         
                    }); 
            } 

            model.masterContact = angular.copy(model.contact);                     
        }

        function getFilteredCountries(countries){
            return countries.filter(x => supportedCountryKeys.includes(x.countryKey));
        }

        function cancel(){              
            setContact(model.masterContact);                    
        }

        function countrySelectionChanged() {
            
            model.contact.postalCode = null;
            model.contact.state = null;
            model.contact.province = null;            

            if (model.contact.selectedCountry) {
                lookupValues.getRegions( model.contact.selectedCountry.countryKey)
                    .then(regions => {
                        model.regions = regions;
                        model.showRegionSelection =  model.contact.selectedCountry && hasRegions() &&  model.contact.selectedCountry.regionType !== REGION_TYPES.NONE;
                    });
            }
        }

        function customerRegionModel(newRegion) {
            if (model.currentCustomer && model.currentCustomer.country) {
                if (model.currentCustomer.country.regionType === REGION_TYPES.STATE) {
                    return arguments.length ? (model.currentCustomer.state = newRegion) : model.currentCustomer.state;
                } else {
                    return arguments.length ? (model.currentCustomer.province = newRegion) : model.currentCustomer.province;
                }
            }
        }

        function getCardDesigns() { 

            notify.forHttp(orderCardsService.getCardDesigns(), { startTranslationId: "ORDER_CARDS.FORM.CARD_DESIGNS_PROGRESS_TEXT" })
                .then(response => {
                    model.cardDesigns = response.cardDesigns;
                });                                      
        }

        function postalCodeRegexPattern() {
            if (model.contact) {
                return model.contact.selectedCountry?.regionLabel === stateRegionLabel ? "^\\d{5}(-\\d{4})?" : "^[A-Za-z]\\d[A-Za-z][ -]?\\d[A-Za-z]\\d$";
            } else {
                return "";
            }
        }        

        function editContact(){
            model.contactUpdateInProcess = true;
            notify.forHttp(orderCardsService.saveMerchantGroupContact(
                mapContactModel(),                        
                { startTranslationId: "ORDER_CARDS.UPDATE_ORDER_PROGRESS" }))
                .then(() => {
                    model.masterContact = angular.copy(model.contact);
                    getCardDesigns();
                })
                .catch(() => model.contactUpdateInProcess = false); 
        }

        function mapContactModel() {
            return {
                firstName: model.contact.firstName,
                lastName: model.contact.lastName,
                title: model.contact.title,
                emailAddress: model.contact.emailAddress,
                phone: model.contact.phone,
                phoneExt: model.contact.phoneExt,
                propertyUrl: model.contact.propertyUrl,
                address1: model.contact.address1,
                address2: model.contact.address2,
                city: model.contact.city,
                stateOrProvince: model.contact.selectedRegion?.regionName,
                postalCode: model.contact.postalCode,
                country: model.contact.selectedCountry?.countryKey
            };            
        }

        function requiresChaEntry() {
            if (model.order && model.order.selectedLanguage) {
                return model.order.selectedLanguage.name === frenchLanguageName;
            }
            return false;
        }

        function hasValidCHAs() {
             if (model.order && model.order.numberOfCards && model.order.selectedLanguage && model.order.selectedLanguage.name === frenchLanguageName) {
                 return ((parseInt(model.order.numberOfEnglishChas, 10) || 0) +
                 (parseInt(model.order.numberOfFrenchChas, 10) || 0)) >=
                 (parseInt(model.order.numberOfCards, 10) || 0);
             }
             return model.order && model.order.numberOfCards > 0;
        }

        function cancelOrder() {
            resetOrderCards();         
        }

        function completeOrder() {
            editContact();
            model.orderRequestSubmissionInProgress = true;
            notify.forHttp(orderCardsService.saveOrderRequest(
                mapOrderRequestModel(),                        
                { startTranslationId: "ORDER_CARDS.SUBMIT_ORDER_PROGRESS" }))
                .then(() => {
                    resetOrderCards();     
                    orderCardsSuccessConfirmationModalPopupTrigger();
                })
                .catch(() => notify.showError("ORDER_CARDS.REQUEST_FAILURE"));           
        }

        function mapOrderRequestModel(){
            return {
                cardDesignId: model.order.selectedCardDesign ? model.order.selectedCardDesign.cardDesignId : null,
                cardImageUrl: model.order.cardImageUrl,
                numberOfCards: model.order.numberOfCards,
                language: model.order.selectedLanguage?.key,
                useRecycledCards: model.order.useRecycledCards,
                useSpecialtyFinish: model.order.useSpecialtyFinish,
                specialtyFinishNote: model.order.specialtyFinishNote,
                note: model.order.note,
                agreements: mapCHAAgreementsModel()
            };            
        }

        function mapCHAAgreementsModel(){
            return model.languages
                .map(lang => lang.key)
                .map(language => {                   
                    if (language === model.LANGUAGE_NAME.ENGLISH){
                        return {
                            language: language,
                            numberOfAgreements: model.order.selectedLanguage?.key === language ? 
                                model.order.numberOfCards : 
                                model.order.numberOfEnglishChas || 0
                        };
                    } else if (language === model.LANGUAGE_NAME.FRENCH){
                        return {
                            language: language,
                            numberOfAgreements: model.order.numberOfFrenchChas || 0
                        };
                    }
                });                    
        }       

        function resetOrderCards(){
            model.order = {};
            model.orderRequestSubmissionInProgress = false;
            $scope.$broadcast(EVENT_NAMES.PANEL_EXPAND, {});
            model.orderForm.$setPristine();
            model.orderForm.$setUntouched();            
        }

        function uploadFiles(file, errFiles) {
            //ref: http://jsfiddle.net/danialfarid/0mz6ff9o/135/
            model.file = file;
            model.errFile = errFiles && errFiles[0];
            if (file) {
                file.upload = Upload.upload({
                    url: UPLOAD_ENDPOINTS.CARD_IMAGE + appConfig.selectedMerchantGroup().uniqueTag,
                    data: { file: file }
                });

                file.upload.then(function(response) {
                        $timeout(function() {
                            if (response && response.data && response.data.succeeded && response.data.cardImageUrl) {
                                model.order.cardImageUrl = response.data.cardImageUrl;
                                model.cardImageIsPdf = model.order.cardImageUrl.toLowerCase().endsWith(".pdf");
                                model.order.selectedCardDesign = null;

                            } else {
                                notify.showError("ORDER_CARDS.CARD_IMAGE_UPLOAD_FAILED");
                            }
                            $timeout(function() {
                                    file.progress = undefined;
                                },
                                400);
                        });
                    },
                    function(response) { //error
                        if (response.status > 0)
                            model.errorMsg = response.status + ": " + response.data;
                    },
                    function(evt) { //upload progress
                        file.progress = Math.min(100,
                            parseInt(100.0 *
                                evt.loaded /
                                Math.max(evt.total, 0.001)));
                    });
            }
        }

        function removeCardImage() {
            model.order.cardImageUrl = null;
        }
        function hasSelectedCardDesign() {
            return model.order.cardImageUrl || model.order.selectedCardDesign;
        }
        function orderCardsSuccessConfirmationModalPopup() {
            var orderCardsSuccessConfirmationModalInstance = $uibModal.open({
                templateUrl: "order-cards/modal/orderCompleteSuccess.html",
                backdrop: "static",
                keyboard: false,
                size: "sm",
                controller: "ConfirmModal",
                scope: $scope
            })
        }

        function orderCardsSuccessConfirmationModalPopupTrigger() {
            model.orderCardsSuccessConfirmationModalPopup();
        }
    }
}());