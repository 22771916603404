﻿import htmlTemplate from './remove-inventory.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
        .config(configRemoveInventory);

    configRemoveInventory.$inject = ["$routeProvider", "NAV_URLS", "CLAIMS"];

    function configRemoveInventory($routeProvider, NAV_URLS, CLAIMS) {

        $routeProvider
            .when(NAV_URLS.REMOVE_INVENTORY + "/:cardOrderId?", {
                template: htmlTemplate,
                controller: "RemoveInventory",
                controllerAs: "model",
                requiredClaim: CLAIMS.RECONCILE_CARD_INVENTORY
            });
    }

}());