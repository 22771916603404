﻿import angular from "angular";

(function () {
    "use strict";

angular.module("cardspotWeb", [
    "ngRoute",
    "ngCookies",
    "ngSanitize",
    "ngDialog",
    "ngAnimate",
    "ui.bootstrap",
	"ngFileUpload",
    "ui.select",
    "ngIdle",
    "angular-owl-carousel-2",
    "currencyFilter",
    "ngFileSaver",

    "pascalprecht.translate",
    "angular-jwt",
    "angular-storage",
    "angular.emlpayments",
    "angularMoment",
	"angular-confirm",

	"cardspotWeb.areas.global",
	"cardspotWeb.areas.lookupCard",
    "cardspotWeb.areas.enterSales",
    "cardspotWeb.areas.enterSundries",
    "cardspotWeb.areas.lookupCustomer",
    "cardspotWeb.areas.lookupSales",
    "cardspotWeb.areas.tools",
    "cardspotWeb.areas.onlineResources",
    "cardspotWeb.areas.login",
    "cardspotWeb.areas.confirmSignage",
    "cardspotWeb.areas.applicationConfiguration",
    "cardspotWeb.areas.transferCard",        
    "cardspotWeb.areas.receipts",
    "cardspotWeb.areas.fulfillment",
    "cardspotWeb.areas.orderCards",
    "cardspotWeb.areas.settings",
    "cardspotWeb.areas.testSwiper",
    "cardspotWeb.services",
	"cardspotWeb.areas.campaigns",
    "cardspotWeb.areas.manageLocations",
    "cardspotWeb.areas.home",
    "cardspotWeb.areas.manageSundries",
    "cardspotWeb.areas.diagnostic",
    "cardspotWeb.areas.manageInventory",
    "cardspotWeb.areas.reconcileInventory",
    "cardspotWeb.areas.integratedpayment",
    "cardspotWeb.areas.lookupInvoices",
    "cardspotWeb.areas.hvtRequest"
    ]);

}());