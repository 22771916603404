﻿import htmlTemplate from './tools.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
        .config(configTools);

    configTools.$inject = ["$routeProvider", "NAV_URLS"];

    function configTools($routeProvider, NAV_URLS) {

        $routeProvider
            .when(NAV_URLS.TOOLS, {
                template: htmlTemplate,
                controller: "Tools",
                controllerAs: "model"
            });
    }

}());