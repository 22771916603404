import _ from 'underscore';

(function () {
	"use strict";

	angular.module("angular.emlpayments")
    .factory("limitService", limitServiceFactory);

	limitServiceFactory.$inject = ["$q", "api", "$filter", "HVT_LIMITS", "notify"];

	function limitServiceFactory($q, api, $filter, HVT_LIMITS, notify) {
        var programHvtLimits = [];
     
        return {
            getProgramHvtLimits,
            getHVTMinimumLimit,
            getHVTInternalApprovalLimit,
            getHVTBankApprovalLimit,
            getCardMaximumExemptionMinimumLimit,
            getCardMaximumExemptionMaximumLimit,
            reset
        };
     
        function getProgramHvtLimits(programUniqueTag) {
            if (programHvtLimits && programHvtLimits.length > 0) { return programHvtLimits }
            var deferred = $q.defer();
            
            notify.forHttp(api.limits.getProgramHvtLimits(programUniqueTag), { startTranslationId: "ENTER_SALES.MESSAGES.GETTING_PROGRAM_HVT_LIMITS"})
            .then(function (response) { 
                handleProgramHvtLimitResponse(response);
                deferred.resolve(response);
            },
            function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        function handleProgramHvtLimitResponse(response) {
            reset();
            programHvtLimits = response;
        }

        function getHVTMinimumLimit() {
            return getHVTLimitByType(HVT_LIMITS.HVT_MINIMUM);
        }

        function getHVTInternalApprovalLimit() {
            return getHVTLimitByType(HVT_LIMITS.HVT_INTERNAL_APPROVAL);
        }

        function getHVTBankApprovalLimit() {
            return getHVTLimitByType(HVT_LIMITS.HVT_BANK_APPROVAL);
        }

        function getCardMaximumExemptionMinimumLimit() {
            return getHVTLimitByType(HVT_LIMITS.CARD_MAXIMUM_EXEMPTION_MINIMUM);
        }

        function getCardMaximumExemptionMaximumLimit() {
            return getHVTLimitByType(HVT_LIMITS.CARD_MAXIMUM_EXEMPTION_MAXIMUM);
        }

        function getHVTLimitByType(hvtLimitType) {
            if (programHvtLimits && programHvtLimits.length > 0) {
                var limit = _.find(programHvtLimits, function (hvtLimit) {
                    return hvtLimit.hvtLimitType === hvtLimitType;
                })
                return limit;
            }
        }

        function reset() {
            programHvtLimits = angular.copy([], programHvtLimits);
        }
    }
}());