﻿import htmlTemplate from './today-inventory-count.directive.html';

(function () {
    "use strict";

    angular.module("cardspotWeb.areas.reconcileInventory")
        .directive("emlTodayInventoryCount", configDirective);

    function configDirective() {
        return {
            restrict: "E",
            scope: {
                todaysInventory: "="
            },
            controller: InventoryCountController,
            controllerAs: "model",
            template: htmlTemplate
        };
    }

    InventoryCountController.$inject = ["$rootScope", "$scope", "notify", "$uibModal", "authService", "cardService", "inventoryService", "$filter"];

    function InventoryCountController($rootScope, $scope, notify, $uibModal, authService, cardService, inventoryService, $filter) {
        var model = this;

        model.canViewRestrictedCardNumbers = authService.canViewRestrictedCardNumbers();
        model.maskPrintText = cardService.maskPrintText;
        model.confirmDeleteModalPopupTrigger = confirmDeleteModalPopupTrigger;
        model.isRemoveInventoryPage = $rootScope.isRemoveInventoryPage;
        model.isConfirmInventoryPage = $rootScope.isConfirmInventoryPage;

        function confirmDeleteModalPopup(cardOrderReconciliationId, firstCardNumber, lastCardNumber) {
            var modalInstance = $uibModal.open({
                templateUrl: "confirm-inventory/modal/confirmDeleteDialog.html",
                backdrop: "static",
                keyboard: false,
                size: "sm",
                controller: "ConfirmModal",
                scope: $scope
            })

            modalInstance.result.then(function (confirmed) {
                if (confirmed) {
                    notify.forHttp(inventoryService.deleteInventoryCardOrderReconciliation(cardOrderReconciliationId, firstCardNumber, lastCardNumber))
                        .then(function () {
                            $scope.todaysInventory = $filter('filter')($scope.todaysInventory, { cardOrderReconciliationId: cardOrderReconciliationId }, function (obj, test) {
                                return obj !== test;
                            })
                        });
                }
            })
        }

        function confirmDeleteModalPopupTrigger(cardOrderReconciliationId, firstCardNumber, lastCardNumber) {
            confirmDeleteModalPopup(cardOrderReconciliationId, firstCardNumber, lastCardNumber);
        }
    }
}());