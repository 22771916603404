import leftNavTemplate from './leftNav.directive.html';

(function () {
    "use strict";

	angular.module("cardspotWeb.areas.global")
		.directive("emlLeftNav", configDirective);

	function configDirective() {
		return {
			restrict: "E",
			scope: {
				isAuthenticated: "=",
                hasSelectedMerchantGroup: "=",
                hasSundryTypes: "="
			},
			controller: LeftNavController,
			controllerAs: "model",
			template: leftNavTemplate
		};
	}

	LeftNavController.$inject = ["NAV_URLS", "CLAIMS", "$location", "authService", "appConfig", "$rootScope", "SF_EVENTS", "notify", "api"];

	function LeftNavController(NAV_URLS, CLAIMS, $location, authService, appConfig, $rootScope, SF_EVENTS, notify, api) {
		var model = this;
		model.NAV_URLS = NAV_URLS;
		model.CLAIMS = CLAIMS;
		model.isActive = isActive;
		model.cssClass = cssClass;
		model.canManageReceiptItems = false;
		model.canManagePropertySettings = false;
		model.canManageCampaigns = false;
		model.canManageLocations = false;
        model.canManageMerchantGroupSundries = false;
		model.username = authService.currentUsername();
		model.allowCorporateInvoicing = false;
		model.toggleHamburger = toggleHamburger;
		model.hvtRequestEnabled = false;

		init();

		$rootScope.$on(SF_EVENTS.LOGGED_IN_SUCCESS, init);
		$rootScope.$on(SF_EVENTS.MERCHANT_GROUP_CHANGED, init);
        $rootScope.$on(SF_EVENTS.GLOBAL_SETTINGS_CHANGED, handleGlobalSettingsChange);
        $rootScope.$on(SF_EVENTS.LOGGED_IN_SUCCESS, function () {
            model.username = authService.currentUsername();
        })
		model.logout = function () {
		    $rootScope.logout();
		};

		function init() {
		    model.canManageReceiptItems = canManageReceiptItems();
		    model.canManagePropertySettings = canManagePropertySettings();
		    model.canManageCampaigns = canManageCampaigns();
		    model.canManageLocations = canManageLocations();
			model.canManageMerchantGroupSundries = canManageMerchantGroupSundries();
            model.canFulfillCorporate = canFulfillCorporate();
            model.canFulfillConsumer = canFulfillConsumer();
			model.canFulfillConsumerOrCorporate = canFulfillConsumerOrCorporate();
			model.canManageCards = canManageCards();
			allowCorporateInvoicing();
			allowHvtRequest();
		}

		function handleGlobalSettingsChange(event, settings) {
		    model.canManageCampaigns = authService.canManageCampaigns() && settings.manageCampaigns;
			model.canManageLocations = authService.canManageLocations() && settings.manageLocations;
			model.allowCorporateInvoicing = settings.allowCorporateInvoicing;
			model.hvtRequestEnabled = settings.allowHvtRequest;
		}

		function isActive(linkUrl) {
			return linkUrl === $location.path();
		}

		function cssClass() {
            return "nav-sidebar";
		}

		function canManageReceiptItems() {
		    return authService.canManageReceiptItems();
		}

		function canManagePropertySettings() {
		    return authService.canManagePropertySettings();
		}

		function canManageCampaigns() {
		    return authService.canManageCampaigns() && appConfig.selectedMerchantGroup() && appConfig.selectedMerchantGroup().manageCampaigns;
		}

		function canManageLocations() {
		    return authService.canManageLocations() && appConfig.selectedMerchantGroup() && appConfig.selectedMerchantGroup().manageLocations;
		}

		function canManageMerchantGroupSundries() {
		    return authService.canManageMerchantGroupSundries();
		}

		function canFulfillCorporate() {
			return authService.canFulfillCorporate();
        }

        function canFulfillConsumer() {
            return authService.canFulfillConsumer();
        }

        function canFulfillConsumerOrCorporate() {
            return authService.canFulfillConsumer() || authService.canFulfillCorporate();
		}

		function canManageCards() {
			return authService.canManageCards();
		}

		function allowCorporateInvoicing() {
			var selectedMerchantGroup = appConfig.selectedMerchantGroup();

			if (selectedMerchantGroup) {
				notify.forHttp(api.configuration.getMerchantGroupSettings(selectedMerchantGroup.uniqueTag), {
					startTranslationId: "APPLICATION_CONFIGURATION.GET_MERCHANT_GROUP_SETTINGS_PROGRESS"
				})
				.then(function(response) {
					handleSettingsResponse(selectedMerchantGroup, response);

					model.allowCorporateInvoicing = selectedMerchantGroup.corporateInvoiceEnabled;
				});
			}
		}

		function handleSettingsResponse(selectedMerchantGroup, response) {
			selectedMerchantGroup.supportsCustomerAtCardLevel = response.supportsCustomerAtCardLevel;
			selectedMerchantGroup.supportsSearchByClientTrackingId = response.supportsSearchByClientTrackingId;
			selectedMerchantGroup.defaultCampaignSelection = response.defaultCampaignSelection;
			selectedMerchantGroup.manageCampaigns = response.manageCampaigns;
			selectedMerchantGroup.manageLocations = response.manageLocations;
			selectedMerchantGroup.supportSearchByCarrierNumber = response.supportSearchByCarrierNumber;
			selectedMerchantGroup.supportsTerminalPayments = response.supportsTerminalPayments;
			selectedMerchantGroup.corporateInvoiceEnabled = response.corporateInvoiceEnabled;
			selectedMerchantGroup.clickAndCollectEnabled = response.clickAndCollectEnabled;
			selectedMerchantGroup.hvtRequestEnabled = response.hvtRequestEnabled;
			appConfig.setSelectedMerchantGroup(selectedMerchantGroup);
		}

		function toggleHamburger() {
			document.querySelector(".hamburger").classList.toggle("is-active");
		}

		function allowHvtRequest() {
			var selectedMerchantGroup = appConfig.selectedMerchantGroup();

			if (selectedMerchantGroup) {
				notify.forHttp(api.configuration.getMerchantGroupSettings(selectedMerchantGroup.uniqueTag), {
					startTranslationId: "APPLICATION_CONFIGURATION.GET_MERCHANT_GROUP_SETTINGS_PROGRESS"
				})
				.then(function(response) {
					handleSettingsResponse(selectedMerchantGroup, response);

					model.hvtRequestEnabled = selectedMerchantGroup.hvtRequestEnabled;
				});
			}
		}
	}
} ());