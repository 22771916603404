﻿import htmlTemplate from './manage-sundries.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
		.config(configSettings);

    configSettings.$inject = ["$routeProvider", "NAV_URLS", "CLAIMS"];

    function configSettings($routeProvider, NAV_URLS, CLAIMS) {

        $routeProvider
			.when(NAV_URLS.MANAGE_SUNDRIES, {
			    template: htmlTemplate,
			    controller: "ManageSundries",
			    controllerAs: "model",
			    requiredClaim: CLAIMS.MANAGE_MERCHANT_GROUP_SUNDRIES
			});
    }

}());