import htmlTemplate from './customer-form.directive.html';
import _ from 'underscore';

(function () {
    "use strict";

    angular.module("cardspotWeb.areas.enterSales")
        .directive("emlCustomerForm", configDirective);

    function configDirective() {
        return {
            restrict: "E",
            scope: {
                customer: "=",
                amlThresholdExceeded: "=",
                customerTypeChangedEvent: "=",
                allowMarketingOptIn: "=",
                merchantGroupCountry: "=",
                customerTabShownEvent: "=",
                isReadOnly: "=?"
            },
            controller: CustomerFormController,
            controllerAs: "model",
            template: htmlTemplate
        };
    }

    CustomerFormController.$inject = ["$scope", "authService", "lookupValues", "REGION_TYPES", "appConfig", "settings"];

    function CustomerFormController($scope, authService, lookupValues, REGION_TYPES, appConfig, settings) {
        var model = this;
        var individual = "Individual";

        model.isIndividual = isIndividual;
        model.customerIsIndividual = customerIsIndividual;
        model.customerIsCompany = customerIsCompany;
        model.regions = [];
        model.countrySelectionChanged = countrySelectionChanged;
        model.showRegionSelection = false;
        model.customerRegionModel = customerRegionModel;
        model.canAddCustomerInformation = authService.canAddCustomerInformation();
        model.kycDocumentTypes = [];
        model.onExpirationDateChange = onExpirationDateChange;
        model.onBirthdateChange = onBirthdateChange;

        init();

        function init() {
            if ($scope.customer && !$scope.customer.customerType) {
                $scope.customer.customerType = individual;
            }

            lookupValues.getCountries()
                .then(function (data) {
                    model.countries = data;
                });

            if ($scope.merchantGroupCountry && $scope.customer && !$scope.customer.country) {
                $scope.customer.country = $scope.merchantGroupCountry;
                countrySelectionChanged();
            }

            settings.getSettings()
                .then(response => {
                    model.corporateInvoiceEnabled = response.data.corporateInvoiceEnabled;
                });

            lookupValues.getKYCDocumentTypes()
                .then(resp => {
                    model.kycDocumentTypes = resp.data;
                });
        }

        $scope.datePopup = {
            opened: false
        };

        $scope.openDatePicker = function () {
            $scope.datePopup.opened = true;
        };

        $scope.kycDatePopup = {
            opened: false
        };

        $scope.openKYCDatePicker = function () {
            $scope.kycDatePopup.opened = true;
        };

        $scope.$on($scope.customerTabShownEvent, function () {
            updateModel();
        });

        function updateModel() {
            if ($scope.merchantGroupCountry && $scope.customer && !$scope.customer.country) {
                $scope.customer.country = findCountry();
                countrySelectionChanged();
            }
            initRegions();
        }

        function findCountry() {
            return _.find(model.countries, function (country) {
                return country.countryName === $scope.merchantGroupCountry;
            });
        }

        function countrySelectionChanged() {
            $scope.customer.postalCode = null;
            $scope.customer.state = null;
            $scope.customer.province = null;
            initRegions();
        }

        function initRegions() {
            if ($scope.customer.country) {
                lookupValues.getRegions($scope.customer.country.countryKey)
                    .then(function (regions) {
                        model.regions = regions;
                        model.showRegionSelection = $scope.customer.country && hasRegions() && $scope.customer.country.regionType !== REGION_TYPES.NONE;
                    });
            }
        }

        function customerRegionModel(newRegion) {
            if ($scope.customer && $scope.customer.country) {
                if ($scope.customer.country.regionType === REGION_TYPES.STATE) {
                    return arguments.length ? ($scope.customer.state = newRegion) : $scope.customer.state ?? "";
                } else {
                    return arguments.length ? ($scope.customer.province = newRegion) : $scope.customer.province ?? "";
                }
            }
        }

        function hasRegions() {
            return angular.isArray(model.regions) && model.regions.length > 0;
        }

        function isIndividual() {
            return $scope.customer && $scope.customer.customerType === individual;
        }
        function customerIsIndividual() {
            $scope.customer.customerType = individual;
            $scope.customer.companyName = undefined;
            $scope.customer.companyRegistrationNumber = undefined;
            $scope.customer.vat = undefined;
            $scope.$emit($scope.customerTypeChangedEvent, {});
        }

        function customerIsCompany() {
            $scope.customer.customerType = "Company";
            $scope.customer.birthDate = undefined;
            $scope.customer.firstName = $scope.customer.lastName = undefined;
            $scope.$emit($scope.customerTypeChangedEvent, {});
        }

        function onExpirationDateChange(date) {
            if (date < Date.now()) {
                model.showExpiredDateMessage = true;
                $scope.customer.kycExpiryDate = undefined;
            } else {
                model.showExpiredDateMessage = false;
            }
        }

        function onBirthdateChange(date) {
            if (date > Date.now()) {
                model.showInvalidBirthdateMessage = true;
                $scope.customer.birthDate = undefined;
            } else {
                model.showInvalidBirthdateMessage = false;
            }
        }
    }
}());
