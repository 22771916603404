﻿import _ from 'underscore';

(function () {
    "use strict";

    angular.module("angular.emlpayments")
        .factory("cardService", cardServiceFactory);

    cardServiceFactory.$inject = ["api", "appConfig"];

    function cardServiceFactory(api, appConfig) {
        var cardsRetrieved = [],
            clientTrackingCards,
            transferCard,
            carrierNumberCards;

        return {
            lookupByPrintText,
            lookupByExternalId,
            lookupBySwipe,
            lookupByClientTrackingId,
            haveCard,
            cardsRetrieved,
            cardJustRetrieved,
            removeCard,
            clearAll,
            lockCard,
            unlockCard,
            findCard,
            refreshCard,
            setTransferCard,
            getTransferCard,
            clientTrackingCardsRetrieved,
            resetClientTrackingCards,
            maskPrintText,
            getSequentialPart,
            lookupByCarrierNumber,
            resetCarrierNumberCards,
            carrierNumberCardsRetrieved,
            extendAccountExpiration,
            calculateNewAccountExpiration
        };

        function clearAll() {
            angular.copy([], cardsRetrieved);
            clientTrackingCards = null;
        }

        function resetClientTrackingCards() {
            clientTrackingCards = null;
        }

        function resetCarrierNumberCards() {
            carrierNumberCards = null;
        }

        function cardJustRetrieved() {
            return cardsRetrieved[0];
        }

        function haveCard(printText, primaryAccountNumber) {
            if (primaryAccountNumber) {
                return _.find(cardsRetrieved, function (card) {
                    return card.cardNumber === primaryAccountNumber;
                });
            }
            if (printText) {
                return _.find(cardsRetrieved, function (card) {
                    return card.printText === printText;
                });
            }
            return false;
        }

        function findCard(printText) {
            return _.find(cardsRetrieved, function (card) {
                return card.printText === printText;
            });
        }

        function removeCard(printText, primaryAccountNumber) {
            if (primaryAccountNumber) {
                angular.copy(_.reject(cardsRetrieved, function (card) {
                    return card.cardNumber === primaryAccountNumber;
                }), cardsRetrieved);
            }
            if (printText) {
                angular.copy(_.reject(cardsRetrieved, function (card) {
                    return card.printText === printText;
                }), cardsRetrieved);
            }

        }

        function lookupByPrintText(printText) {
            var lookupPromise = api.cards.lookupCardByPrintText(printText);
            lookupPromise.then(handleLookupResponse);
            return lookupPromise;
        }

        function lookupByExternalId(externalId) {
            var lookupPromise = api.cards.lookupCardByExternalId(externalId);
            lookupPromise.then(handleLookupResponse);
            return lookupPromise;
        }

        function lookupBySwipe(track1Data, track2Data) {
            var lookupPromise = api.cards.lookupCardBySwipe(track1Data, track2Data);
            lookupPromise.then(handleLookupResponse);
            return lookupPromise;
        }

        function lookupByClientTrackingId(clientTrackingId) {
            return api.cards.lookupCardByClientTrackingId(appConfig.selectedMerchantGroup().uniqueTag, clientTrackingId)
                .then(handleClientTrackingIdResponse);
        }

        function lookupByCarrierNumber(carrierNumber) {
            return api.cards.lookupCardByCarrierNumber(appConfig.selectedMerchantGroup().uniqueTag, carrierNumber)
                .then(handleCarrierNumberResponse);
        }

        function maskPrintText(printText) {
            if (!printText) return printText;
            return `**** **** **** ${printText.substr(printText.length - 4)}`;
        }

        function getSequentialPart(printText) {
            if (!printText) return printText;
            return printText.substr(0, printText.length - 1);
        }

        function handleClientTrackingIdResponse(response) {
            let cards = response.data.cards;
            if (!cards || cards.length === 0) {
                return Promise.reject(response);
            } else if (cards.length > 1) {
                clientTrackingCards = cards;
                return Promise.resolve(response);
            } else {
                return lookupByPrintText(cards[0].printText);
            }
        }

        function handleCarrierNumberResponse(response) {
            let cards = response.data.cards;
            if (!cards || cards.length === 0) {
                return Promise.reject(response);
            } else if (cards.length > 1) {
                carrierNumberCards = cards;
            } else {
                return lookupByPrintText(cards[0].printText);
            }
        }

        function handleLookupResponse(response) {
            var cardInfo = response.data.card;

            if (!response.data.succeeded || cardInfo === null)
                return;

            if (!haveCard(undefined, cardInfo.cardNumber)) {
                cardsRetrieved.unshift(cardInfo);
            } else {
                var index = _.findIndex(cardsRetrieved, function (card) {
                    return card.cardNumber === cardInfo.cardNumber;
                });
                cardsRetrieved[index] = cardInfo;
            }
        }

        function lockCard(cardNumber, reason, note) {
            return api.cardStatus.lockCard(appConfig.selectedMerchantGroup().uniqueTag, cardNumber, appConfig.getSelectedLocation().location, reason, note);
        }

        function unlockCard(cardNumber, note) {
            return api.cardStatus.unlockCard(appConfig.selectedMerchantGroup().uniqueTag, cardNumber, appConfig.getSelectedLocation().location, note);
        }

        function refreshCard(printText) {
            return lookupByPrintText(printText);
        }

        function setTransferCard(card) {
            transferCard = card;
        }

        function getTransferCard() {
            return transferCard;
        }

        function clientTrackingCardsRetrieved() {
            return clientTrackingCards;
        }

        function carrierNumberCardsRetrieved() {
            return carrierNumberCards;
        }

        function extendAccountExpiration(cardNumber, newAccountExpirationDate, extensionReason) {
            return api.expiration.extendAccountExpiration(cardNumber, newAccountExpirationDate, extensionReason);
        }

        function calculateNewAccountExpiration(cardNumber) {
            return api.expiration.calculateAccountExpiration(cardNumber);
        }
    }

}());