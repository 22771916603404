import angular from "angular";
import _ from "underscore";

(function () {
    "use strict";

    angular.module("cardspotWeb.services")
        .factory("appConfig", configFactory);

    configFactory.$inject = ["$log", "api", "notify", "store", "STORE_KEYS", "authService", "sundryService"];

    function configFactory($log, api, notify, store, STORE_KEYS, authService, sundryService) {
        var merchantGroups;

        return {
            getMerchantGroups,
            merchantGroupsRetrieved,
            hasMerchantGroups,
            resetMerchantGroups,
            selectedMerchantGroup: getSelectedMerchantGroup,
            setSelectedMerchantGroup,
            hasSelectedMerchantGroup,
            getMerchantGroupLocations,
            getSelectedLocation,
            setSelectedLocation,
            resetLocation,
            removeLocationFromLocalStorage,
            isValid: configurationIsValid,
            init,
            hasSundryTypes,
            refreshSelectedMerchantGroup,
            updateMerchantGroupSettings,
            getDisplayFeeBasedSignageMessageFlag
        };

        function init(successCallback) {

            notify.forHttp(api.configuration.getMerchantGroups(), {
                    startTranslationId: "APPLICATION_CONFIGURATION.GET_MERCHANT_GROUPS_PROGRESS"
                })
                .then(function(data) {
                    merchantGroups = data;
                    var selectedMerchantGroup = getSelectedMerchantGroup();
                    //check existing selected merchant group if applicable
                    if (selectedMerchantGroup && !_.find(merchantGroups, function(group) {
                            return group.uniqueTag === selectedMerchantGroup.uniqueTag;
                        })) {
                        removeSelectedMerchantGroup();
                    } else if (selectedMerchantGroup) {
                        refreshSelectedMerchantGroup(selectedMerchantGroup);
                    }

                    if (angular.isFunction(successCallback)) {
                        successCallback();
                    }
                });
        }

        function refreshSelectedMerchantGroup(selectedMerchantGroup, successCallback) {

            var refreshedMerchantGroup = _.find(merchantGroups, function(merchantGroup) {
                return merchantGroup.uniqueTag === selectedMerchantGroup.uniqueTag;
            });

            if (refreshedMerchantGroup) {
                sundryService.getSundryTypes(refreshedMerchantGroup.uniqueTag).then(function(sundryTypes) {
                    refreshedMerchantGroup.sundryItemTypes = sundryTypes;
                    notify.forHttp(api.configuration.getMerchantGroupSettings(refreshedMerchantGroup.uniqueTag), {
                            startTranslationId: "APPLICATION_CONFIGURATION.GET_MERCHANT_GROUP_SETTINGS_PROGRESS"
                        })
                        .then(function(response) {
                            refreshedMerchantGroup.supportsCustomerAtCardLevel = response.supportsCustomerAtCardLevel;
                            refreshedMerchantGroup.supportsSearchByClientTrackingId = response.supportsSearchByClientTrackingId;
                            refreshedMerchantGroup.defaultCampaignSelection = response.defaultCampaignSelection;
                            refreshedMerchantGroup.manageCampaigns = response.manageCampaigns;
                            refreshedMerchantGroup.manageLocations = response.manageLocations;
                            refreshedMerchantGroup.supportSearchByCarrierNumber = response.supportSearchByCarrierNumber;
                            refreshedMerchantGroup.supportsTerminalPayments = response.supportsTerminalPayments;
                            refreshedMerchantGroup.corporateInvoiceEnabled = response.corporateInvoiceEnabled;
                            refreshedMerchantGroup.individualCardReceiptsEnabled = response.individualCardReceiptsEnabled;
                            refreshedMerchantGroup.clickAndCollectEnabled = response.clickAndCollectEnabled;
                            refreshedMerchantGroup.hvtRequestEnabled = response.hvtRequestEnabled;
                            setSelectedMerchantGroup(refreshedMerchantGroup);

                            if (angular.isFunction(successCallback)) {
                                successCallback();
                            }
                        });
                });
            }
        }

        function updateMerchantGroupSettings(supportsCustomerAtCardLevel, supportsSearchByClientTrackingId, defaultCampaignSelection, manageCampaigns, receiptGroupingThreshold, manageLocations, supportSearchByCarrierNumber, corporateInvoiceEnabled, individualCardReceiptsEnabled, clickAndCollectEnabled, hvtRequestEnabled) {
			var selectedMerchantGroup = getSelectedMerchantGroup();
            selectedMerchantGroup.supportsCustomerAtCardLevel = supportsCustomerAtCardLevel;
            selectedMerchantGroup.supportsSearchByClientTrackingId = supportsSearchByClientTrackingId;
            selectedMerchantGroup.defaultCampaignSelection = defaultCampaignSelection;
	        selectedMerchantGroup.manageCampaigns = manageCampaigns;
            selectedMerchantGroup.receiptGroupingThreshold = receiptGroupingThreshold;
            selectedMerchantGroup.manageLocations = manageLocations;
            selectedMerchantGroup.supportSearchByCarrierNumber = supportSearchByCarrierNumber;
            selectedMerchantGroup.corporateInvoiceEnabled = corporateInvoiceEnabled;
            selectedMerchantGroup.individualCardReceiptsEnabled = individualCardReceiptsEnabled;
            selectedMerchantGroup.clickAndCollectEnabled = clickAndCollectEnabled;
            selectedMerchantGroup.hvtRequestEnabled = hvtRequestEnabled;
            setSelectedMerchantGroup(selectedMerchantGroup);
        }

        function getMerchantGroupLocations(merchantGroupUniqueTag) {
            return notify.forHttp(api.configuration.getMerchantGroupLocations(merchantGroupUniqueTag), {
                startTranslationId: "APPLICATION_CONFIGURATION.GET_MERCHANT_GROUP_LOCATIONS_PROGRESS"
            });
        }

        function getDisplayFeeBasedSignageMessageFlag(merchantGroupUniqueTag) {
            var promise = api.configuration.getDisplayFeeBasedSignageMessageFlag(merchantGroupUniqueTag);
            return promise;
        }

        function resetMerchantGroups() {
            merchantGroups = undefined;
        }

        function getMerchantGroups() {
            return merchantGroups;
        }

        function merchantGroupsRetrieved() {
            return merchantGroups && angular.isArray(merchantGroups);
        }

        function hasMerchantGroups() {
            return merchantGroupsRetrieved() && merchantGroups.length > 0;
        }

        function hasSelectedMerchantGroup() {
            return getSelectedMerchantGroup();
        }

        function getSelectedMerchantGroup() {
            var merchantGroupsSelected = getMerchantGroupsSelected();
            if (merchantGroupsSelected) {
                return _.find(merchantGroupsSelected, function(selectedGroup) {
                    return selectedGroup.username === authService.currentUsername();
                });
            }
            return null;
        }

        function setSelectedMerchantGroup(merchantGroup) {
            var merchantGroupsSelected = getMerchantGroupsSelected();
            if (!merchantGroupsSelected) {
                merchantGroupsSelected = [];
                resetLocation();
            } else {
                merchantGroupsSelected = removeSelectedMerchantGroup(merchantGroupsSelected);
            }

            merchantGroup.username = authService.currentUsername();
            merchantGroupsSelected.push(merchantGroup);
            store.set(STORE_KEYS.SELECTED_MERCHANT_GROUPS, merchantGroupsSelected);
        }

        function getMerchantGroupsSelected() {
            return store.get(STORE_KEYS.SELECTED_MERCHANT_GROUPS);
        }

        function removeSelectedMerchantGroup(merchantGroupsSelected) {
            resetLocation();
            if (merchantGroupsSelected) {
                return _.reject(merchantGroupsSelected, function(selectedGroup) {
                    return selectedGroup.username === authService.currentUsername();
                });
            }
            return [];
        }

        function configurationIsValid() {
            return hasSelectedMerchantGroup() && getSelectedLocation();
        }

        function getSelectedLocation() {
            var locationsSelected = getLocationsSelected();
            if (locationsSelected) {
                return _.find(locationsSelected, function(selectedLocation) {
                    return selectedLocation.username === authService.currentUsername();
                });
            }
            return null;
        }

        function getLocationsSelected() {
            return store.get(STORE_KEYS.SELECTED_LOCATIONS);
        }

        function setSelectedLocation(location) {
            var locationsSelected = getLocationsSelected();
            if (!locationsSelected) {
                locationsSelected = [];
            } else {
                locationsSelected = removeSelectedLocation(locationsSelected);
            }

            location.username = authService.currentUsername();
            locationsSelected.push(location);
            store.set(STORE_KEYS.SELECTED_LOCATIONS, locationsSelected);
        }

        function removeSelectedLocation(locationsSelected) {
            if (locationsSelected) {
                return _.reject(locationsSelected,
                    function(selectedLocation) {
                        return selectedLocation.username === authService.currentUsername();
                    });
            }
            return [];
        }

        function resetLocation() {
            removeSelectedLocation(getLocationsSelected());
        }

        function removeLocationFromLocalStorage() {
            store.remove(STORE_KEYS.SELECTED_LOCATIONS);
        }

        function hasSundryTypes() {

            var selectedMerchantGroup = getSelectedMerchantGroup();
            var sundryItemTypes = selectedMerchantGroup ? selectedMerchantGroup.sundryItemTypes : null;

            return angular.isArray(sundryItemTypes) && sundryItemTypes.length > 0 && !selectedMerchantGroup.supportsCustomerAtCardLevel;
        }
    }

}());