(function() {
    "use strict";

    angular.module("cardspotWeb.areas.testSwiper")
        .controller("TestSwiper", TestSwiper);

    TestSwiper.$inject = ["$scope", "$timeout", "SF_EVENTS","$window"];

    function TestSwiper($scope, $timeout, SF_EVENTS, $window) {
        var model = this;
        model.swipes = [];
        model.haveSwipes = haveSwipes;
        model.clearSwipes = clearSwipes;        

        $scope.$on(SF_EVENTS.CARDSWIPE_SUCCESS, handleCardSwipeSuccess);

        function handleCardSwipeSuccess() {
            $timeout(function() {
                model.swipes.push({});
            });
        }

        function haveSwipes() {
            return angular.isArray(model.swipes) && model.swipes.length > 0;
        }
        function clearSwipes() {
            model.swipes = [];
        }


        $window.handleHidSwipe = function handleHidSwipe(primaryAccountNumber) {
			if (primaryAccountNumber) {
				handleCardSwipeSuccess();
			}
        }
        
        $scope.$watch(function(){
            return window.hidSwipeData ? window.hidSwipeData.primaryAccountNumber : null;
        }, handleHidSwipe);  

        function handleHidSwipe(){
            if(window.hidSwipeData && window.hidSwipeData.primaryAccountNumber){            
                handleCardSwipeSuccess();
                window.hidSwipeData.primaryAccountNumber = null;                
            }                
        }
    }

}());
