(function () {
	"use strict";

	angular.module("angular.emlpayments")
		.factory("customerService", customerServiceFactory);

	customerServiceFactory.$inject = ["api", "appConfig",];

	function customerServiceFactory(api, appConfig) {
		var searchCustomerCriteria = null, activateCardCustomer;

		return {
			searchCustomers,
			searchCustomerCards,
			updateCustomer,
			getSearchCustomerCriteria,
			setActivateCardCustomer,
			getActivateCardCustomer,
			mapCustomerForActivation,
			reset
		};

		function setActivateCardCustomer(customer) {
			activateCardCustomer = customer;
		}

		function getActivateCardCustomer() {
			return activateCardCustomer;
		}

		function mapCustomerForActivation() {
			if (!activateCardCustomer) {
				return undefined;
			}

			return {
				firstName: activateCardCustomer.firstName,
				middleName: activateCardCustomer.middleName,
				lastName: activateCardCustomer.lastName,
				phone: activateCardCustomer.phoneNumber,
				phoneExt: activateCardCustomer.phoneNumberExtension,
				fax: activateCardCustomer.faxNumber,
				faxExt: activateCardCustomer.faxNumberExtension,
				email: activateCardCustomer.emailAddress,
				country: angular.copy(activateCardCustomer.selectedCountry),
				address1: activateCardCustomer.address1,
				address2: activateCardCustomer.address2,
				city: activateCardCustomer.city,
				selectedRegion: activateCardCustomer.selectedRegion,
				postalCode: activateCardCustomer.postalCode,
				companyName: activateCardCustomer.name,
				isOrganization: activateCardCustomer.isOrganization,
				marketingOptIn: activateCardCustomer.marketingOptIn,
				identifier: activateCardCustomer.identifier,
				companyRegistrationNumber: activateCardCustomer.companyRegistrationNumber,
				vat: activateCardCustomer.vat,
				birthDate: activateCardCustomer.birthDate,
				kycDocumentType: activateCardCustomer.kycDocumentType,
				kycDocumentId: activateCardCustomer.kycDocumentId,
				kycExpiryDate: activateCardCustomer.kycExpiryDate,
				requireCIP: activateCardCustomer.requireCIP
			};
		}

		function searchCustomers(searchCustomerModel) {

			searchCustomerCriteria = searchCustomerModel;

			return api.customer.searchCustomers(appConfig.selectedMerchantGroup().uniqueTag,
				searchCustomerModel.isOrganization,
				searchCustomerModel.name,
				searchCustomerModel.firstName,
				searchCustomerModel.lastName,
				searchCustomerModel.phoneNumber,
				searchCustomerModel.identifier,
				searchCustomerModel.email,
				searchCustomerModel.kybAssessmentLevel,
				searchCustomerModel.companyRegistrationNumber,
				searchCustomerModel.assessmentFromDate,
				searchCustomerModel.assessmentToDate);
		}

		function searchCustomerCards(isOrganization, customerId, pageNumber, pageSize) {
			return api.customer.searchCustomerCards(isOrganization, customerId, pageNumber, pageSize);
		}

		function updateCustomer(updateModel) {
			return api.customer.updateCustomer(appConfig.selectedMerchantGroup().uniqueTag, updateModel);
		}

		function getSearchCustomerCriteria() {
			return searchCustomerCriteria;
		}

		function reset() {
			searchCustomerCriteria = null;
			activateCardCustomer = undefined;
		}
	}
}());