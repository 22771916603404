(function () {
    "use strict";

    angular.module("angular.emlpayments")
        .factory("orderCardsService", orderCardsServiceFactory);

    orderCardsServiceFactory.$inject = ["api", "appConfig"];

    function orderCardsServiceFactory(api, appConfig) {           

        return {            
            getMerchantGroupContact,
            saveMerchantGroupContact,
            getCardDesigns,
            saveOrderRequest            
        };       

        function getMerchantGroupContact() {
            return api.orderCards.getMerchantGroupContact(appConfig.selectedMerchantGroup().uniqueTag);
        }

        function saveMerchantGroupContact(primaryContact) {
            return api.orderCards.saveMerchantGroupContact(appConfig.selectedMerchantGroup().uniqueTag, primaryContact);
        }

        function getCardDesigns() {
            return api.orderCards.getCardDesigns(appConfig.selectedMerchantGroup().uniqueTag);
        }

        function saveOrderRequest(orderRequest) {
            return api.orderCards.saveOrderRequest(appConfig.selectedMerchantGroup().uniqueTag, orderRequest);
        }
    }
} ());