﻿import htmlTemplate from './confirm-inventory.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
		.config(configConfirmInventory);

    configConfirmInventory.$inject = ["$routeProvider", "NAV_URLS", "CLAIMS"];

    function configConfirmInventory($routeProvider, NAV_URLS, CLAIMS) {

        $routeProvider
			.when(NAV_URLS.CONFIRM_INVENTORY + "/:cardOrderId?", {
          template: htmlTemplate,
			    controller: "ConfirmInventory",
			    controllerAs: "model",
			    requiredClaim: CLAIMS.RECONCILE_CARD_INVENTORY
			});
    }

}());