(function () {
    "use strict"; 

    angular.module("cardspotWeb.services")
        .factory("routeBoss", configFactory);

    configFactory.$inject = ["$rootScope", "$log", "navigate", "authService", "appConfig", "CONTROLLERS", "NAV_URLS"];

    function configFactory($rootScope, $log, navigate, authService, appConfig, CONTROLLERS, NAV_URLS) {
        
        var destination, cancelRoute, displayFeeBasedSignageMessage = true;
        
        return {
            manage: manage
        };

        function manage(settings) {
            if (!settingsValid(settings)) return;

            settings.userIsAuthenticated = authService.isAuthenticated();
            settings.signageConfirmed = authService.signageConfirmed();
            cancelRoute = settings.routeChangeEvent.preventDefault;

            destination = {
                isLogin: isLoginController(settings.route),
                isAppConfig: isAppConfigController(settings.route),
                isEnterSalesController: isEnterSalesController(settings.route),
                isEnterSundries: isEnterSundries(settings.route)
            };
            
            ensureAuthentication(settings);
        }

        function settingsValid(settings) {
            return settings
                && settings.route
                && settings.routeChangeEvent
                && angular.isDefined(settings.nextRouteUrl)
            ;
        }

        function ensureAuthentication(settings) {
            if (!destination.isLogin && !settings.userIsAuthenticated) {
                reroute(navigate.toLogin(settings.nextRouteUrl));
            }
            else if (destination.isLogin && settings.userIsAuthenticated) {
                reroute(navigate.toReferrerOrDefault());
            }
            else if (!destination.isLogin) {
                ensureAppConfigValid(settings);
            }
        }

        function ensureAppConfigValid(settings) {
            if (!destination.isAppConfig && !appConfig.isValid()) {
                reroute(navigate.toApplicationConfiguration(settings.nextRouteUrl));
            }
            else if (!destination.isAppConfig) {
                preventUnauthorizedRoute(settings);
            }
        }

        function preventUnauthorizedRoute(settings) {
            if (settings.route.requiredClaim) {
                if (!authService.hasClaim(settings.route.requiredClaim)) {
                    cancelRoute();
                } else if (destination.isEnterSundries && !$rootScope.hasSundryTypes()) {
                    reroute(navigate.toHome());
                }
            }
        }

        function isLoginController(route) {
            return route.controller === CONTROLLERS.LOGIN;
        }

        function isEnterSalesController(route) {
            return route.controller === CONTROLLERS.ENTER_SALES;
        }
        
        function isEnterSundries(route) {
            return route.originalPath === NAV_URLS.ENTER_SUNDRIES;
        }
        
        function isAppConfigController(route) {
            return route.controller === CONTROLLERS.APPLICATION_CONFIGURATION;
        }

        function reroute(goToNewRoute) {
            cancelRoute();
            if (angular.isFunction(goToNewRoute)) {
                goToNewRoute();
            }
        }        
    }
}());