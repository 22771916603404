﻿(function () {
    "use strict";

    angular.module("cardspotWeb.areas.reconcileInventory")
        .controller("RemoveInventory", RemoveInventory);

    RemoveInventory.$inject = ["$rootScope", "$scope", "inventoryService", "notify", "$routeParams", "authService", "cardService", "$uibModal", "SF_EVENTS", "CARD_ORDER_RECONCILIATION_STATUS_TYPES", "LOOKUP_TYPES"];

    function RemoveInventory($rootScope, $scope, inventoryService, notify, $routeParams, authService, cardService, $uibModal, SF_EVENTS, CARD_ORDER_RECONCILIATION_STATUS_TYPES, LOOKUP_TYPES) {
        var model = this;

        model.LOOKUP_TYPE = LOOKUP_TYPES;
        model.isLookupType = isLookupType;
        model.setLookupType = setLookupType;
        model.lookupCardByPrintText = lookupCardByPrintText;
        model.lookupGroupCardByFirstPrintText = lookupGroupCardByFirstPrintText;
        model.lookupGroupCardByLastPrintText = lookupGroupCardByLastPrintText;
        model.statusReasons = [];
        model.cardsToBeRemoved = [];
        model.canViewRestrictedCardNumbers = authService.canViewRestrictedCardNumbers();
        model.maskPrintText = cardService.maskPrintText;
        model.displayPrintText = displayPrintText;
        model.card = {
            firstPrintText: null,
            lastPrintText: null
        };
        model.inventoryCount = {
            cardOrderId: null,
            firstCardNumber: null,
            lastCardNumber: null,
            location: null,
            cardOrderReconciliationStatusReason: null,
            cardCount: null,
            hasDiscrepancy: false,
            localTime: null,
            cardOrderReconciliationStatusType: CARD_ORDER_RECONCILIATION_STATUS_TYPES.UNUSABLE,
            cards: []
        }

        $scope.$on(SF_EVENTS.CARDSWIPE_SUCCESS, handleCardSwipeSuccess);

        var currentLookupType = model.LOOKUP_TYPE.INDIVIDUAL;

        function setLookupType(type) {
            currentLookupType = type;
            resetGroupLookup();
        }

        function isLookupType(type) {
            return type === currentLookupType;
        }

        init();

        function init() {
            if ($routeParams.cardOrderId) {
                model.cardOrderId = $routeParams.cardOrderId;
                getTodaysUnusableInventoryCount($routeParams.cardOrderId);
            }
            getStatusReasons();
        }

        function resetGroupLookup() {
            model.card.firstPrintText = null;
            model.card.lastPrintText = null;
            model.firstGroupCard = null;
            model.lastGroupCard = null;
            model.firstCardEntered = false;
            model.lastCardEntered = false;
            resetInventoryCountModel();
            $scope.lookupCardForm.$setPristine();
            $scope.lookupGroupCardForm.$setPristine();
            notify.dismissErrorAlert();
        }

        function resetInventoryCountModel() {
            model.inventoryCount.cardOrderId = null;
            model.inventoryCount.firstCardNumber = null;
            model.inventoryCount.lastCardNumber = null;
            model.inventoryCount.location = null;
            model.inventoryCount.cardOrderReconciliationStatusReason = null;
            model.inventoryCount.cardCount = null;
            model.inventoryCount.hasDiscrepancy = false;
            model.inventoryCount.localTime = null;
            model.inventoryCount.cardOrderReconciliationStatusType = CARD_ORDER_RECONCILIATION_STATUS_TYPES.UNUSABLE;
            model.inventoryCount.cards = [];
        }

        function getStatusReasons() {
            notify.forHttp(inventoryService.getCardOrderReconciliationStatusReasons(), {
                    startTranslationId: "MANAGE_CARDS.REMOVE_INVENTORY.NOTIFICATION_MESSAGES.LOADING_STATUS_REASONS" })
                .then(function (data) {
                    model.statusReasons = data.reasons;
                })
        }

        function doLookupInventoryCard(printText, primaryAccountNumber) {
            notify.dismissErrorAlert();

            var lookupInventoryCardFunction = inventoryService.getInventoryLookupCardFunction(printText, primaryAccountNumber, model.cardOrderId);

            notify.forHttp(lookupInventoryCardFunction, { startTranslationId: "LOOKUP_CARD.FORM.PROGRESS_TEXT" })
                .then(function (data) {
                    setRetrievedCard(data);
                }, function (error) {
                    notify.showError(error);
                });
        }

        function setRetrievedCard(data) {
            
            if (!model.firstCardEntered) {
                model.card.firstPrintText = Number(data.printText);
                model.firstGroupCard = data;
                //if (!$scope.lookupGroupCardForm.$valid)
                //    return
                model.firstCardEntered = true;
                if (!isLookupType(model.LOOKUP_TYPE.GROUP) && $scope.lookupGroupCardForm.$valid) {
                    doLookupInventoryGroupCards(model.firstGroupCard.primaryAccountNumber, model.firstGroupCard.primaryAccountNumber);
                    return
                }
            } else if (!model.lastCardEntered) {
                model.card.lastPrintText = Number(data.printText);
                model.lastGroupCard = data;
                if (!$scope.lookupGroupCardForm.$valid)
                    return
                model.lastCardEntered = true;
                // now have the first and last card,
                // so now need to do the lookup Group
                
                doLookupInventoryGroupCards(model.firstGroupCard.primaryAccountNumber, model.lastGroupCard.primaryAccountNumber);
            }
        }

        function lookupCardByPrintText() {
            notify.forFormSubmission($scope.lookupCardForm, doLookupInventoryCard(model.card.firstPrintText));
        }

        function lookupGroupCardByFirstPrintText() {
            doLookupInventoryCard(model.card.firstPrintText);
        }

        function lookupGroupCardByLastPrintText() {
            notify.forFormSubmission($scope.lookupGroupCardForm, doLookupInventoryCard(model.card.lastPrintText));
        }

        function handleCardSwipeSuccess(event, swipe) {
            doLookupInventoryCard(undefined, swipe.primaryAccountNumber);
        }

        function getTodaysUnusableInventoryCount(orderId) {
            notify.forHttp(inventoryService.getTodaysInventoryCount(orderId, true), { startTranslationId: "MANAGE_CARDS.CONFIRM_INVENTORY.NOTIFICATION_MESSAGES.LOADING_TODAYS_INVENTORY_COUNT" })
                .then(data => setTodaysUnusableInventoryCount(data));
        }

        function setTodaysUnusableInventoryCount(data) {
            model.todaysUnusableInventory = data.cardOrderReconciliations;
        }

        function doLookupInventoryGroupCards(firstCardNumber, lastCardNumber) {
            notify.dismissErrorAlert();
            var firstCardSequential = Number(cardService.getSequentialPart(firstCardNumber));
            var lastCardSequential = Number(cardService.getSequentialPart(lastCardNumber));

            if (firstCardSequential > lastCardSequential) {
                notify.showError("MANAGE_CARDS.CONFIRM_INVENTORY.FORM.VALIDATION_TEXT.CARD_GROUP_OUT_OF_SEQUENCE");
                resetGroupLookup();
                return;
            }

            notify.forHttp(inventoryService.getInventoryCardGroup(firstCardNumber, lastCardNumber), { startTranslationId: "LOOKUP_CARD.FORM.PROGRESS_TEXT" })
                .then(function (data) {
                    model.inventoryCount.firstCardNumber = firstCardNumber;
                    model.inventoryCount.lastCardNumber = lastCardNumber;
                    model.inventoryCount.cardCount = data.cards.length;
                    model.cardsWithDiscrepancy = data.cardsWithDiscrepancy;
                    model.inventoryCount.localTime = new Date();
                    model.inventoryCount.cards = data.cards;
                    model.cardsToBeRemoved = data.cards;

                    confirmationModalPopupTrigger()
                });
        }

        function displayPrintText(printText) {
            return model.canViewRestrictedCardNumbers ? printText : model.maskPrintText(printText);
        }

        function confirmationModalPopup() {
            var modalInstance = $uibModal.open({
                templateUrl: "remove-inventory/modal/confirmationDialog.html",
                backdrop: "static",
                keyboard: false,
                controller: "ConfirmModal",
                scope: $scope,
                size: "sm"
            });

            modalInstance.result.then(function (confirmed) {
                if (confirmed) {
                    notify.forHttp(inventoryService.insertInventoryCardOrderReconciliation(model.cardOrderId, model.inventoryCount))
                        .then(function () {
                            getTodaysUnusableInventoryCount(model.cardOrderId)
                            resetGroupLookup();
                        }, function (error) {
                            resetGroupLookup();
                        });
                } else { resetGroupLookup(); }
            });
        }

        function confirmationModalPopupTrigger() {
            confirmationModalPopup();
        }
    }
}());