﻿(function () {
    "use strict";

    angular.module("angular.emlpayments")
        .factory("inventoryService", inventoryServiceFactory);

    inventoryServiceFactory.$inject = ["api", "appConfig", "notify"];

    function inventoryServiceFactory(api, appConfig, notify) {
        return {
            getCardOrderDesigns,
            getTodaysInventoryCount,
            getInventoryCardBySwipe,
            getInventoryCardByEntry,
            getInventoryCardByExternalId,
            getInventoryCardGroup,
            insertInventoryCardOrderReconciliation,
            deleteInventoryCardOrderReconciliation,
            getInventoryLookupCardFunction,
            getCardOrderReconciliationStatusReasons
        };

        function getCardOrderDesigns() {
            return api.inventory.getCardOrderDesigns(appConfig.selectedMerchantGroup().uniqueTag);
        }

        function getTodaysInventoryCount(orderId, isUnusable = false) {
            return api.inventory.getTodaysInventoryCount(orderId, appConfig.selectedMerchantGroup().uniqueTag, isUnusable);
        }

        function getInventoryCardBySwipe(primaryAccountNumber, cardOrderId) {
            return api.inventory.getInventoryCardBySwipe(primaryAccountNumber, cardOrderId);
        }

        function getInventoryCardByEntry(printText, cardOrderId) {
            return api.inventory.getInventoryCardByPrintText(printText, cardOrderId);
        }
        function getInventoryCardByExternalId(externalId, cardOrderId) {
            return api.inventory.getInventoryCardByExternalId(externalId, cardOrderId);
        }
        function getInventoryCardGroup(firstCardNumber, lastCardNumber) {
            return api.inventory.getInventoryCardGroup(firstCardNumber, lastCardNumber);
        }
        function insertInventoryCardOrderReconciliation(cardOrderId, model) {
            model.softwareClientInstanceLocationName = appConfig.getSelectedLocation().location;
            return api.inventory.insertInventoryCardOrderReconciliation(cardOrderId, appConfig.selectedMerchantGroup().uniqueTag, model);
        }
        function deleteInventoryCardOrderReconciliation(cardOrderReconciliationId, firstCardNumber, lastCardNumber) {
            return api.inventory.deleteInventoryCardOrderReconciliation(cardOrderReconciliationId, firstCardNumber, lastCardNumber);
        }
        function getInventoryLookupCardFunction(printText, primaryAccountNumber, cardOrderId) {
            var lookupInventoryCardFunction;
            if (printText) {
                // need to determine if this should be treated as printText or externalId
                // if startsWith alpha, then treat as externalid search
                if (/^[A-Za-z]/.test(printText)) {
                    lookupInventoryCardFunction = getInventoryCardByExternalId(printText, cardOrderId);
                } else {
                    lookupInventoryCardFunction = getInventoryCardByEntry(printText, cardOrderId);
                }
            } else if (primaryAccountNumber) {
                lookupInventoryCardFunction = getInventoryCardBySwipe(primaryAccountNumber, cardOrderId);
            } else {
                notify.showError("LOOKUP_CARD.FORM.INVALID_PRINT_TEXT_OR_SWIPE");
                return;
            }

            return lookupInventoryCardFunction;
        }
        function getCardOrderReconciliationStatusReasons() {
            return api.inventory.getCardOrderReconciliationStatusReasons();
        }
    }
}());