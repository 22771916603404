﻿import UAParser from 'ua-parser-js';

(function () {
    "use strict";

    angular.module("angular.emlpayments")
        .factory("diagnosticService", diagnosticServiceFactory);

    diagnosticServiceFactory.$inject = ["$q", "api", "notify", "store", "STORE_KEYS"];
    
    function diagnosticServiceFactory($q, api, notify, store, STORE_KEYS) {
        var parser = new UAParser();

        return {
            getClientIPAddress,
            getDiagnosticData,
            getClientBrowserInfo,
            getClientDeviceInfo,
            getClientOSInfo,
            getClientEngineInfo
        };

        function getClientIPAddress() {
            return api.diagnostic.getClientIPAddress();
        }

        function getDiagnosticData() {
            return store.get(STORE_KEYS.DIAGNOSTIC_DATA);
        }

        function getClientBrowserInfo() {
            return parser.getBrowser();
        }

        function getClientDeviceInfo() {
            return parser.getDevice();
        }

        function getClientOSInfo() {
            return parser.getOS();
        }

        function getClientEngineInfo() {
            return parser.getEngine();
        }
    }
}());