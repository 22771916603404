import htmlTemplate from './use-of-cards.directive.html';

(function() {
    "use strict";

    angular.module("cardspotWeb.areas.hvtRequest")
        .directive("emlUseOfCards", configDirective);

    function configDirective() {
        return {
            restrict: "E",
            scope: {
                useOfCards:"=",
                useOfCardsComplete:"=",
                previousStep:"="
            },
            controller: UseOfCardsController,
            controllerAs: "model",
            template: htmlTemplate
        };
    }

	UseOfCardsController.$inject = ["$q", "$scope", "$translate", "USE_OF_CARD_OPTIONS"];

	function UseOfCardsController($q, $scope, $translate, USE_OF_CARD_OPTIONS) {
        var model = this;
        
        model.USE_OF_CARD_OPTIONS = [];
        model.focusInput = false;
        model.onOptionChange = onOptionChange;
        model.otherOptionInputRequired = false;
        
        model.isOptionsRequired = function() {
            return !model.USE_OF_CARD_OPTIONS.some(function(options) {
              return options.SELECTED;
            })
        }

        init();

        function init() {
            Object.values(USE_OF_CARD_OPTIONS).forEach((option) => {
                model.USE_OF_CARD_OPTIONS.push(option);
            })
        }

        function onOptionChange(option) {
            if (option.SELECTED && option.NAME === USE_OF_CARD_OPTIONS.OTHER.NAME) {
                model.focusInput = true;
            } else { model.focusInput = false; }

            model.otherOptionInputRequired = otherOptionInputRequired();

            model.isOptionsRequired();

            $scope.useOfCards.selectedOptions = angular.copy(model.USE_OF_CARD_OPTIONS.filter(o => o.SELECTED).map(m => m.NAME));
        }

        function otherOptionInputRequired() {
            return model.USE_OF_CARD_OPTIONS.some(function(option) {
                return option.SELECTED && option.NAME === USE_OF_CARD_OPTIONS.OTHER.NAME;
            })
        }
    }
}());