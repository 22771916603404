import moment from 'moment';

(function () {
    "use strict";

    angular.module("angular.emlpayments")
        .directive("emlValidDatePickerValue", validDatePickerValueDirective);  
        
    validDatePickerValueDirective.$inject = ["$parse"];

    function validDatePickerValueDirective($parse) {

        return {
            restrict: 'A',
            require: 'ngModel',
            link: link            
        }
    
        function link(scope, element, attrs, ngModel) {

            ngModel.$validators.invalidDate = function (modelValue, viewValue) {
                if (!modelValue) {
                    return true;
                }

                var datepickerOptions = $parse(attrs.datepickerOptions)(scope);
                
                if (!datepickerOptions) {
                    return true;
                }

                if (datepickerOptions.minDate || datepickerOptions.maxDate) {                        
                    var modelValueMoment = moment(modelValue);
                    
                    if (datepickerOptions.minDate) {
                        var minDateMoment = moment(datepickerOptions.minDate);
                        if (modelValueMoment.isBefore(minDateMoment, 'day')) {
                            return false;
                        }
                    }
                    
                    if (datepickerOptions.maxDate) {
                        var maxDateMoment = moment(datepickerOptions.maxDate);
                        if (modelValueMoment.isAfter(maxDateMoment, 'day')) {
                            return false;
                        }
                    }
                }          

                return true;
            };
        }
    }
}());