﻿import htmlTemplate from './lookup-customer.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
        .config(configLookupCustomer);

    configLookupCustomer.$inject = ["$routeProvider", "NAV_URLS", "CLAIMS"];

    function configLookupCustomer($routeProvider, NAV_URLS, CLAIMS) {

        $routeProvider
            .when(NAV_URLS.LOOKUP_CUSTOMER, {
                template: htmlTemplate,
                controller: "LookupCustomer",
                controllerAs: "model",
                requiredClaim: CLAIMS.SEARCH_CUSTOMER
            });

    }
}());