import _ from 'underscore';

(function () {
    "use strict";

    angular.module("angular.emlpayments")
        .factory("sundryService", sundryServiceFactory);

    sundryServiceFactory.$inject = ["$q", "$log", "api", "notify"];

    function sundryServiceFactory($q, $log, api, notify) {
        var sundries = [],            
            errors = [];

        return {            
            getSundries: getSundries,
            hasSundries: hasSundries,  
            addSundry: addSundry,                                  
            removeSundry: removeSundry,                        
            getCurrencySymbol: getCurrencySymbol,
            reset: reset,
            hasErrors: hasErrors,
            errors: getErrors,
            getSundryTypes: getSundryTypes,
            getSundryCurrencies: getSundryCurrencies,
            getAllSundryItemTypes: getAllSundryItemTypes,
            getActiveSundryItemTypes: getActiveSundryItemTypes,
            getInactiveSundryItemTypes: getInactiveSundryItemTypes,
            addSundryItemType: addSundryItemType,
            updateSundryItemType: updateSundryItemType
        };
        
        function addSundry(sundry){
            sundries.unshift(sundry);
        }

        function getCurrencySymbol() {
            return hasSundries() ? sundries[0].currency.currencySymbol : "";
        }

        function hasSundries() {
            return angular.isArray(sundries) && sundries.length > 0;
        }

        function removeSundry(sundryItemTypeId) {
            
            angular.copy(_.reject(sundries, function (sundryItem) {
                return sundryItemTypeId === sundryItem.sundryItemTypeId;
            }), sundries);
                
            if (!hasSundries()) {
                reset();
            }
        }

        function resetErrors() {
            errors = [];
        }

        function reset() {
            sundries = angular.copy([], sundries);
            resetErrors();            
        }               

        function getSundries() {
            return sundries;
        }       

        function hasErrors() {
            return angular.isArray(errors) && errors.length > 0;
        }

        function getErrors() {
            return errors;
        }                               
        
        function getSundryTypes(merchantGroupUniqueTag) {
            
            var deferred = $q.defer();
                        
            notify.forHttp(api.sundries.getSundryItemTypes(merchantGroupUniqueTag), { startTranslationId: "ENTER_SUNDRIES.GET_SUNDRY_TYPES_PROGRESS_TEXT" })
            .then(function(data){                   
                deferred.resolve(data);
            }, function(error){
                deferred.reject(error);
            });			
			
            return deferred.promise;                        
        }

        function getSundryCurrencies(merchantGroupUniqueTag) {
            return api.sundries.getSundryCurrencies(merchantGroupUniqueTag);
        }

        function getAllSundryItemTypes(merchantGroupUniqueTag) {
            return api.sundries.getAllSundryItemTypes(merchantGroupUniqueTag);
        }

        function getActiveSundryItemTypes(merchantGroupUniqueTag, pageNumber = 1, pageSize = 10, sortAscending = true, isActive = true) {
            return api.sundries.getPagedSundryItemTypes(merchantGroupUniqueTag,
                pageNumber,
                pageSize,
                sortAscending,
                isActive);
        }

        function getInactiveSundryItemTypes(merchantGroupUniqueTag, pageNumber = 1, pageSize = 10, sortAscending = true, isActive = false) {
            return api.sundries.getPagedSundryItemTypes(merchantGroupUniqueTag,
                pageNumber,
                pageSize,
                sortAscending,
                isActive);
        }

        function addSundryItemType(merchantGroupUniqueTag, model) {
            var model = {
                Description: model.type,
                UnitPrice: model.unitPrice,
                CurrencyNumericCode: model.currency.numericCode,
                Active: model.status
            }

            return api.sundries.addMerchantGroupSundry(merchantGroupUniqueTag, model);
        }

        function updateSundryItemType(rowData) {
            var model = {
                Description: rowData.description,
                UnitPrice: rowData.unitPrice,
                CurrencyNumericCode: rowData.currencyNumericCode,
                Active: rowData.active,
                MerchantGroupUniqueTag: rowData.merchantGroupUniqueTag
            }

            return api.sundries.updateMerchantGroupSundry(rowData.sundryItemTypeId, model);
        }
    }
} ());