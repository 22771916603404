(function () {
    "use strict";

    angular.module("cardspotWeb.areas.receipts")
        .controller("Receipts", Receipts);

    Receipts.$inject = ["$scope", "$sce", "$document", "authService", "$translate", "notify", "receipts", "settings", "appConfig", "REG_EX"];

    function Receipts($scope, $sce, $document, authService, $translate, notify, receipts, settings, appConfig, REG_EX) {
        var model = this;
        var urlReceiptItems = ["MerchantGroupUrl"];

        model.selectedCulture = undefined;
        model.getReceiptItems = getReceiptItems;
        model.isCultureSelected = isCultureSelected;
        model.canManageReceiptTermsAndConditions = authService.canManageReceiptTermsAndConditions();
        model.setEditMode = setEditMode;
        model.showCustomEdit = false;
        model.getRows = getRows;
        model.updateSampleReceipt = updateSampleReceipt;
        model.isEditable = isEditable;
        model.saveReceiptProperties = saveReceiptProperties;
        model.isReceiptItemRemoved = isReceiptItemRemoved;
        model.reset = reset;
        model.receiptGroupingThreshold = undefined;
        $scope.defaultReceiptItems = {};
        $scope.defaultSample = {};
        $scope.defaultTermsAndConditions = {};

        init();

        function init() {
            getSupportedCultures();
            getReceiptGroupingThreshold();
        }

        function getSupportedCultures() {
            notify.forHttp(receipts.getReceiptCultures(), { startTranslationId: "TOOLS.RECEIPT_PROPERTIES.GET_RECEIPT_CULTURES_PROGRESS" })
                .then(data => setSupportedCultures(data));
        }

        function setSupportedCultures(data) {
            model.supportedCultures = data.supportedCultures;
            sortSupportedCultures();
            applyTranslations();

            var matchingCulture = model.supportedCultures.filter(c => c.cultureName.toUpperCase() === $translate.currentLanguage().key.toUpperCase());

            if (matchingCulture) {
                model.selectedCulture = matchingCulture[0].cultureName;
                getReceiptItems();
            }
        }

        function compareCulture(a, b) {
            var display1 = a.displayName.toUpperCase();
            var display2 = b.displayName.toUpperCase();

            if (display1 > display2)
                return 1;
            else if (display1 < display2)
                return -1;

            return 0;
        }

        function sortSupportedCultures() {
            // translate to US for sorting purposes - this is uniform with how the culture selection is displayed
            // we are getting the display values from the server, which are determined using the .NET cultureInfo class display name
            // those names aren't consistent with the display names we use in this app, but we have the culture names as translations IDs to make them uniform

            for (var i = 0; i < model.supportedCultures.length; i++) {
                var culture = model.supportedCultures[i];
                var translationId = 'CULTURES.' + culture.cultureName.toUpperCase().replace('-', '_');
                culture.displayName = $translate.instant(translationId, {}, undefined, 'en-us');
            }

            model.supportedCultures.sort(compareCulture);
        }

        function applyTranslations() {
            var currentKey = $translate.use();

            if (currentKey.toUpperCase() === 'EN-US')
                return; // we sorted using english culture, no need to translate again

            // apply display name translations for currently selected culture
            for (var i = 0; i < model.supportedCultures.length; i++) {
                var culture = model.supportedCultures[i];
                var translationId = 'CULTURES.' + culture.cultureName.toUpperCase().replace('-', '_');
                culture.displayName = $translate.instant(translationId);
            }
        }

        function isCultureSelected() {
            return model.selectedCulture !== undefined && model.selectedCulture !== "" && model.selectedCulture !== {};
        }

        function getReceiptItems() {
            if (isCultureSelected()) {
                notify.forHttp(receipts.getReceiptItems(model.selectedCulture), { startTranslationId: "TOOLS.RECEIPT_PROPERTIES.GET_RECEIPT_PROPERTIES_PROGRESS" })
                    .then(data => setReceiptData(data));
            }
        }

        function setReceiptData(data) {

            model.receiptHtml = $sce.trustAsHtml(data.sampleReceipt);
            $scope.defaultSample = angular.copy(model.receiptHtml);

            //This will be temporary until we can remove the unused items from the DB. 
            model.receiptItems = data.receiptItems.filter((item) => item.uniqueTag != 'SupportPhone' && item.uniqueTag != 'SupportUrl');
            $scope.defaultReceiptItems = angular.copy(model.receiptItems);

            model.termsAndConditions = data.receiptTermsAndConditions;
            $scope.defaultTermsAndConditions = angular.copy(model.termsAndConditions);

            model.showCustomEdit = false;
        }

        function getReceiptGroupingThreshold() {
            settings.getSettings()
            .then(response => assignReceiptGroupingThreshold(response));
        }

        function assignReceiptGroupingThreshold(response) {
            appConfig.selectedMerchantGroup().receiptGroupingThreshold = response.data.receiptGroupingThreshold;
            
            model.master = {
                receiptGroupingThreshold: response.data.receiptGroupingThreshold
            };

            model.receiptGroupingThreshold = response.data.receiptGroupingThreshold;
        }

        function setEditMode() {
            model.showCustomEdit = true;
        }

        function isEditable() {
            if (!model.showCustomEdit) {
                return false;
            }

            return true;
        }

        function getRows(item) {
            var defaultLength = item.defaultValue ? item.defaultValue.length : 1;
            if (defaultLength <= 30) {
                return 1;
            }
            return Math.ceil(defaultLength / 30);
        }

        function updateSampleReceipt(item) {

            let el = getElement("#" + item.uniqueTag);

            if (el && el.length > 0) {
                el[0].textContent = item.customValue && item.customValue.length > 0 ? item.customValue : item.defaultValue;
            }
        }

        function getElement(tag) {
            return angular.element($(tag));
        }

        function reset() {
            angular.copy($scope.defaultReceiptItems, model.receiptItems);
            angular.copy($scope.defaultSample, model.receiptHtml);
            angular.copy($scope.defaultTermsAndConditions, model.termsAndConditions);
            model.receiptItems.forEach((item) => updateSampleReceipt(item));
            model.termsAndConditions.forEach((item) => updateSampleReceipt(item));
            model.receiptGroupingThreshold = model.master.receiptGroupingThreshold;
            model.showCustomEdit = false;
            notify.dismissErrorAlert();
        }

        function saveReceiptProperties() {
            if (!validateReceiptGroupingThreshold(model.receiptGroupingThreshold)) { return }

            let filteredReceiptItems = model.receiptItems
                .filter(item => item.customValue && item.customValue.length > 0)
                .map(item => { return { uniqueTag: item.uniqueTag, customValue: item.customValue };});

            if (!validateUrlItems(filteredReceiptItems)) {return}

            let filteredTermsAndConditions = model.termsAndConditions
                .filter(item => item.customValue && item.customValue.length > 0 && model.canManageReceiptTermsAndConditions)
                .map(item => { return { uniqueTag: item.uniqueTag, customValue: item.customValue };});

            settings.setSettings(null, null, null, null, model.receiptGroupingThreshold, null, null, null, null);
            model.master.receiptGroupingThreshold = model.receiptGroupingThreshold;

            notify.forHttp(receipts.setReceiptItems(model.selectedCulture, filteredReceiptItems, filteredTermsAndConditions), { startTranslationId: "TOOLS.RECEIPT_PROPERTIES.UPDATE_RECEIPT_PROPERTIES_PROGRESS" })
                .then(() => getReceiptItems());

        }

        function isReceiptItemRemoved(item) {
            return (hasNoDefaultValue(item) || userHasRemovedReceiptItem(item)) && (userHasRemovedReceiptItem(item) ||
                (!item.customValue || item.customValue.length === 0));
        }

        function hasNoDefaultValue(item) {
            return !item.defaultValue || item.defaultValue.length === 0;
        }

        function userHasRemovedReceiptItem(item) {
            return item.customValue && item.customValue.length > 0 && item.customValue.trim().length === 0;
        }

        function validateReceiptGroupingThreshold(threshold) {
            if (!REG_EX.NUMBERS_ONLY.test(threshold)) {
                notify.showError("TOOLS.RECEIPT_PROPERTIES.VALIDATION.GROUPING_THRESHOLD_INVALID");
                window.scrollTo(0, 0);
                return false;
            }

            if (parseInt(threshold) <= 1) {
                notify.showError("TOOLS.RECEIPT_PROPERTIES.VALIDATION.GROUPING_THRESHOLD_GREATER_THAN");
                window.scrollTo(0, 0);
                return false;
            }            

            return true;
        }

        function validateUrlItems(receiptItems) {
            var items = receiptItems.filter(item => urlReceiptItems.includes(item.uniqueTag));
            if (items.length < 1){ return true; }

            for (let i = 0; i < items.length; i++){
                return validateUrl(items[i]);
            }            
        }

        function validateUrl(receiptItem) {            
            try {
                new URL(receiptItem.customValue)
                return true;
            } catch (err) {
                notify.showError("TOOLS.RECEIPT_PROPERTIES.VALIDATION.INVALID_URL");
                return false;
            }
        }
    }
} ());