import htmlTemplate from './receipt.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
        .config(configTools);

    configTools.$inject = ["$routeProvider", "NAV_URLS"];

    function configTools($routeProvider, NAV_URLS) {

        $routeProvider
            .when(NAV_URLS.MANAGE_RECEIPTS, {
                template: htmlTemplate,
                controller: "Receipts",
                controllerAs: "model"
            });
    }

}());