(function () {
    "use strict";

    angular.module("angular.emlpayments")
        .factory("integratedPaymentService", integratedPaymentService);

    integratedPaymentService.$inject = ["$rootScope","api","appConfig"];

    function integratedPaymentService($rootScope, api, appConfig) {

        if(typeof CefSharp != "undefined")
            CefSharp.BindObjectAsync("posTerminal");

        return {
            integratedPaymentsEnabled,
            processPayment,
            processRefund,
            processReversal,
            logIntegratedPayment
        };

        function integratedPaymentsEnabled(){
            return $rootScope.integratedPaymentsEnabled() && appConfig.selectedMerchantGroup().supportsTerminalPayments;
        }

        function processPayment(payment){
            posTerminal.processPayment(payment.amount);
            logIntegratedPayment('payment',payment.paymentType.displayName, payment.amount,false,undefined,'payment sent to terminal');
        }

        function processRefund(payment){
            posTerminal.processRefund(payment.amount);
            logIntegratedPayment('refund',payment.paymentType.displayName, payment.amount,false,undefined,'refund sent to terminal');
        }

        function processReversal(payment){
            posTerminal.processReversal(payment.externalAuthorizationTransactionId);
            logIntegratedPayment('reversal',payment.paymentType.displayName, payment.amount,false,undefined,'reversal sent to terminal');
        }

        function logIntegratedPayment(paymentTransactionType, paymentType, amount, complete, success, message){
            api.diagnostic.logIntegratedPayment({
                paymentTransactionType: paymentTransactionType,
                paymentType: paymentType,
                amount: amount,
                complete: complete,
                success: success,
                message: message
            })
        }
    }
}());