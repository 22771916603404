(function () {
    "use strict";

    angular.module("angular.emlpayments")
        .directive("emlCurrencyValueInput", numericInput);

    function numericInput() {

        return {
            restrict: 'A',
            require: 'ngModel',
            link: link
        }
    };

    function link(scope, element, attrs, ctrl) {

        ctrl.$parsers.push(formatModelValue);
        ctrl.$validators.min = createRangeValidator('min');
        ctrl.$validators.max = createRangeValidator('max');

        ctrl.$validators.number = function (modelValue, viewValue) {
            if (!modelValue)
                return true;

            // replace trailing comma, or period
            // we don't want to flag this as invalid if the user is still typing
            // also the model formatter removes trailing commas and periods
            modelValue = modelValue.toString().replace(/[,.]$/, "");

            var pattern = /^[-+]?[0-9\s,]*[.,]?[0-9]+$/;
            var match = pattern.exec(modelValue);

            return match !== null;
        }
        
        element.on("keydown", function (e, h) {
            if (!e.key)
                return;

            // cross browser standard key codes: http://www.javascripter.net/faq/keycodes.htm

            // IE has an e.key value for number pad period of "Decimal"... so we're checking key code here instead
            var isNumberPadPeriod = $.inArray(e.keyCode === 110);

            // space, backspace, delete, tab, escape, enter
            var isAllowedNonTextKey = $.inArray(e.keyCode, [32, 8, 46, 9, 27, 13]) !== -1;

            // support ctrl/cmd + a, ctrl/cmd + c, ctrl/cmd + v, ctrl/cmd + x
            var isSupportedCommand = (
                (e.ctrlKey === true || e.metaKey === true) && 
                $.inArray(e.keyCode, [65, 67, 86, 88]) !== -1
            );
            
            // end, home, left, up, right, and down
            var isPositionalKey = (e.keyCode >= 35 && e.keyCode <= 40);

            if (isAllowedNonTextKey || isSupportedCommand || isPositionalKey || isNumberPadPeriod) {
                return;
            }

            var regex = '^[0-9,.-]*$';
            var match = e.key.match(regex);

            // block any non number
            if (!match) {
                e.preventDefault();
            }
        });

        function createRangeValidator(attributeName) {
            return function(modelValue, viewValue) {
                var value = element[0].getAttribute(attributeName);

                if (value === null || !angular.isNumber(+value))
                    return true;

                return attributeName.indexOf('min') !== -1 ? 
                    modelValue >= +value :
                    modelValue <= +value;
            }
        }

        function formatModelValue(viewValue) {
            if (!viewValue)
                return viewValue;

            // replace any white space
            // international separators can be spaces, but we don't want to persist those
            var modelValue = viewValue.replace(/\s+/g, '');

            // replace any alphabetical characters
            modelValue = modelValue.replace(/[a-zA-Z]*/, '');

            // replace last separator with period
            modelValue = modelValue.replace(/[,][0-9]{0,2}$/, function (m) {
                var remainingCharacters = m.substring(1);
                return '.' + remainingCharacters;
            });

            // replace trailing comma, or period
            modelValue = modelValue.replace(/[,.]$/, "");

            // replace all remaining commas with empty spaces
            modelValue = modelValue.replace(/,/, "");

            return +modelValue;
        }
    };
}());