(function() {
    "use strict";

    angular.module("cardspotWeb.areas.hvtRequest")
        .controller("HvtRequest", HvtRequest);

        HvtRequest.$inject = ["$scope", "$rootScope", "$timeout", "SF_EVENTS","notify", "HVT_VIEW_NAMES", "lookupValues", "limitService", "hvtRequestService", "preActivationService"];

    function HvtRequest($scope, $rootScope, $timeout, SF_EVENTS, notify, HVT_VIEW_NAMES, lookupValues, limitService, hvtRequestService, preActivationService) {
        var model = this;
        model.HVT_VIEW_NAMES = HVT_VIEW_NAMES;
        model.activeView = model.HVT_VIEW_NAMES.PROGRAM;
        model.countries;
        model.kycDocumentTypes;
        model.programHvtLimits;
        model.programUniqueTag;
        model.programName;
        model.hvtMinimumLimit;
        model.hvtInternalApprovalLimit;
        model.hvtBankApprovalLimit;
        model.cardMaximumExemptionMinimumLimit;
        model.cardMaximumExemptionMaximumLimit;
        model.merchantGroupCountry;
        model.purchaserInit = purchaserInit;
        model.companyInit = companyInit;

        model.purchaserInformation = {
            firstName: null,
            lastName: null,
            address1: null,
            address2: null,
            city: null,
            state: null,
            province: null,
            country: null,
            email: null,
            phone: null,
            phoneExt: null,
            postalCode: null,
            birthDate: null,
            kycDocumentType: null,
            kycDocumentId: null,
            kycExpiryDate: null
        };

        model.companyInformation = {
            companyName: null,
            address1: null,
            address2: null,
            city: null,
            state: null,
            province: null,
            country: null,
            tradingName: null,
            taxId: null,
            organizationState: null,
            occupation: null
        }

        model.useOfCards = {
            otherDescription: null,
            selectedOptions: []
        }

        model.transactionInformation = {
            totalTransactionValue: null,
            numberOfCards: null,
        }

        model.viewVisible = function (viewName) {
            return viewName === model.activeView;
        };

        model.changeActiveView = function (view) {
            model.activeView = view;
        };

        $rootScope.$on(SF_EVENTS.PROGRAM_SELECTED, handleProgramSelectedChange);

        init();

        function init() {
            lookupValues.getCountries()
                .then(function (data) {
                    model.countries = data;
                });
            
            lookupValues.getKYCDocumentTypes()
                .then(resp => {
                    model.kycDocumentTypes = resp.data;
                });

            model.merchantGroupCountry = preActivationService.getMerchantGroupCountry();
        }

        function purchaserInit() {
            $rootScope.$broadcast(SF_EVENTS.HVT_REQUEST_PURCHASER_STEP_SELECTED);
        }

        function companyInit() {
            $rootScope.$broadcast(SF_EVENTS.HVT_REQUEST_COMPANY_STEP_SELECTED);
        }

        function purchaserInformationComplete() {
            hvtRequestService.addPurchaserInformation(model.purchaserInformation);
            model.changeActiveView(HVT_VIEW_NAMES.COMPANY_INFORMATION);
        }

        $scope.purchaserComplete = function () {
            purchaserInformationComplete();
        };

        function companyInformationComplete() {
            hvtRequestService.addCompanyInformation(model.companyInformation);
            model.changeActiveView(HVT_VIEW_NAMES.USE_OF_CARDS);
        }

        $scope.companyComplete = function () {
            companyInformationComplete();
        }

        function useOfCardsComplete() {
            hvtRequestService.addUseOfCards(model.useOfCards);
            
            notify.forHttp(hvtRequestService.submitHvtRequest(
                model.programUniqueTag, 
                model.purchaserInformation, 
                model.companyInformation, 
                model.useOfCards, 
                model.transactionInformation), {
                    startTranslationId: "HVT_REQUEST.FROM.MESSAGES.HVT_REQUEST_SUBMIT_PROGRESS"
                })            
            .then((resp) => {
                if (resp.succeeded) {
                    resetModels();
                    model.changeActiveView(HVT_VIEW_NAMES.PROGRAM);
                    notify.success("HVT_REQUEST.FORM.MESSAGES.HVT_REQUEST_SUCCESS");
                    $rootScope.$broadcast(SF_EVENTS.HVT_REQUEST_SUCCEEDED);
                } else {
                    notify.showError(resp.message);
                }
            });
        }

        $scope.cardUseComplete = function () {
            useOfCardsComplete();
        }

        function transactionInformationComplete() {
            hvtRequestService.addTransactionInformation(model.transactionInformation);
            model.changeActiveView(HVT_VIEW_NAMES.PURCHASER_INFORMATION);
        }

        $scope.transactionInfoComplete = function () {
            transactionInformationComplete();
        }
        
        function programSelectionComplete() {
            model.changeActiveView(HVT_VIEW_NAMES.TRANSACTION_INFORMATION);
        }

        $scope.programComplete = function() {
            programSelectionComplete();
        }

        function previousStep() {
            switch(model.activeView) {
                case HVT_VIEW_NAMES.TRANSACTION_INFORMATION:
                    model.changeActiveView(HVT_VIEW_NAMES.PROGRAM);
                    break;
                case HVT_VIEW_NAMES.PURCHASER_INFORMATION:
                    model.changeActiveView(HVT_VIEW_NAMES.TRANSACTION_INFORMATION);
                    break;
                case HVT_VIEW_NAMES.COMPANY_INFORMATION:
                    model.changeActiveView(HVT_VIEW_NAMES.PURCHASER_INFORMATION);
                    break;
                case HVT_VIEW_NAMES.USE_OF_CARDS:
                    $rootScope.$emit(SF_EVENTS.HVT_REQUEST_COMPANY_STEP_SELECTED);
                    model.changeActiveView(HVT_VIEW_NAMES.COMPANY_INFORMATION);
                    break;
                default:
                    return;
            }
        }

        $scope.previousStep = function() {
            previousStep();
        }

        function handleProgramSelectedChange(event, program) {
            model.programUniqueTag = program.programUniqueTag;
            model.programName = program.programName;
            model.transactionInformation.cardProgramName = program.programName;

            limitService.getProgramHvtLimits(program.programUniqueTag)
            .then(function (resp) {
                handleProgramHvtLimitsResponse(resp);
            })
        }

        function handleProgramHvtLimitsResponse(resp) {
            model.programHvtLimits = resp;
            model.hvtMinimumLimit = limitService.getHVTMinimumLimit().limit;
            model.hvtInternalApprovalLimit = limitService.getHVTInternalApprovalLimit().limit;
            model.hvtBankApprovalLimit = limitService.getHVTBankApprovalLimit().limit;
            model.cardMaximumExemptionMinimumLimit = limitService.getCardMaximumExemptionMinimumLimit().limit;
            model.cardMaximumExemptionMaximumLimit = limitService.getCardMaximumExemptionMaximumLimit().limit;
        }

        function resetModels() {
            model.purchaserInformation = {
                firstName: null,
                lastName: null,
                address1: null,
                address2: null,
                city: null,
                state: null,
                province: null,
                country: null,
                email: null,
                phone: null,
                phoneExt: null,
                postalCode: null,
                birthDate: null,
                kycDocumentType: null,
                kycDocumentId: null,
                kycExpiryDate: null
            };
    
            model.companyInformation = {
                companyName: null,
                address1: null,
                address2: null,
                city: null,
                state: null,
                province: null,
                country: null,
                tradingName: null,
                taxId: null,
                organizationState: null,
                occupation: null
            }
    
            model.useOfCards = {
                otherDescription: null,
                selectedOptions: []
            }
    
            model.transactionInformation = {
                totalTransactionValue: null,
                numberOfCards: null,
            }

            model.programUniqueTag = null;
            model.programName = null;

            hvtRequestService.reset();
        }
    }

}());
