﻿(function () {
    "use strict";

    angular.module("angular.emlpayments")
		.factory("merchantGroupLocationsService", merchantGroupLocationsServiceFactory);

    merchantGroupLocationsServiceFactory.$inject = ["api", "appConfig"];

    function merchantGroupLocationsServiceFactory(api, appConfig) {
        return {
            getAllLocations,
            getActiveLocations,
            getInactiveLocations,
            addLocation,
            updateLocation
        }

        function getAllLocations() {
            var promise = api.configuration.getMerchantGroupLocations(appConfig.selectedMerchantGroup().uniqueTag);
            return promise;
        }

        function getActiveLocations(pageNumber = 1, pageSize = 10, sortAscending = true, isActive = true) {
            var promise = api.location.getLocations(appConfig.selectedMerchantGroup().uniqueTag, pageNumber, pageSize, sortAscending, isActive);
            return promise;
        }

        function getInactiveLocations(pageNumber = 1, pageSize = 10, sortAscending = true, isActive = false) {
            var promise = api.location.getLocations(appConfig.selectedMerchantGroup().uniqueTag, pageNumber, pageSize, sortAscending, isActive);
            return promise;
        }

        function addLocation(location) {
            var promise = api.configuration.addMerchantGroupLocation(appConfig.selectedMerchantGroup().uniqueTag, location);
            return promise;
        }

        function updateLocation(rowData) {
            var model = {
                locationId: rowData.locationId,
                merchantGroupId: rowData.merchantGroupId,
                isActive: rowData.isActive
            };

            var promise = api.location.updateLocation(model);
            return promise;
        }
    }
}());