﻿(function() {
    "use strict";

    angular.module("angular.emlpayments")
        .directive("emlRequireClaim", requireClaim);

    requireClaim.$inject = ["authService"];

    function requireClaim(authService) {
        return function(scope, element, attrs) {
            var claims = attrs.emlRequireClaim.split(',');
            if (!authService.hasAnyClaim(claims)) {
                element.hide();
            }

        };
    }

}());