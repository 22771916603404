﻿(function () {
    "use strict";

    angular.module("cardspotWeb.areas.reconcileInventory")
        .controller("ConfirmInventory", ConfirmInventory);

    ConfirmInventory.$inject = ["$rootScope", "$scope", "inventoryService", "notify", "$routeParams", "authService", "cardService", "$uibModal", "SF_EVENTS"];

    function ConfirmInventory($rootScope, $scope, inventoryService, notify, $routeParams, authService, cardService, $uibModal, SF_EVENTS) {
        var model = this;

        model.cardOrderId = null;
        model.todaysInventory = null;
        model.lookupGroupCardByFirstPrintText = lookupGroupCardByFirstPrintText;
        model.lookupGroupCardByLastPrintText = lookupGroupCardByLastPrintText;
        model.resetGroupLookup = resetGroupLookup;
        model.canViewRestrictedCardNumbers = authService.canViewRestrictedCardNumbers();
        model.displayPrintText = displayPrintText;
        model.cardsWithDiscrepancy = [];
        model.card = {
            firstPrintText: null,
            lastPrintText: null
        };
        model.inventoryCount = {
            cardOrderId: null,
            firstCardNumber: null,
            lastCardNumber: null,
            location: null,
            cardCount: null,
            hasDiscrepancy: false,
            localTime: null,
            cards: []
        }

        $scope.$on(SF_EVENTS.CARDSWIPE_SUCCESS, handleCardSwipeSuccess);

        init();

        function init() {
            if ($routeParams.cardOrderId) {
                model.cardOrderId = $routeParams.cardOrderId;
                getTodaysInventoryCount($routeParams.cardOrderId);
            }
        }

        function resetGroupLookup() {
            model.card.firstPrintText = null;
            model.card.lastPrintText = null;
            model.card.groupCardCount = null;
            model.firstGroupCard = null;
            model.lastGroupCard = null;
            model.firstCardEntered = false;
            model.lastCardEntered = false;
            resetInventoryCountModel();
            $scope.confirmInventoryForm.$setPristine();
            notify.dismissErrorAlert();
        }

        function resetInventoryCountModel() {
            model.inventoryCount.cardOrderId = null;
            model.inventoryCount.firstCardNumber = null;
            model.inventoryCount.lastCardNumber = null;
            model.inventoryCount.location = null;
            model.inventoryCount.cardCount = null;
            model.inventoryCount.hasDiscrepancy = false;
            model.inventoryCount.localTime = null;
            model.inventoryCount.softwareClientInstanceName = null;
            model.inventoryCount.cards = [];
        }

        function doLookupInventoryCard(printText, primaryAccountNumber) {
            notify.dismissErrorAlert();

            var lookupInventoryCardFunction = inventoryService.getInventoryLookupCardFunction(printText, primaryAccountNumber, model.cardOrderId);
            
            notify.forHttp(lookupInventoryCardFunction, { startTranslationId: "LOOKUP_CARD.FORM.PROGRESS_TEXT" })
                .then(function (data) {
                    if (primaryAccountNumber) {
                        setCardRetrievedBySwipe(data)
                    }
                    else {
                        setRetrievedCard(data);
                    }
                }, function (error) {
                    notify.showError(error);
                });
        }

        function setRetrievedCard(data) {
            if (!model.firstCardEntered) {
                model.card.firstPrintText = Number(data.printText);
                model.firstGroupCard = data;
                model.firstCardEntered = true;
            } else if (!model.lastCardEntered) {
                model.card.lastPrintText = Number(data.printText);
                model.lastGroupCard = data;
                model.lastCardEntered = true;
                // now have the first and last card,
                // so now need to do the lookup Group
            }
            if (model.firstCardEntered && model.lastCardEntered)
                doLookupInventoryGroupCards(model.firstGroupCard.primaryAccountNumber, model.lastGroupCard.primaryAccountNumber);
        }

        function setCardRetrievedBySwipe(data) {
            if (!model.firstCardEntered) {
                model.card.firstPrintText = Number(data.printText);
                model.firstGroupCard = data;
                model.firstCardEntered = true;
                return
            } else if (!model.lastCardEntered) {
                model.card.lastPrintText = Number(data.printText);
                model.lastGroupCard = data;
                model.lastCardEntered = true;
                return
                // now have the first and last card,
                // so now need to do the lookup Group

            }
            doLookupInventoryGroupCards(model.firstGroupCard.primaryAccountNumber, model.lastGroupCard.primaryAccountNumber);
        }

        function doLookupInventoryGroupCards(firstCardNumber, lastCardNumber) {
            notify.dismissErrorAlert();
            var firstCardSequential = Number(cardService.getSequentialPart(firstCardNumber));
            var lastCardSequential = Number(cardService.getSequentialPart(lastCardNumber));

            if (firstCardSequential > lastCardSequential) {
                notify.showError("MANAGE_CARDS.CONFIRM_INVENTORY.FORM.VALIDATION_TEXT.CARD_GROUP_OUT_OF_SEQUENCE");
                resetGroupLookup();
                return;
            }

            notify.forHttp(inventoryService.getInventoryCardGroup(firstCardNumber, lastCardNumber), { startTranslationId: "LOOKUP_CARD.FORM.PROGRESS_TEXT" })
                .then(function (data) {
                    model.inventoryCount.firstCardNumber = firstCardNumber;
                    model.inventoryCount.lastCardNumber = lastCardNumber;
                    model.inventoryCount.cardCount = data.cards.length - data.cardsWithDiscrepancy.length;
                    model.cardsWithDiscrepancy = data.cardsWithDiscrepancy;
                    model.inventoryCount.localTime = new Date();
                    model.inventoryCount.cards = data.cards;

                    if (data.cardsWithDiscrepancy.length < 1) {
                        notify.forHttp(inventoryService.insertInventoryCardOrderReconciliation(model.cardOrderId, model.inventoryCount))
                        .then(function () {
                            getTodaysInventoryCount(model.cardOrderId)
                            resetGroupLookup();
                        });
                    } else {
                        //Pop modal to tell them there is a discrepancy and let them choose whether or not to proceed.
                        inventoryDiscrepancyModalPopupTrigger();
                    }
                });
        }

        function getTodaysInventoryCount(orderId) {
            notify.forHttp(inventoryService.getTodaysInventoryCount(orderId), { startTranslationId: "MANAGE_CARDS.CONFIRM_INVENTORY.NOTIFICATION_MESSAGES.LOADING_TODAYS_INVENTORY_COUNT" })
                .then(data => setTodaysInventoryCount(data));
        }

        function setTodaysInventoryCount(data) {
            model.todaysInventory = data.cardOrderReconciliations;
        }

        function lookupGroupCardByFirstPrintText() {
            notify.forFormSubmission($scope.confirmInventoryForm, doLookupInventoryCard(model.card.firstPrintText));
        }

        function lookupGroupCardByLastPrintText() {
            notify.forFormSubmission($scope.confirmInventoryForm, doLookupInventoryCard(model.card.lastPrintText));
        }

        function handleCardSwipeSuccess(event, swipe) {
            doLookupInventoryCard(undefined, swipe.primaryAccountNumber);
        }

        function inventoryDiscrepancyModalPopup() {
            var modalInstance = $uibModal.open({
                templateUrl: "confirm-inventory/modal/inventoryDiscrepancyDialog.html",
                backdrop: "static",
                keyboard: false,
                controller: "ConfirmModal",
                scope: $scope
            });

            modalInstance.result.then(function (confirmed) {
                if (confirmed) {
                    model.inventoryCount.hasDiscrepancy = true;

                    notify.forHttp(inventoryService.insertInventoryCardOrderReconciliation(model.cardOrderId, model.inventoryCount))
                        .then(function () {
                            getTodaysInventoryCount(model.cardOrderId)
                            resetGroupLookup();
                        });
                } else { resetGroupLookup(); }
            });
        }

        function inventoryDiscrepancyModalPopupTrigger() {
            inventoryDiscrepancyModalPopup();
        }

        function displayPrintText(printText) {
            return model.canViewRestrictedCardNumbers ? printText : cardService.maskPrintText(printText);
        }
    }
}());