import htmlTemplate from './sale-summary.directive.html';
import _ from 'underscore';

(function () {
    "use strict";

    angular.module("cardspotWeb.areas.enterSales")
        .directive("emlSaleSummary", configDirective);

    function configDirective() {
        return {
            restrict: "E",
            scope: {
                cardsPreActivated: "=",
                editCards: "=",
                saleCompleted: "=",
                editSundries: "=",
                isTrailingSymbol: "=",
                currencySeparators: "=",
                fractionSize: "=",
                currentLanguage: "=",
                startOver: "="
            },
            controller: SaleSummaryController,
            controllerAs: "model",
            template: htmlTemplate
        };
    }

    SaleSummaryController.$inject = ["$scope", "cardFees", "notify", "preActivationService", "authService", "appConfig", "lookupValues",
        "activationService", "customerService", "sundryService", "CLAIMS", "DEFAULT_SALES_CHANNEL",
        "REGION_TYPES", "EVENT_NAMES", "integratedPaymentService", "$uibModal", "LOOKUP_TYPES", "$rootScope", "codeService", "limitService"];

    function SaleSummaryController($scope, cardFees, notify, preActivationService, authService, appConfig, lookupValues,
        activationService, customerService, sundryService, CLAIMS, DEFAULT_SALES_CHANNEL,
        REGION_TYPES, EVENT_NAMES, integratedPaymentService, $uibModal, LOOKUP_TYPES, $rootScope, codeService, limitService) {

        var model = this, defaultSalesChannel;
        var individual = "Individual";
        model.canAddFee = authService.hasClaim(CLAIMS.ADD_FEE);
        model.canDeleteFee = authService.hasClaim(CLAIMS.DELETE_FEE);
        model.canModifyFee = authService.hasClaim(CLAIMS.MODIFY_ACTIVATION_FEE);
        model.canLockCard = authService.canLockCard();
        model.cardsPreActivated = undefined;
        model.havePayments = havePayments;
        model.paymentsTotal = 0;
        model.remainingBalance = 0;
        model.completeSale = completeSale;
        model.showCompleteSale = showCompleteSale;
        model.cardsVisible = false;
        model.sundriesVisible = false;
        model.toggleCardVisibility = toggleCardVisibility;
        model.toggleSundryVisibility = toggleSundryVisibility;
        model.canAddCustomerInformation = authService.canAddCustomerInformation();
        model.sundrySubtotalDetail = sundrySubtotalDetail;
        model.amountExceedsThreshold = false;
        model.meetsSaleRequirements = meetsSaleRequirements;
        model.completeSaleInProgress = false;
        model.currentLanguage = $scope.currentLanguage;
        model.paymentToRemove = undefined;
        model.cancelSale = cancelSale;
        model.PRE_ACTIVATION_TYPES = LOOKUP_TYPES;
        model.completeSaleButtonEnabled = completeSaleButtonEnabled;

        function updateActivationCustomer() {
            model.activationCustomer = customerService.mapCustomerForActivation();
            if (model.activationCustomer) {
                model.activationCustomer.customerType = individual;
            }
        }

        $scope.$on(EVENT_NAMES.SHOWING_SALE_COMPLETE, function () {
            updateActivationCustomer();
        });

        $scope.$on(EVENT_NAMES.SHOWING_SALE_SUMMARY, function () {
            initModel();
        });

        function toggleCardVisibility() {
            model.cardsVisible = !model.cardsVisible;
        }

        function toggleSundryVisibility() {
            model.sundriesVisible = !model.sundriesVisible;
        }

        function showCompleteSale() {
            return havePayments() && model.remainingBalance <= 0;
        }

        lookupValues.getSalesChannels().then(function (data) {
            model.salesChannels = data;

            defaultSalesChannel = _.find(model.salesChannels, function (salesChannel) {
                return salesChannel.name === DEFAULT_SALES_CHANNEL;
            }) || model.salesChannels[0];
            initModel();
        });

        function initModel() {
            updateActivationCustomer();
            model.payments = [];
            model.note = "";
            if (!model.customer) {
                model.customer = model.activationCustomer ?
                    formatActivationCustomer() :
                    {
                        firstName: null,
                        lastName: null,
                        address1: null,
                        address2: null,
                        city: null,
                        state: null,
                        province: null,
                        country: null,
                        customerType: individual,
                        email: null,
                        fax: null,
                        faxExt: null,
                        middleName: null,
                        phone: null,
                        phoneExt: null,
                        postalCode: null,
                        identifier: null,
                        marketingOptIn: false,
                        birthDate: null,
                        kycDocumentType: null,
                        kycDocumentId: null,
                        kycExpiryDate: null,
                        requireCIP: false
                    };
            }
            model.salesChannel = defaultSalesChannel;
            model.lockTransactionOnActivation = false;
            model.completeSaleInProgress = false;
        }

        function formatActivationCustomer() {
            var customer = angular.copy(model.activationCustomer);
            if (customer && customer.country && customer.selectedRegion) {
                if (customer.country.regionType === REGION_TYPES.STATE) {
                    customer.state = customer.selectedRegion.regionKey;
                } else if (customer.country.regionType === REGION_TYPES.PROVINCE) {
                    customer.province = customer.selectedRegion.regionKey;
                }
            }
            customer.customerType = customer.isOrganization ? "Company" : individual;
            return customer;
        }

        function cancelSale() {
            var cancelConfirmModalInstance = $uibModal.open({
                templateUrl: "sale-summary/modal/cancelConfirmDialog.html",
                backdrop: "static",
                keyboard: false,
                size: "sm",
                controller: "ConfirmModal",
                scope: $scope
            })

            cancelConfirmModalInstance.result.then(function (confirmed) {
                if (confirmed) {
                    $scope.$broadcast('handleIntegratedReverseAll');
                    codeService.cancelAll();
                }
            });
        }
        $scope.$on('integratedReversalHandled', function (event, success) {
            if (success) {
                if (model.paymentToRemove) {
                    $scope.$broadcast('completeRemovePayment');
                }
                else {
                    model.payments = [];
                    preActivationService.reset();
                    sundryService.reset();
                    $scope.startOver();
                }
            }
        });

        function completeSale() {
            model.completeSaleInProgress = true;
            $scope.$broadcast('handleIntegratedPayments');
            $rootScope.selectedPaymentType = undefined;
        }

        $scope.$on('integratedPaymentsHandled', function (event, success) {
            if (success)
                activateCards();
            else
                model.completeSaleInProgress = false;
        });

        $scope.$on('cardsActivated', function (event, success) {
            var buyerDetails = undefined;
            var codesRedeemed = codeService.getCodesRedeemed();
            if (model.customer.country && model.customer.firstName && model.customer.lastName) {
                lookupValues.getCountryCode(model.customer.country.countryKey)
                    .then(function (response) {
                        buyerDetails = {
                            FirstName: truncateString(model.customer.firstName, 20),
                            LastName: truncateString(model.customer.lastName, 20),
                            Email: truncateString(model.customer.email, 100),
                            CardholderId: truncateString('', 30),
                            Address1: truncateString(model.customer.address1, 50),
                            Address2: truncateString(model.customer.address2, 50),
                            City: truncateString(model.customer.city, 30),
                            County: truncateString(model.customer.state, 30),
                            Country: response.data,
                            PostCode: truncateString(model.customer.postalCode, 10),
                            MobileCountryCode: '',
                            MobileNumber: ''
                        }
                        if (angular.isArray(codesRedeemed) && codesRedeemed.length > 0) {
                            codesRedeemed.forEach((x) => completeCodes(x, buyerDetails));
                        }
                        model.customer = undefined;
                        initModel();
                        customerService.reset();
                        $scope.saleCompleted();
                    })
            } else {
                if (angular.isArray(codesRedeemed) && codesRedeemed.length > 0) {
                    codesRedeemed.forEach((x) => completeCodes(x, buyerDetails));
                }
                model.customer = undefined;
                initModel();
                customerService.reset();
                $scope.saleCompleted();
            }
        })

        function truncateString(str, num) {
            if ((str && str.length <= num) || !str)
                return str;

            return str.slice(0, num);
        }

        function completeCodes(redeemedCode, buyerDetails) {
            codeService.redeemComplete(redeemedCode.code, redeemedCode.programUniqueTag, redeemedCode.sessionId, redeemedCode.externalId, redeemedCode.cardId, buyerDetails);
        }

        function activateCards() {
            activationService.setActivationCardCustomerEmail(model.customer.email);
            // need to determine what level the customer information is at
            if (model.supportsCustomerAtCardLevel) {
                activationService.activateCardPerCustomer(model.payments, model.salesChannel.name, model.note, model.lockTransactionOnActivation === true, model.posTransactionId)
                    .then(function () {
                        $scope.$broadcast('cardsActivated');
                        initModel();
                        $scope.saleCompleted();
                    });
            } else {
                //country in the model is an object; just need to send country name as string to activation call
                var customer;
                if (model.canAddCustomerInformation && ((model.customer.firstName && model.customer.lastName) || model.customer.companyName)) {
                    customer = angular.copy(model.customer);
                    customer.country = model.customer.country ? model.customer.country.countryName : "";
                    customer.state = model.customer.requireCIP && !model.customer.state ? "" : model.customer.state;
                    customer.postalCode = model.customer.requireCIP && !model.customer.postalCode ? "" : model.customer.postalCode;
                } else {
                    customer = null;
                }

                activationService.activate(model.payments, model.salesChannel.name, model.note, customer, model.lockTransactionOnActivation === true)
                    .then(function () {
                        $scope.$broadcast('cardsActivated');
                    });
            }
        }

        function updatePaymentsTotal() {
            model.paymentsTotal = _.reduce(model.payments, function (memo, payment) {
                return memo + payment.amount;
            }, 0);

            model.remainingBalance = Number(model.totalAmount.toFixed(2)) - Number(model.paymentsTotal.toFixed(2));
        }

        function havePayments() {
            return angular.isArray(model.payments) && model.payments.length > 0;
        }

        function getCurrencySymbol() {
            if (preActivationService.hasCards()) {
                return preActivationService.getCardsPreActivated()[0].bin.currency.currencySymbol;
            } else if (sundryService.hasSundries()) {
                return sundryService.getSundries()[0].currency.currencySymbol;
            }
            return null;
        }

        function updateModel() {
            var cards = preActivationService.getCardsPreActivated();
            var perCardFees = cardFees.getPerCardFees();
            var sundries = sundryService.getSundries();

            angular.forEach(cards, function (card) {
                card.perCardFee = _.find(perCardFees, function (fee) {
                    return fee.cardType === card.currentCardType;
                });

            });
            model.cardsPreActivated = cards;
            model.sundries = sundries;

            var flattenedCardsPreActivated = [];
            angular.forEach(cards, function (cardPreActivated) {
                if (cardPreActivated.cards) {
                    angular.forEach(cardPreActivated.cards, function (card) {
                        card.amount = cardPreActivated.amount;
                        card.perCardFee = cardPreActivated.perCardFee;
                        flattenedCardsPreActivated.push(card);
                    });
                } else {
                    flattenedCardsPreActivated.push(cardPreActivated);
                }
            });

            model.flattenedCardsPreActivated = flattenedCardsPreActivated;

            model.hasCards = preActivationService.hasCards();
            model.transactionFees = appConfig.selectedMerchantGroup().supportsCustomerAtCardLevel ? null : cardFees.getTransactionFees();
            model.haveTransactionFees = angular.isArray(model.transactionFees) && model.transactionFees.length > 0;
            model.currencySymbol = getCurrencySymbol();

            model.totalCardCount = _.reduce(cards, function (memo, card) {
                var cardCount = angular.isArray(card.cards) ? card.cards.length : 1;
                return memo + cardCount;
            }, 0);

            model.totalCardAmount = _.reduce(cards, function (memo, card) {
                var qty = angular.isArray(card.cards) ? card.cards.length : 1;
                return parseFloat((memo + (card.amount * qty)).toFixed(2));
            }, 0);

            model.totalFeeAmount = _.reduce(cards, function (memo, card) {
                var qty = angular.isArray(card.cards) ? card.cards.length : 1;
                return memo + (card.perCardFee ? card.perCardFee.perCardFee * qty : 0);
            }, 0) + _.reduce(model.transactionFees, function (memo, fee) {
                return memo + fee.amount;
            }, 0);

            model.amountExceedsThreshold = isAmlThresholdExceeded();
            
            model.hasFees = model.totalFeeAmount > 0;
            model.totalSundryCount = angular.isArray(model.sundries) && model.sundries.length > 0 ? getSundryCount() : 0;

            model.totalSundryAmount = _.reduce(model.sundries, function (memo, sundry) {
                return memo + sundry.subTotal;
            }, 0);

            model.hasSundries = model.totalSundryCount > 0;

            model.totalAmount = model.totalCardAmount + model.totalFeeAmount + model.totalSundryAmount;
            updatePaymentsTotal();

            if (model.hasCards) {
                model.paymentTypes = rejectIntegratedPaymentTypes(preActivationService.getPaymentTypes());
            } else {
                activationService.getPaymentTypes().then(function (data) {
                    model.paymentTypes = rejectIntegratedPaymentTypes(data);
                });
            }

            model.allowMarketingOptIn = preActivationService.getAllowMarketingOptIn();
            model.merchantGroupCountry = preActivationService.getMerchantGroupCountry();
            model.supportsCustomerAtCardLevel = appConfig.selectedMerchantGroup().supportsCustomerAtCardLevel;
        }

        $scope.$on(EVENT_NAMES.ON_CARD_PREACTIVATED_WITH_CODE, function () {
            if (appConfig.selectedMerchantGroup().clickAndCollectEnabled && $rootScope.currentPreactivationType === model.PRE_ACTIVATION_TYPES.REDEEM_CODE) {
                model.paymentTypes = _.filter(model.paymentTypes, function (type) {
                    return type.paymentType === "Code";
                })

                if (angular.isArray(model.paymentTypes) && model.paymentTypes.length === 1) {
                    $rootScope.selectedPaymentType = model.paymentTypes[0];
                    $rootScope.remainingBalance = model.remainingBalance;
                    $scope.$broadcast(EVENT_NAMES.ON_PAYMENT_TYPE_ARRAY_CHANGED);
                }
            }
        });

        function getSundryCount() {
            return model.sundries.map(sundry => sundry.quantity).reduce((prev, next) => prev + next);
        }

        function isAmlThresholdExceeded() {
            var hvtCardMaximumExemptionMinimum = limitService.getCardMaximumExemptionMinimumLimit()?.limit ?? 0;
            var hvtCardMaximumExemptionMaximum = limitService.getCardMaximumExemptionMaximumLimit()?.limit ?? 0;
            var cardsGreaterThanHvtCardMin = $scope.cardsPreActivated.filter(function (card) {
                if (hvtCardMaximumExemptionMinimum > 0)
                    return card.amount > hvtCardMaximumExemptionMinimum;
            })
            var cardsGreaterThanHvtCardMax = $scope.cardsPreActivated.filter(function (card) {
                if (hvtCardMaximumExemptionMaximum > 0)
                    return card.amount > hvtCardMaximumExemptionMaximum;
            })
            var hvtMinimumLimit = limitService.getHVTMinimumLimit()?.limit ?? 0;
            var hvtInternalApprovalLimit = limitService.getHVTInternalApprovalLimit()?.limit ?? 0;
            var hvtBankApprovalLimit = limitService.getHVTBankApprovalLimit()?.limit ?? 0;
            var maxActivationAmount = model.hasCards ? hvtMinimumLimit : 0;

            if ((hvtInternalApprovalLimit > 0 && model.totalCardAmount > hvtInternalApprovalLimit) ||
                (hvtBankApprovalLimit > 0 && model.totalCardAmount > hvtBankApprovalLimit)) {
                // I didn't localize this message because we are going to remove it and redirect them to the form inside of CSW.
                notify.showError("Your transaction amount has exceeded a limit and requires approval. Please fill out the High Value Transactions Form located <a href='https://info.emlpayments.com/resourcecenter' target='_blank'>here</a>.");
                //model.customer.requireCIP = true;
                return true;
            }

            if (cardsGreaterThanHvtCardMax?.length > 0) {
                // I didn't localize this message because we are going to remove it and redirect them to the form inside of CSW.
                notify.showError("One or more of the cards in this transaction has exceeded a limit and requires approval. Please fill out the Card Max Exception Request located <a href='https://info.emlpayments.com/resourcecenter' target='_blank'>here</a>.");
                //model.customer.requireCIP = true;
                return true;
            }

            if (model.totalCardAmount > 0 && maxActivationAmount > 0) {
                if (model.totalCardAmount > maxActivationAmount || cardsGreaterThanHvtCardMin?.length > 0) {
                    $scope.changeActiveTab(TAB_NAMES.CUSTOMER);
                    model.customer.requireCIP = true;
                    return true;
                }
            }
            model.customer.requireCIP = false;
            return false;
        }

        function rejectIntegratedPaymentTypes(allPaymentTypes) {
            var filteredPayments = _.reject(allPaymentTypes, function (paymentType) {
                return paymentType.isAuthorizable === true;
            });

            if (model.amountExceedsThreshold) {
                return _.filter(filteredPayments, function (paymentType) {
                    if (model.customer && model.customer.customerType === individual) {
                        return paymentType.allowedByAmlForConsumer === true;
                    } else {
                        return paymentType.allowedByAmlForCorporate === true;
                    }
                });
            }

            return filteredPayments;
        }

        $scope.FEES_TAB_SHOWN_EVENT = "fees-tab-shown";
        $scope.FEES_CHANGED_EVENT = "fees-changed";
        $scope.PAYMENTS_CHANGED_EVENT = "payments-changed";
        $scope.CUSTOMER_TYPE_CHANGED_EVENT = "customer-type-changed";
        $scope.CUSTOMER_TAB_SHOWN_EVENT = "customer-tab-shown";

        $scope.$on(EVENT_NAMES.SHOWING_SALE_SUMMARY, function () {
            updateModel();
            refreshFeeTab();
        });
        $scope.$on($scope.FEES_CHANGED_EVENT, function () {
            updateModel();
        });
        $scope.$on($scope.PAYMENTS_CHANGED_EVENT, function () {
            updatePaymentsTotal();
        });

        $scope.$on($scope.CUSTOMER_TYPE_CHANGED_EVENT, function () {
            updatePaymentTypes();
        });

        function updatePaymentTypes() {
            // we only need to address payment types available if the aml threshold is exceeded
            if (model.amountExceedsThreshold) {
                model.paymentTypes = rejectIntegratedPaymentTypes(preActivationService.getPaymentTypes());
            }
        }

        var TAB_NAMES = {
            PAYMENT: "payment",
            FEES: "fees",
            CUSTOMER: "customer",
            NOTES: "notes"
        };
        $scope.TAB_NAMES = TAB_NAMES;
        var activeTab = TAB_NAMES.PAYMENT;

        $scope.tabClass = function (tabName) {
            var cssClass = "";
            if (tabName === activeTab) {
                cssClass = "active";
            }

            if (tabName === TAB_NAMES.CUSTOMER && model.amountExceedsThreshold && !model.meetsSaleRequirements()) {
                cssClass = cssClass + " tab-alert";
            }

            return cssClass;
        };

        $scope.tabVisible = function (tabName) {
            return tabName === activeTab;
        };

        $scope.changeActiveTab = function (tab) {
            activeTab = tab;

            if (tab === TAB_NAMES.FEES) {
                $scope.$broadcast($scope.FEES_TAB_SHOWN_EVENT, {});
            }

            if (tab === TAB_NAMES.CUSTOMER) {
                $scope.$broadcast($scope.CUSTOMER_TAB_SHOWN_EVENT, {});
            }
        };

        function sundrySubtotalDetail(sundry) {
            if (sundry) {
                return sundry.quantity + " @ " + sundry.unitPrice;
            }
        }

        function meetsSaleRequirements() {
            var saleRequirementsMet = false;

            if (!model.amountExceedsThreshold) {
                saleRequirementsMet = true;
            }

            return saleRequirementsMet;
        }

        function refreshFeeTab() {
            if (activeTab === TAB_NAMES.FEES) {
                $scope.$broadcast($scope.FEES_TAB_SHOWN_EVENT, {});
            }
        }

        function completeSaleButtonEnabled() {
            var hvtInternalApprovalLimit = limitService.getHVTInternalApprovalLimit()?.limit ?? 0;
            var limitReachedAndCannotProceed = hvtInternalApprovalLimit > 0 && model.totalCardAmount > hvtInternalApprovalLimit;
            var enabled = true;

            if (!model.meetsSaleRequirements() || model.completeSaleInProgress) {
                enabled = false;
            }

            if (!limitReachedAndCannotProceed && model.customer.requireCIP && (model.customer.firstName && model.customer.lastName && model.customer.address1 && model.customer.city && model.customer.birthDate && model.customer.kycDocumentType && model.customer.kycDocumentId && model.customer.kycExpiryDate )) {
                enabled = true;
            }

            return enabled;
        }
    }
}());
