﻿(function () {
    "use strict";

    angular.module("cardspotWeb.areas.enterSales")
    .controller("SignageConfirm", SignageConfirm);

    SignageConfirm.$inject = ["$scope", "$uibModalInstance", "authService", "navigate"];

    function SignageConfirm($scope, $uibModalInstance, authService, navigate) {
        var model = this;
        model.hasDeclined = false;
        model.confirmSignage = confirmSignage;

        function confirmSignage(confirmed) {
            if (confirmed) {
                authService.confirmSignage();
                $uibModalInstance.close();
            } else {
                if (!model.hasDeclined) {
                    model.hasDeclined = true;
                } else {
                    $uibModalInstance.dismiss("cancel");
                    authService.removeConfirmSignage();
                    navigate.toOnlineResources();
                }
            }
        }
    }
}());
