import htmlTemplate from './lookup-invoices.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
        .config(configLookupInvoices);

    configLookupInvoices.$inject = ["$routeProvider", "NAV_URLS"];

    function configLookupInvoices($routeProvider, NAV_URLS) {

        $routeProvider
            .when(NAV_URLS.LOOKUP_INVOICES, {
                template: htmlTemplate,
                controller: "LookupInvoices",
                controllerAs: "model"
            });
    }

}());