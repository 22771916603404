﻿(function () {
    "use strict";

    angular.module("cardspotWeb.areas.confirmSignage")
        .controller("ConfirmSignage", ConfirmSignage);

    ConfirmSignage.$inject = ["authService", "navigate"];

    function ConfirmSignage (authService, navigate) {
        var model = this;

        model.confirmSignage = function (confirmed) {
            if (confirmed) {
                authService.confirmSignage();
                navigate.toReferrerOrDefault();
            }
            else {
                authService.removeConfirmSignage();
                navigate.toOnlineResources();
            }
        };
    }
}());