import homeTemplate from './home.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
    .config(configHome);

    configHome.$inject = ["$routeProvider", "NAV_URLS"];
    function configHome($routeProvider, NAV_URLS) {

                $routeProvider
                .when(NAV_URLS.HOME, {
                    template: homeTemplate,
                    controller: "Home",
                    controllerAs: "model"
                })
                .otherwise({ redirectTo: NAV_URLS.HOME });
            }

}());