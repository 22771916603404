import htmlTemplate from './test-swiper.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
        .config(testSwiper);

    testSwiper.$inject = ["$routeProvider", "NAV_URLS"];

    function testSwiper($routeProvider, NAV_URLS) {

        $routeProvider
            .when(NAV_URLS.TEST_SWIPER, {
                template: htmlTemplate,
                controller: "TestSwiper",
                controllerAs: "model",                
            });
    }

}());