import htmlTemplate from './card-fees.directive.html';
import _ from 'underscore';

(function() {
	"use strict";

	angular.module("cardspotWeb.areas.enterSales")
		.directive("emlCardFees", configDirective);

	function configDirective() {
		return {
			restrict: "E",
			scope: {
				feesTabShownEvent: "=",
				feesChangedEvent: "=",
                cardCount: "=",
                isTrailingSymbol: "=",
                currencySeparators: "=",
                fractionSize: "=",
                currentLanguage: "="
			},
			controller: CardFeesController,
			controllerAs: "model",
			template: htmlTemplate
		};
	}

    CardFeesController.$inject = ["$scope", "$log", "cardFees", "api", "notify", "preActivationService", "authService", "CLAIMS", "appConfig", "$uibModal"];

    function CardFeesController($scope, $log, cardFees, api, notify, preActivationService, authService, CLAIMS, appConfig, $uibModal) {
		var model = this,
			allCardTypes;
		model.hasPerCardFees = hasPerCardFees;
		model.hasTransactionFees = hasTransactionFees;
		model.canAddFee = authService.hasClaim(CLAIMS.ADD_FEE);
		model.canDeleteFee = authService.hasClaim(CLAIMS.DELETE_FEE);
		model.canModifyFee = authService.hasClaim(CLAIMS.MODIFY_ACTIVATION_FEE);
		model.addPerCardFeeVisible = false;
		model.addTransactionFeeVisible = false;
		model.addPerCardFee = addPerCardFee;
		model.cancelAddPerCardFee = cancelAddPerCardFee;
		model.showAddPerCardFee = showAddPerCardFee;
		model.perCardFee = {
			perCardFee: undefined,
			cardType: undefined
		};
		model.transactionFee = {
			amount: undefined,
			feeType: "Processing"
		};
		model.removePerCardFee = removePerCardFee;
		model.removeTransactionFee = removeTransactionFee;
		model.cancelRemovePerCardFee = cancelRemovePerCardFee;
		model.isPerCardFeeToBeRemoved = isPerCardFeeToBeRemoved;
		model.isTransactionFeeToBeRemoved = isTransactionFeeToBeRemoved;
		model.cancelRemoveTransactionFee = cancelRemoveTransactionFee;
		model.showAddTransactionFee = showAddTransactionFee;
		model.addTransactionFee = addTransactionFee;
		model.cancelAddTransactionFee = cancelAddTransactionFee;
		model.applyPerCardFeeChange = applyPerCardFeeChange;
		model.applyTransactionFeeChange = applyTransactionFeeChange;
		model.showingEditForPerCardFee = showingEditForPerCardFee;
		model.showingEditForTransactionFee = showingEditForTransactionFee;
		model.perCardFeeEdited = undefined;
		model.editingPerCardFee = editingPerCardFee;
		model.editingTransactionFee = editingTransactionFee;
		model.cancelEditPerCardFee = cancelEditPerCardFee;
		model.cancelEditTransactionFee = cancelEditTransactionFee;
		model.anyPerCardFeesToAdd = anyPerCardFeesToAdd;
		model.getCardCount = getCardCount;
		model.removeCardFeeConfirmModalPopup = removeCardFeeConfirmModalPopup;
		model.removeCardFeeConfirmModalPopupTrigger = removeCardFeeConfirmModalPopupTrigger;
		model.removeTransactionFeeConfirmModalPopup = removeTransactionFeeConfirmModalPopup;
        model.removeTransactionFeeConfirmModalPopupTrigger = removeTransactionFeeConfirmModalPopupTrigger;
        model.currentLanguage = $scope.currentLanguage;
		$scope.key = function(hashKey) {
			return hashKey.replace(":", "");
		};

		function updateModel() {
			model.canAddTransactionFee = model.canAddFee && !appConfig.selectedMerchantGroup().supportsCustomerAtCardLevel;
			model.perCardFees = cardFees.getPerCardFees();
			model.transactionFees = model.canAddTransactionFee ? cardFees.getTransactionFees() : null;
			model.distinctCardTypes = getDistinctCardTypes();
			model.currencySymbol = getCurrencySymbol();
			model.perCardFeeTotal = _.reduce(model.perCardFees, function (memo, fee) {
			    return memo + fee.perCardFee;
			}, 0) * model.getCardCount();
			model.transactionFeeTotal = _.reduce(model.transactionFees, function (memo, fee) {
			    return memo + fee.amount;
            }, 0);
		}

		function handleFeeChange() {
			updateModel();
			$scope.$emit($scope.feesChangedEvent, {});
		}

		function ensureRemoveFeePermission(removeCallback) {
			if (model.canDeleteFee) {
				removeCallback();
			}
		}

		function cancelEditPerCardFee(perCardFee) {
			angular.copy(model.perCardFeeEditedMaster, perCardFee);
			resetEditPerCardFee();
		}

		function cancelEditTransactionFee(transactionFee) {
			angular.copy(model.transactionFeeEditedMaster, transactionFee);
			resetEditTransactionFee();
		}

		function editingPerCardFee(perCardFee) {
			model.perCardFeeEditedMaster = angular.copy(perCardFee);
			model.perCardFeeEdited = perCardFee;
		}
		function editingTransactionFee(transactionFee) {
			model.transactionFeeEditedMaster = angular.copy(transactionFee);
			model.transactionFeeEdited = transactionFee;
		}

		function showingEditForPerCardFee(perCardFee) {
			return model.perCardFeeEdited
				&& model.perCardFeeEdited.cardType === perCardFee.cardType;
		}

		function showingEditForTransactionFee(transactionFee) {
			return model.transactionFeeEdited
			&& model.transactionFeeEdited.$$hashKey === transactionFee.$$hashKey;
		}

		function applyPerCardFeeChange() {
			notify.forFormSubmission($scope.editPerCardFeeAmountForm, function() {
				if (model.canModifyFee) {
					resetEditPerCardFee();
					handleFeeChange();
				}
			});
		}

		function applyTransactionFeeChange() {
			notify.forFormSubmission($scope.editTransactionFeeForm, function () {
				if (model.canModifyFee) {
					resetEditTransactionFee();
					handleFeeChange();
				}
			});
		}

		function resetEditPerCardFee() {
			$scope.editPerCardFeeAmountForm.$setPristine();
			model.perCardFeeEditedMaster = undefined;
			model.perCardFeeEdited = undefined;
		}

		function resetEditTransactionFee() {
			$scope.editTransactionFeeForm.$setPristine();
			model.transactionFeeEditedMaster = undefined;
			model.transactionFeeEdited = undefined;
		}

		function anyPerCardFeesToAdd() {
			return _.some(model.distinctCardTypes, function(cardType) {
				return !_.find(model.perCardFees, function(fee) {
					return fee.cardType === cardType.cardType;
				});
			});
		}

		function removeTransactionFee(transactionFee, confirmed) {
			ensureRemoveFeePermission(
				function() {
					if (confirmed) {
						cardFees.removeTransactionFee(transactionFee);
						handleFeeChange();
						model.transactionFeeToRemove = undefined;
					}
				});
		}

		function removePerCardFee(perCardFee, confirmed) {
			ensureRemoveFeePermission(
				function() {
					if (confirmed) {
						cardFees.removePerCardFee(perCardFee);
						handleFeeChange();
						model.perCardFeeToRemove = undefined;
						resetEditPerCardFee();
					}
				});
		}

		function cancelRemovePerCardFee() {
			removePerCardFeeConfirmationVisible(false);
			model.perCardFeeToRemove = undefined;
		}
		function cancelRemoveTransactionFee() {
			removeTransactionFeeConfirmationVisible(false);
			model.transactionFeeToRemove = undefined;
		}

		function removePerCardFeeConfirmationVisible(isVisible) {
			model.showRemovePerCardFeeConfirmation = isVisible;
		}
		function removeTransactionFeeConfirmationVisible(isVisible) {
			model.showRemoveTransactionFeeConfirmation = isVisible;
		}

		function isPerCardFeeToBeRemoved(perCardFee) {
			return perCardFee && model.perCardFeeToRemove && perCardFee.$$hashKey === model.perCardFeeToRemove.$$hashKey;
		}
		function isTransactionFeeToBeRemoved(transactionFee) {
			return transactionFee && model.transactionFeeToRemove && transactionFee.$$hashKey === model.transactionFeeToRemove.$$hashKey;
		}

		$scope.$on($scope.feesTabShownEvent, function () {
			updateModel();
		});

		function showAddPerCardFee() {
			model.addPerCardFeeVisible = true;
		}

		function initPerCardFeeModel() {
            model.perCardFee.perCardFee = undefined;
		}

		function showAddTransactionFee() {
			model.addTransactionFeeVisible = true;
		}
		function initTransactionFeeModel() {
			model.transactionFee.amount = undefined;
		}

		initPerCardFeeModel();
		initTransactionFeeModel();

		function addPerCardFee() {
			notify.forFormSubmission($scope.addPerCardFeeForm, function () {
				var perCardFee = angular.copy(model.perCardFee);
				if (!cardFees.hasPerCardFeeForCardType(perCardFee)) {
					cardFees.addPerCardFee(perCardFee);
					handleFeeChange();
					cancelAddPerCardFee();
				} else {
					notify.showError("ENTER_SALES.FEES.CANNOT_ADD_PER_CARD_FEE_FOR_CARD_TYPE");
				}
			});
		}

		function cancelAddPerCardFee() {
			initPerCardFeeModel();
			$scope.addPerCardFeeForm.$setPristine();
			model.addPerCardFeeVisible = false;
		}

		function addTransactionFee() {
			notify.forFormSubmission($scope.addTransactionFeeForm, function () {
					cardFees.addTransactionFee(angular.copy(model.transactionFee));
					handleFeeChange();
					cancelAddTransactionFee();
			});
		}

		function cancelAddTransactionFee() {
			initTransactionFeeModel();
			$scope.addTransactionFeeForm.$setPristine();
			model.addTransactionFeeVisible = false;
		}

		function hasPerCardFees() {
			return angular.isArray(model.perCardFees) && model.perCardFees.length > 0;
		}

		function hasTransactionFees() {
			return angular.isArray(model.transactionFees) && model.transactionFees.length > 0;
		}

		function setAllCardTypes() {
			allCardTypes = preActivationService.getCardTypes();//these are all card types valid for the merchant group/program
		}

		function getDistinctCardTypes() {
			setAllCardTypes();
			var distinctCardTypeKeys = _.chain(preActivationService.getCardsPreActivated())
				.groupBy(function (card) {
					return card.currentCardType;
				})
				.keys()
				.value()
				;


			var distinctCardTypes = _.filter(allCardTypes, function(aCardType) {
				return _.find(distinctCardTypeKeys, function(cardTypeKey) {
					return aCardType.cardType === cardTypeKey;
				});
			});

			model.perCardFee.cardType = _.first(distinctCardTypes).cardType;

			return distinctCardTypes;
		}

		function getCurrencySymbol() {
			return preActivationService.getCurrencySymbol();
		}

		function getCardCount() {
		    return $scope.cardCount;
		}

		function removeCardFeeConfirmModalPopup(cardFee) {
		    var removeCardFeeModalInstance = $uibModal.open({
		        templateUrl: "enter-sales/card-fees/modal/removeCardFeeConfirmDialog.html",
		        backdrop: "static",
		        keyboard: false,
		        size: "sm",
		        controller: "ConfirmModal",
		        scope: $scope
		    })

		    removeCardFeeModalInstance.result.then(function (status) {
		        removePerCardFee(cardFee, status);
		    });
		}

		function removeCardFeeConfirmModalPopupTrigger(cardFee) {
		    model.removeCardFeeConfirmModalPopup(cardFee);
		}

		function removeTransactionFeeConfirmModalPopup(tranFee) {
		    var removeTransactionFeeModalInstance = $uibModal.open({
		        templateUrl: "enter-sales/card-fees/modal/removeTransactionFeeConfirmDialog.html",
		        backdrop: "static",
		        keyboard: false,
		        size: "sm",
		        controller: "ConfirmModal",
		        scope: $scope
		    })

		    removeTransactionFeeModalInstance.result.then(function (status) {
		        removeTransactionFee(tranFee, status);
		    });
		}

		function removeTransactionFeeConfirmModalPopupTrigger(tranFee) {
		    model.removeTransactionFeeConfirmModalPopup(tranFee);
		}
	}
}());
