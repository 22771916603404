import htmlTemplate from './transfer-card.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
        .config(configTransferCard);

    configTransferCard.$inject = ["$routeProvider", "NAV_URLS", "CLAIMS"];

    function configTransferCard($routeProvider, NAV_URLS, CLAIMS) {

        $routeProvider
            .when(NAV_URLS.TRANSFER_CARD, {
                template: htmlTemplate,
                controller: "TransferCard",
                controllerAs: "model",
                requiredClaim: CLAIMS.TRANSFER_CARD
            });                    
    }
}());