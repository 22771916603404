﻿(function () {
    "use strict";

    angular.module("angular.emlpayments")
        .factory("navigate", ["$timeout", "$location", "authService", "NAV_URLS", function ($timeout, $location, authService, NAV_URLS) {

            var referrerInternal,
                defaultUrl = "/";

            return {
                to: to,
                setReferrer: setReferrer,
                setDefaultUrl: setDefaultUrl,
                toReferrerOrDefault: toReferrerOrDefault
            };


            function to(url) {
                $timeout(function () {
                    $location.path(url);
                });
            }

            function toReferrerOrDefault() {
                if (referrerInternal) {
                    to(referrerInternal);
                }
                else {
                    setDefaultUrl();
                    to(defaultUrl);
                }
            }

            function setReferrer(referrer) {
                referrerInternal = referrer;
            }

            function setDefaultUrl() {
                if (authService.canActivateCard()) {
                    defaultUrl = NAV_URLS.ENTER_SALES
                } else if (authService.canSearchCustomer()) {
                    defaultUrl = NAV_URLS.LOOKUP_CUSTOMER
                } else if (authService.canLookupCard()) {
                    defaultUrl = NAV_URLS.LOOKUP_CARD
                } else if (authService.canViewActivationDetails()) {
                    defaultUrl = NAV_URLS.LOOKUP_SALES
                } else if (authService.canViewCardFrontOrders()) {
                    defaultUrl = NAV_URLS.FULFILLMENT
                } else {
                    defaultUrl = NAV_URLS.ONLINE_RESOURCES;
                }
            }
        }]);
}());