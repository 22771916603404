(function () {
    "use strict";

    angular
        .module("cardspotWeb.areas.login")
        .controller("Login", Login);

    Login.$inject = ["$rootScope","$scope", "$routeParams", "notify", "authService", "navigate", "appConfig", "storeLinks", "store", "STORE_KEYS", "Idle", "Keepalive", "SF_EVENTS"];

    function Login($rootScope, $scope, $routeParams, notify, authService, navigate, appConfig, storeLinks, store, STORE_KEYS, Idle, Keepalive, SF_EVENTS) {

        var model = this;
        model.showChangePassword = false;
        model.lockUsername = null;
        model.login = login;        
        model.forgotPasswordLinkUrl = storeLinks.forgotPasswordLinkUrl;
        model.changePasswordLinkUrl = storeLinks.storeLinkUrl;
        model.currentDate = new Date();
        model.messageDateEnd = new Date("2022-8-30");

        $scope.started = false;

        activate();

        function activate() {
            $scope.user = {
                username: undefined,
                password: undefined
            }; 
                       
            if($routeParams.id){                
                $scope.user.username = $routeParams.id;
                model.lockUsername = true;
            }                                                     
        }
        
        function requiresPasswordChange() {            
            return authService.isPasswordChangeRequired();
        }
        function navigateToNext() {
            if (!authService.signageConfirmed) {
                navigate.toConfirmSignage();
            }
            else {
                navigate.toReferrerOrDefault();
            }
        }
        function login() {
            notify.forFormSubmission($scope.loginForm, function () {

                notify.forHttp(authService.login($scope.user.username, $scope.user.password),
                    {
                        startTranslationId: "LOGIN.FORM.PROGRESS_TEXT",
                        errorTranslationId: "LOGIN.FORM.INVALID_CREDENTIALS"
                    })
                .then(function () {
                    //login success, authService has persisted the token
                    //initialize application configuration for the user
                        authService.removePasswordChangeRequiredKey();
                        appConfig.init(function () {
                            // Added this in case there are two or more workstations in the same location.
                            // This will check to see if the selected location is in the active locations and if it isn't remove it and 
                            // force them to select one from the list.
                            if (appConfig.selectedMerchantGroup() && appConfig.selectedMerchantGroup().uniqueTag) {
                                appConfig.getMerchantGroupLocations(appConfig.selectedMerchantGroup().uniqueTag).then(function (locations) {
                                    var configLocation = appConfig.getSelectedLocation();
                                    if (configLocation && !locations.find(l => l.location === configLocation.location)) {
                                        store.remove(STORE_KEYS.SELECTED_LOCATIONS);
                                    }
                                    navigateToNext();
                                });
                            } else {
                                navigateToNext();
                            }
                            if (!Idle.running()) {
                                Idle.watch();
                                Keepalive.start();
                            }
                            
                        });
                        handleLoggedInSuccess();
                }, function(){                    
                    if (requiresPasswordChange()) {
                       notify.showError("GLOBAL.PASSWORD_CHANGE_REQUIRED");   
                       model.showChangePassword = true; 
                    }                                                           
                });
            });
        }

        function handleLoggedInSuccess() {
            $rootScope.$emit(SF_EVENTS.LOGGED_IN_SUCCESS);
        }
    }
}());