﻿(function () {
    "use strict";

    angular.module("cardspotWeb")
        .config(configModule);

    configModule.$inject = ["$provide"];

    function configModule($provide) {
        $provide.decorator("navigate", decorateNavigateService);
    }

    decorateNavigateService.$inject = ["$delegate", "NAV_URLS"];

    function decorateNavigateService($delegate, NAV_URLS) {

        $delegate.toEnterSales = function () {
            $delegate.to(NAV_URLS.ENTER_SALES);
        };
        
        $delegate.toHome = function (referrer) {
            $delegate.setReferrer(referrer);
            $delegate.to(NAV_URLS.HOME);
        };

        $delegate.toLogin = function (referrer) {
            $delegate.setReferrer(referrer);
            $delegate.to(NAV_URLS.LOGIN);
        };

        $delegate.toReauthenticate = function (referrer, userName) {
            $delegate.setReferrer(referrer);
            $delegate.to(NAV_URLS.LOGIN + "/" + userName);
        };

        $delegate.toConfirmSignage = function (referrer) {
            $delegate.setReferrer(referrer);
            $delegate.to(NAV_URLS.CONFIRM_SIGNAGE);
        };

        $delegate.toApplicationConfiguration = function (referrer) {
            $delegate.setReferrer(referrer);
            $delegate.to(NAV_URLS.APPLICATION_CONFIGURATION);
        };

        $delegate.toLookupSales = function () {
            $delegate.to(NAV_URLS.LOOKUP_SALES);
        };

        $delegate.toLookupCard = function () {
            $delegate.to(NAV_URLS.LOOKUP_CARD);
        };

        $delegate.toViewActivationDetails = function (id) {
            $delegate.to(NAV_URLS.LOOKUP_SALES + "/" + id);
        };
        
        $delegate.toTransferCard = function () {
            $delegate.to(NAV_URLS.TRANSFER_CARD);
        };

        $delegate.toOnlineResources = function () {
            $delegate.to(NAV_URLS.ONLINE_RESOURCES);
        }

        $delegate.setDefaultUrl();

        return $delegate;
    }

}());