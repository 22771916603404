import htmlTemplate from './purchaser-information.directive.html';

(function() {
    "use strict";

    angular.module("cardspotWeb.areas.hvtRequest")
        .directive("emlPurchaserInformation", configDirective);

    function configDirective() {
        return {
            restrict: "E",
            scope: {
                purchaserInformation: "=",
                purchaserInformationComplete: "=",
                countries: "=",
                merchantGroupCountry: "=",
                kycDocumentTypes: "=",
                previousStep:"="
            },
            controller: PurchaserInformationController,
            controllerAs: "model",
            template: htmlTemplate,
            require: ['^form', 'ngModel']
        };
    }

	PurchaserInformationController.$inject = ["$q", "$scope", "$translate", "REGION_TYPES", "lookupValues", "$rootScope", "SF_EVENTS"];

	function PurchaserInformationController($q, $scope, $translate, REGION_TYPES, lookupValues, $rootScope, SF_EVENTS) {
        var model = this;
        model.showRegionSelection = false;
        model.purchaserInformationRegionModel = purchaserInformationRegionModel;
        model.onExpirationDateChange = onExpirationDateChange;
        model.showExpiredDateMessage = false;
        model.kycDocumentTypes;
        model.regions = [];
        model.selectedCountry;
        model.countrySelectionChanged = countrySelectionChanged;
        model.onBirthdateChange = onBirthdateChange;
        model.showInvalidBirthdateMessage = false;

        $scope.datePopup = {
            opened: false
        };

        $scope.openDatePicker = function () {
            $scope.datePopup.opened = true;
        };

        $scope.kycDatePopup = {
            opened: false
        };

        $scope.openKYCDatePicker = function () {
            $scope.kycDatePopup.opened = true;
        };

        $rootScope.$on(SF_EVENTS.HVT_REQUEST_PURCHASER_STEP_SELECTED, function(event, args) {
            initRegions();
        })

        init();

        function init() {
            if ($scope.merchantGroupCountry && $scope.purchaserInformation && !$scope.purchaserInformation.country) {
                $scope.purchaserInformation.country = $scope.merchantGroupCountry;
                countrySelectionChanged();
            }
        }

        function countrySelectionChanged() {
            $scope.purchaserInformation.postalCode = null;
            $scope.purchaserInformation.state = null;
            $scope.purchaserInformation.province = null;
            initRegions();
        }

        function initRegions() {
            if ($scope.purchaserInformation.country) {
                var countryKey = $scope.purchaserInformation.country.countryKey;
                lookupValues.getRegions(countryKey)
                    .then(function (regions) {
                        model.regions = regions;
                        model.showRegionSelection = $scope.purchaserInformation.country && hasRegions() && $scope.purchaserInformation.country.regionType !== REGION_TYPES.NONE;
                    });
            }
        }

        function purchaserInformationRegionModel(newRegion) {
            if ($scope.purchaserInformation && $scope.purchaserInformation.country) {
                if ($scope.purchaserInformation.country.regionType === REGION_TYPES.STATE) {
                    return arguments.length ? ($scope.purchaserInformation.state = newRegion) : $scope.purchaserInformation.state ?? "";
                } else {
                    return arguments.length ? ($scope.purchaserInformation.province = newRegion) : $scope.purchaserInformation.province ?? "";
                }
            }
        }

        function hasRegions() {
            return angular.isArray(model.regions) && model.regions.length > 0;
        }

        function onBirthdateChange(date) {
            var age = getAge(date);
            if (date > Date.now() || age < 18) {
                model.showInvalidBirthdateMessage = true;
                $scope.purchaserInformation.birthDate = undefined;
            } else {
                model.showInvalidBirthdateMessage = false;
            }
        }

        function onExpirationDateChange(date) {
            if (date < Date.now()) {
                model.showExpiredDateMessage = true;
                $scope.purchaserInformation.kycExpiryDate = undefined;
            } else {
                model.showExpiredDateMessage = false;
            }
        }

        function getAge(dateString) {
            var today = new Date();
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }
    }
}());
