import _ from "underscore";
import confirmTemplate from './confirm.html';

(function () {
    "use strict";
    angular.module("cardspotWeb.areas.lookupCustomer")
        .controller("LookupCustomer", LookupCustomer);

    LookupCustomer.$inject = ["$scope", "$uibModal", "lookupValues", "REGION_TYPES", "notify", "customerService", "authService", 
        "navigate", "preActivationService", "cardService", "$anchorScroll", "$timeout"];

    function LookupCustomer($scope, $uibModal, lookupValues, REGION_TYPES, notify, customerService,
        authService, navigate, preActivationService, cardService, $anchorScroll, $timeout) {
        var company = "company";
        var individual = "individual";
        var model = this;

        model.isEditMode = false;
        model.search = {
            searchType: null,
            organizationName: null,
            firstName: null,
            lastName: null,
            phoneNumber: null,
            identifier: null,
            email: null,
            kybAssessmentLevel: {
                id: null,
                name: null,
                isDefaultApproval: null
            },
            companyRegistrationNumber: null,
            assessmentFromDate: null,
            assessmentToDate: null
        };

        model.sort = sort;
        model.sortKey = "transactionId";
        model.reverse = true;
        $scope.currentPage = 1;
        $scope.pageSize = 10;

        model.customers = null;
        model.reset = reset;
        model.customerIsIndividual = customerIsIndividual;
        model.customerIsCompany = customerIsCompany;
        model.isIndividual = isIndividual;
        model.isCompany = isCompany;
        model.regions = [];
        model.countrySelectionChanged = countrySelectionChanged;
        model.showRegionSelection = false;
        model.customerRegionModel = customerRegionModel;
        model.selectCustomer = selectCustomer;
        model.currentCustomer = null;
        model.searchCustomers = searchCustomers;
        model.canEditCustomer = canEditCustomer;
        model.toggleEditMode = toggleEditMode;
        model.resetCustomer = resetCustomer;
        model.editCustomer = editCustomer;
        model.isValidSearchCriteria = isValidSearchCriteria;
        model.confirmActivateCards = confirmActivateCards;
        model.canActivateCard = authService.canActivateCard();
        model.canViewRestrictedCardNumbers = authService.canViewRestrictedCardNumbers();
        model.maskPrintText = cardService.maskPrintText;
        model.pageChanged = pageChanged;
        model.assessmentLevelChange = assessmentLevelChange;
        $scope.master = {};

        $scope.datePopup = {
            opened: false
        };

        $scope.kybStartDatePopup = {
            opened: false
        }

        $scope.kybEndDatePopup = {
            opened: false
        }

        $scope.openDatePicker = function () {
            $scope.datePopup.opened = true;
        };

        $scope.openKybStartDatePicker = function () {
            $scope.kybStartDatePopup.opened = true;
        }

        $scope.openKybEndDatePicker = function () {
            $scope.kybEndDatePopup.opened = true;
        }

        init();

        function init() {

            var searchCustomerCriteria = customerService.getSearchCustomerCriteria();
            if (searchCustomerCriteria) {
                model.search.searchType = searchCustomerCriteria.isOrganization ? company : individual;
                model.search.organizationName = searchCustomerCriteria.name;
                model.search.firstName = searchCustomerCriteria.firstName;
                model.search.lastName = searchCustomerCriteria.lastName;
                model.search.phoneNumber = searchCustomerCriteria.phoneNumber;
                model.search.email = searchCustomerCriteria.email;
                model.search.kybAssessmentLevel = searchCustomerCriteria.kybAssessmentLevel;
                model.search.companyRegistrationNumber = searchCustomerCriteria.companyRegistrationNumber;
                model.search.assessmentFromDate = searchCustomerCriteria.assessmentFromDate;
                model.search.assessmentToDate = searchCustomerCriteria.assessmentToDate;
                doSearchCustomers(searchCustomerCriteria);
            }

            lookupValues.getCountries()
                .then(function (data) {
                    model.countries = data;
                });

            getRiskAssessmentLevels();
        }

        function confirmActivateCards() {
            var modalInstance = $uibModal.open({
                animation: false,
                template: confirmTemplate,
                controller: "ConfirmActivateCards",
                size: "sm"
            });

            modalInstance.result.then(function () {
                customerService.setActivateCardCustomer(model.currentCustomer);
                preActivationService.reset();
                navigate.toEnterSales();
            });
        }

        function searchCustomers() {

            model.customers = null;
            model.currentCustomer = null;

            var searchCustomerModel = {
                isOrganization: !isIndividual(),
                name: model.search.organizationName,
                firstName: model.search.firstName,
                lastName: model.search.lastName,
                phoneNumber: model.search.phoneNumber ? model.search.phoneNumber.replace(/[^0-9]/g, '') : model.search.phoneNumber,
                identifier: model.search.identifier,
                email: model.search.email,
                kybAssessmentLevel: model.search.kybAssessmentLevel?.name,
                companyRegistrationNumber: model.search.companyRegistrationNumber,
                assessmentFromDate: model.search.assessmentFromDate,
                assessmentToDate: model.search.assessmentToDate
            };
            notify.forFormSubmission($scope.searchCustomerForm, doSearchCustomers(searchCustomerModel));
        }

        function doSearchCustomers(searchCustomerModel) {
            notify.dismissErrorAlert();
            if (!isValidSearchCriteria()) {
                notify.showError("LOOKUP_CUSTOMER.FORM.INVALID_SEARCH_CRITERIA");
                return;
            }

            notify.forHttp(customerService.searchCustomers(searchCustomerModel), { startTranslationId: "LOOKUP_CUSTOMER.FORM.PROGRESS_TEXT" })
                .then(function (response) {
                    setSearchResults(response);
                });
        }

        function isValidSearchCriteria() {
            return (model.search.firstName || model.search.lastName || model.search.phoneNumber
                || model.search.organizationName || model.search.email || model.search.identifier
                || (model.search.kybAssessmentLevel && model.search.kybAssessmentLevel.name && model.search.kybAssessmentLevel.name !== "--Select Assessment Level--") || model.search.companyRegistrationNumber ||
                model.search.assessmentFromDate || model.search.assessmentToDate);
        }

        function setSearchResults(response) {

            if (!response.succeeded || !response.customers || response.customers.length === 0) {
                notify.showError("LOOKUP_CUSTOMER.NO_RESULTS_FOUND");
            } else {
                model.customers = response.customers;
                model.customers.forEach(c => {
                    if (c.birthDate) {
                        c.birthDate = new Date(c.birthDate);
                    }
                    if (c.assessmentDate) {
                        c.assessmentDate = new Date(c.assessmentDate);
                    }
                });
            }
        }

        function countrySelectionChanged() {

            model.currentCustomer.postalCode = null;
            model.currentCustomer.state = null;
            model.currentCustomer.province = null;

            if (model.currentCustomer.selectedCountry) {
                lookupValues.getRegions(model.currentCustomer.selectedCountry.countryKey)
                    .then(function (regions) {
                        model.regions = regions;
                        model.showRegionSelection = model.currentCustomer.selectedCountry && hasRegions() && model.currentCustomer.selectedCountry.regionType !== REGION_TYPES.NONE;
                    });
            }
        }

        function customerRegionModel(newRegion) {
            if (model.currentCustomer && model.currentCustomer.country) {
                if (model.currentCustomer.country.regionType === REGION_TYPES.STATE) {
                    return arguments.length ? (model.currentCustomer.state = newRegion) : model.currentCustomer.state;
                } else {
                    return arguments.length ? (model.currentCustomer.province = newRegion) : model.currentCustomer.province;
                }
            }
        }

        function hasRegions() {
            return angular.isArray(model.regions) && model.regions.length > 0;
        }

        function reset() {
            model.search.firstName = null;
            model.search.lastName = null;
            model.search.organizationName = null;
            model.search.phoneNumber = null;
            model.search.identifier = null;
            model.search.email = null;
            model.search.kybAssessmentLevel.name = null;
            model.search.kybAssessmentLevel.id = null;
            model.search.kybAssessmentLevel.isDefaultApproval = null;
            model.search.companyRegistrationNumber = null;
            model.search.assessmentFromDate = null;
            model.search.assessmentToDate = null;
            model.customers = null;
            model.currentCustomer = null;
            $scope.searchCustomerForm.$setPristine();
        }

        function isIndividual() {
            return model.search.searchType === individual;
        }

        function isCompany() {
            return model.search.searchType === company;
        }

        function customerIsIndividual() {
            reset();
            model.search.searchType = individual;
        }

        function customerIsCompany() {
            reset();
            model.search.searchType = company;
        }

        function sort(columnKey) {
            model.sortKey = columnKey;
            model.reverse = !model.reverse;
        }

        function selectCustomer(customer) {
            if (model.currentCustomer === customer) {
                model.currentCustomer = null;
                return;
            } else {
                model.isEditMode = false;
            }
            model.currentCustomer = customer;
            var country = _.find(model.countries, function (country) {
                return country.countryName === customer.country;
            });
            model.currentCustomer.selectedCountry = country;
            if (country) {
                lookupValues.getRegions(customer.country)
                    .then(function (regions) {
                        model.regions = regions;
                        model.showRegionSelection = model.currentCustomer.selectedCountry && hasRegions() && model.currentCustomer.selectedCountry.regionType !== REGION_TYPES.NONE;
                        var region = _.find(model.regions, function (region) {
                            return region.regionKey === customer.region;
                        });
                        model.currentCustomer.selectedRegion = region;
                    });
            }
            doGetCustomerCards(customer);
        }

        function toggleEditMode(customer) {
            $scope.master = angular.copy(customer);
            model.isEditMode = !model.isEditMode;
        }

        function doGetCustomerCards(customer) {

            notify.forHttp(customerService.searchCustomerCards(customer.isOrganization, customer.customerId, $scope.currentPage, $scope.pageSize), { startTranslationId: "LOOKUP_CUSTOMER.FORM.GET_CUSTOMER_CARDS_PROGRESS_TEXT" })
                .then(function (response) {
                    customer.cards = response.cards;
                    model.totalItems = response.totalItems;

                    $timeout($anchorScroll("currentCustomerSection"))
                });
        }

        function canEditCustomer() {
            return authService.canAddCustomerInformation();
        }

        function resetCustomer(customer) {
            angular.copy($scope.master, customer);
            model.isEditMode = false;
        }

        function editCustomer(customer) {

            customer.isOrganization = !isIndividual();
            customer.country = customer.selectedCountry ? customer.selectedCountry.countryKey : null;
            customer.region = customer.selectedRegion ? customer.selectedRegion.regionKey : null;

            notify.forHttp(customerService.updateCustomer(customer), { startTranslationId: "LOOKUP_CUSTOMER.FORM.UPDATE_CUSTOMER_PROGRESS_TEXT" })
                .then(function () {
                    model.isEditMode = false;
                });
        }

        function pageChanged(newPageNumber, customer) {
            if (newPageNumber) {
                $scope.currentPage = newPageNumber;
                doGetCustomerCards(customer);
            }
        }

        function getRiskAssessmentLevels() {
            lookupValues.getRiskAssessmentLevels()
                .then(function(resp) {
                    model.riskAssessmentLevels = resp.data;
                })
        }

        function assessmentLevelChange() {
            console.log(model.search.kybAssessmentLevel);
        }
    }
}());
