﻿import htmlTemplate from './lookup-card.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
        .config(configLookupCard);

    configLookupCard.$inject = ["$routeProvider", "NAV_URLS", "CLAIMS","paginationTemplateProvider"];

    function configLookupCard($routeProvider, NAV_URLS, CLAIMS, paginationTemplateProvider) {

        $routeProvider
            .when(NAV_URLS.LOOKUP_CARD + "/:printText?", {
                template: htmlTemplate,
                controller: "LookupCard",
                controllerAs: "model",
                requiredClaim: CLAIMS.LOOKUP_CARD
            });
            
        paginationTemplateProvider.setPath("./transactionPaginationTemplate.html");

    }
}());