(function () {
    "use strict";

    angular.module("cardspotWeb")
        .factory("globalHttpInterceptor", globalHttpInterceptor);

    globalHttpInterceptor.$inject = ["$rootScope", "$q", "$log", "apiUrlFormatter", "moment"];

    function globalHttpInterceptor ($rootScope, $q, $log, apiUrlFormatter, moment) {
        return {
            "request": function (config) {
                var clientDateTime = "Client-Date-Time",
                    acceptLanguage = "Accept-Language";

                if (!(config.url.startsWith("http") || config.url.endsWith(".json") || config.url.endsWith(".html") || config.url.includes("OpenApi"))) {
                    //convenient way to format all $http URLs outside of static files and calls to the oauth service
                    config.url = apiUrlFormatter.format(config.url);
                }

                config.headers = config.headers || {};
                if(!config.headers[clientDateTime]){
                    config.headers[clientDateTime] = moment().format();
                }

                if(!config.headers[acceptLanguage]){
                    config.headers[acceptLanguage] = $rootScope.currentLanguage().key;
                }

                return config;
            },
            //// optional method
            //'requestError': function (rejection) {
            //    // do something on error
            //    if (canRecover(rejection)) {
            //        return responseOrNewPromise
            //    }
            //    return $q.reject(rejection);
            //},
            //// optional method
            // 'response': function (response) {
            //     // do something on success
            //     return response;
            // },
            //// optional method
            "responseError": function (rejection) {
               if (rejection.status === 401) {
                   $rootScope.$broadcast("unauthorized", {});
                } else if (rejection.status === 400) {
                    if(rejection.data && rejection.data.error && rejection.data.error === $rootScope.EVENT_NAMES.PASSWORD_CHANGE_REQUIRED) {
                        $rootScope.$broadcast($rootScope.EVENT_NAMES.PASSWORD_CHANGE_REQUIRED, {});
                    }
               }
               return $q.reject(rejection);
            }
        };
    }


}());