(function() {
    "use strict";

    angular.module("angular.emlpayments")
        .factory("modal", modalServiceFactory);

    modalServiceFactory.$inject = ["$uibModal", "$uibModalStack"];

    function modalServiceFactory($uibModal, $uibModalInstance) {
        return {
            trigger: function(template, scope, size = "md") {
                return $uibModal.open({
                    templateUrl: template,
                    backdrop: "static",
				    keyboard: false,
                    controller: "ConfirmModal",
                    scope: scope,
                    size: size
                });
            },
            close: function() {
                $uibModalInstance.close(false);
            }
        };
    }
}());