﻿(function () {
	"use strict";
	
	angular.module("angular.emlpayments")
		.factory("storeLinks", StoreLinks);

	StoreLinks.$inject = ["$location"];

	function StoreLinks($location) {
		var host = $location.host().toLowerCase(),
		qaRegex = /(alpha|bravo|charlie|delta|foxtrot-[0-9]{1,2})/,
		qaMatch = host.match(qaRegex),
		testRegex = /(xtest|uat)/,
		testMatch = host.match(testRegex);

			var storeLinkUrl, infoCenterReportsUrl, cardspotMobileInstallUrl, forgotPasswordLinkUrl;
			var documentsRepositoryUrl = "http://documents.emlpayments.com/CardSpot/DocumentRepository/";
			var sslLabsLinkUrl = "https://www.ssllabs.com/ssltest/viewMyClient.html";

			if (host == "cardspot.storefinancial.net") {
				//prod
				storeLinkUrl =  "https://storelink.storefinancial.net/Default.aspx",
				infoCenterReportsUrl = "https://info.storefinancial.net/Default.aspx",
				cardspotMobileInstallUrl = infoCenterReportsUrl + "?StoreFinancial.Web.Security.ReferrerParam=%2fSupport%2fSupport.aspx%3finstall%3dcsm2",
				forgotPasswordLinkUrl = "https://storelink.storefinancial.net/Modules/SecurityModule/Default.aspx?action=ForgotPassword";
			} 
			else if (qaMatch) {
				storeLinkUrl = "https://storelink-" + qaMatch[0] + ".storefinancialdev.net/default.aspx",
				infoCenterReportsUrl = "https://info-" + qaMatch[0] + ".storefinancialdev.net/default.aspx",
				cardspotMobileInstallUrl = infoCenterReportsUrl + "?StoreFinancial.Web.Security.ReferrerParam=%2fSupport%2fSupport.aspx%3finstall%3dcsm2",
				forgotPasswordLinkUrl = "https://storelink-" + qaMatch[0] + ".storefinancialdev.net/Modules/SecurityModule/Default.aspx?action=ForgotPassword";
			} 
			else if (testMatch) {
				storeLinkUrl = "https://storelink." + testMatch[0] + ".storefinancial.net/Default.aspx",
				infoCenterReportsUrl = "https://info." + testMatch[0] + ".storefinancial.net/Default.aspx",
				cardspotMobileInstallUrl = infoCenterReportsUrl + "?StoreFinancial.Web.Security.ReferrerParam=%2fSupport%2fSupport.aspx%3finstall%3dcsm2",
				forgotPasswordLinkUrl = "https://storelink." + testMatch[0] + ".storefinancial.net/Modules/SecurityModule/Default.aspx?action=ForgotPassword";
			}
			else if (host == "localhost") {
				storeLinkUrl = "http://local.storefinancialdev.net/storelink/Default.aspx",
				infoCenterReportsUrl = "http://local.storefinancialdev.net/cardpointreports/Default.aspx",
				cardspotMobileInstallUrl = "http://local.storefinancialdev.net/cardpointreports/Default.aspx?StoreFinancial.Web.Security.ReferrerParam=%2fSupport%2fSupport.aspx%3finstall%3dcsm2",
				forgotPasswordLinkUrl = "http://local.storefinancialdev.net/StoreLink/Modules/SecurityModule/Default.aspx?action=ForgotPassword";
			}


		return {
			storeLinkUrl,
			infoCenterReportsUrl,
			cardspotMobileInstallUrl,
		    forgotPasswordLinkUrl,
            sslLabsLinkUrl,
            documentsRepositoryUrl
		};
	}
}());