﻿(function() {
	"use strict";

	angular.module("cardspotWeb.areas.campaigns")
		.controller("Campaigns", Campaigns);

	Campaigns.$inject = ["$scope", "$translate", "notify", "campaignService", "authService"];

	function Campaigns($scope, $translate, notify, campaignService, authService) {
		var model = this;

		model.add = {
			newCampaignDescription: null
		};

		model.loadActiveCampaigns = loadActiveCampaigns;
		model.loadInactiveCampaigns = loadInactiveCampaigns;
		model.canManageCampaigns = authService.canManageCampaigns();
		model.saveNewMerchantGroupCampaign = saveNewMechantGroupCampaign;
		model.activeCampaignsPageChanged = activeCampaignPageChanged;
		model.activeCampaignPageSizeChanged = activeCampaignPageSizeChanged;
		model.inactiveCampaignsPageChanged = inactiveCampaignPageChanged;
		model.inactiveCampaignPageSizeChanged = inactiveCampaignPageSizeChanged;
		model.activeSortChanged = activeSortChanged;
		model.inactiveSortChanged = inactiveSortChanged;
		model.editingActiveData = {};
		model.editingInactiveData = {};
		model.modifyActive = modifyActiveCampaign;
		model.modifyInactive = modifyInactiveCampaign;
		model.updateActive = updateActiveCampaign;
		model.updateInactive = updateInactiveCampaign;
		model.cancelActiveUpdate = cancelActiveUpdate;
		model.cancelInactiveUpdate = cancelInactiveUpdate;

		$scope.pageSizeOptions = [5, 10, 25, 50];
		$scope.pageSize = 10;
		$scope.activeCampaignPageSize = 10;
		$scope.activeCampaignCurrentPage = 1;
		$scope.inactiveCampaignPageSize = 10;
		$scope.inactiveCampaignCurrentPage = 1;
		$scope.activeSortAscending = true;
		$scope.inactiveSortAscending = true;

		model.pagination = {
			pageSize: $scope.pageSizeOptions[1]
		};

		var TAB_NAMES = {
			ACTIVE: "active",
			INACTIVE: "inactive"
		};

		$scope.TAB_NAMES = TAB_NAMES;
		var activeTab = TAB_NAMES.ACTIVE;

		init();

		function init() {
			loadActiveCampaigns();
			getAllMerchantGroupCampaigns();
		}
		
		function resetCampaigns() {
			model.activeCampaigns = null;
			model.inactiveCampaigns = null;
		}

		function getAllMerchantGroupCampaigns() {
			campaignService.getAllMerchantGroupCampaigns()
				.then(response => setAllMerchantGroupCampaigns(response));
		}
		
		function loadActiveCampaigns() {
			activeTab = TAB_NAMES.ACTIVE;
			notify.forHttp(campaignService.getActiveCampaigns(
				$scope.activeCampaignCurrentPage,
				$scope.activeCampaignPageSize,
				$scope.activeSortAscending), { startTranslationId: 'CAMPAIGNS.FORM.LOADING_ACTIVE_CAMPAIGNS_TEXT' })
            .then(data => setActiveCampaigns(data));
		}

		function loadInactiveCampaigns() {
			activeTab = TAB_NAMES.INACTIVE;
			notify.forHttp(campaignService.getInactiveCampaigns(
				$scope.inactiveCampaignCurrentPage,
				$scope.inactiveCampaignPageSize,
				$scope.inactiveSortAscending), { startTranslationId: 'CAMPAIGNS.FORM.LOADING_INACTIVE_CAMPAIGNS_TEXT' })
            .then(data => setInactiveCampaigns(data));
		}

		function refreshActiveCampaignGrid() {
		    loadActiveCampaigns();
		    getAllMerchantGroupCampaigns();
		}

		function refreshInactiveCampaignsGrid() {
		    loadInactiveCampaigns();
		    getAllMerchantGroupCampaigns();
		}

		function setAllMerchantGroupCampaigns(response) {
			model.allCampaigns = response.data;
		}

		function setActiveCampaigns(data) {
			resetCampaigns();
			model.activeCampaigns = data.items;
			model.activeCampaigns.filteredItems = data.filteredItems;
			model.activeCampaigns.totalItems = data.totalItems;
			for (var i = 0, length = model.activeCampaigns.length; i < length; i++) {
				model.editingActiveData[model.activeCampaigns[i].merchantGroupCampaignId] = false;
			}
		}

		function setInactiveCampaigns(data) {
			resetCampaigns();
			model.inactiveCampaigns = data.items;
			model.inactiveCampaigns.filteredItems = data.filteredItems;
			model.inactiveCampaigns.totalItems = data.totalItems;
			for (var i = 0, length = model.inactiveCampaigns.length; i < length; i++) {
				model.editingInactiveData[model.inactiveCampaigns[i].merchantGroupCampaignId] = false;
			}
		}

		function saveNewMechantGroupCampaign() {
			notify.dismissErrorAlert();

			if (isDuplicateCampaign(model.add.newCampaignDescription)) {
			    notify.showError("CAMPAIGNS.FORM.VALIDATION_TEXT.CAMPAIGN_ALREADY_ADDED");
			    resetAddMerchantGroupForm();
				return;
			}

			if (model.canManageCampaigns) {
				notify.forHttp(campaignService.addMerchantGroupCampaign(model.add.newCampaignDescription),
					{ startTranslationId: 'CAMPAIGNS.FORM.INSERTING_NEW_MERCHANT_GROUP_CAMPAIGN' })
					.then(function () {
						resetAddMerchantGroupForm();
						loadActiveCampaigns();
						getAllMerchantGroupCampaigns();
					});
			}
		}

		function isDuplicateCampaign(campaignDescription) {
			var isDupe = true;

			if (campaignDescription && model.allCampaigns) {
				isDupe = model.allCampaigns.findIndex(c => c.description.toLowerCase() === campaignDescription.toLowerCase()) > -1;
			}

			return isDupe;
		}

		function activeCampaignPageChanged(newPageNumber) {
			if (newPageNumber) {
				$scope.activeCampaignCurrentPage = newPageNumber;
				loadActiveCampaigns();
			}
		}

		function inactiveCampaignPageChanged(newPageNumber) {
			if (newPageNumber) {
				$scope.inactiveCampaignCurrentPage = newPageNumber;
				loadInactiveCampaigns();
			}
		}

		function activeCampaignPageSizeChanged() {
			if (model.pagination.pageSize !== $scope.activeCampaignPageSize) {
				$scope.activeCampaignPageSize = model.pagination.pageSize;
				loadActiveCampaigns();
			}
		}

		function inactiveCampaignPageSizeChanged() {
			if (model.pagination.pageSize !== $scope.inactiveCampaignPageSize) {
				$scope.inactiveCampaignPageSize = model.pagination.pageSize;
				loadInactiveCampaigns();
			}
		}

		function activeSortChanged() {
			$scope.activeSortAscending = !$scope.activeSortAscending;
			loadActiveCampaigns();
		}

		function inactiveSortChanged() {
			$scope.inactiveSortAscending = !$scope.inactiveSortAscending;
			loadInactiveCampaigns();
		}

		function resetAddMerchantGroupForm() {
			model.add.newCampaignDescription = null;
		}

		function modifyActiveCampaign(rowData) {
			model.editingActiveData[rowData.merchantGroupCampaignId] = true;
		}

		function modifyInactiveCampaign(rowData) {
			model.editingInactiveData[rowData.merchantGroupCampaignId] = true;
		}

		function updateActiveCampaign(rowData) {
			if (model.canManageCampaigns) {
				notify.forHttp(campaignService.updateMerchantGroupCampaign(rowData),
						{ startTranslationId: 'CAMPAIGNS.FORM.SAVING_CAMPAIGN_TEXT' })
					.then(function () {
						model.editingActiveData[rowData.merchantGroupCampaignId] = false;
						if (!rowData.isActive) {
							removeFromArray(model.activeCampaigns, rowData);
						}
						getAllMerchantGroupCampaigns();
					});
			}
		}

		function updateInactiveCampaign(rowData) {
			if (model.canManageCampaigns) {
				notify.forHttp(campaignService.updateMerchantGroupCampaign(rowData),
						{ startTranslationId: 'CAMPAIGNS.FORM.SAVING_CAMPAIGN_TEXT' })
					.then(function () {
						model.editingInactiveData[rowData.merchantGroupCampaignId] = false;
						if (rowData.isActive) {
							removeFromArray(model.inactiveCampaigns, rowData);
						}
						getAllMerchantGroupCampaigns();
					});
			}
		}

		function removeFromArray(array, item) {
			var index = array.indexOf(item);
			if (index > -1) {
				array.splice(index, 1);
			}
		}

		function cancelActiveUpdate(rowData) {
		    notify.dismissErrorAlert();
		    refreshActiveCampaignGrid();
            model.editingActiveData[rowData.merchantGroupCampaignId] = false;
			
		}

		function cancelInactiveUpdate(rowData) {
		    notify.dismissErrorAlert();
		    refreshInactiveCampaignsGrid();
		    model.editingInactiveData[rowData.merchantGroupCampaignId] = false;
		}

		$scope.tabClass = function (tabName) {
			var cssClass = "";
			if (tabName === activeTab) {
				cssClass = "active";
			}
			return cssClass;
		}

		$scope.tabVisible = function (tabName) {
			return tabName === activeTab;
		}
	}
}());