﻿(function () {
    "use strict";

    angular.module("cardspotWeb.areas.manageLocations")
		.controller("ManageLocations", ManageLocations);

    ManageLocations.$inject = ["$scope", "$translate", "notify", "authService", "merchantGroupLocationsService", "store", "STORE_KEYS"];

    function ManageLocations($scope, $translate, notify, authService, merchantGroupLocationsService, store, STORE_KEYS) {
        var model = this;

        model.add = {
            newLocationDescription: null
        };

        model.loadActiveLocations = loadActiveLocations;
        model.loadInactiveLocations = loadInactiveLocations;
        model.canManageLocations = authService.canAddSoftwareClientInstance();
        model.saveNewLocation = saveNewLocation;
        model.activeLocationsPageChanged = activeLocationPageChanged;
        model.activeLocationPageSizeChanged = activeLocationPageSizeChanged;
        model.inactiveLocationsPageChanged = inactiveLocationPageChanged;
        model.inactiveLocationPageSizeChanged = inactiveLocationPageSizeChanged;
        model.activeSortChanged = activeSortChanged;
        model.inactiveSortChanged = inactiveSortChanged;
        model.editingActiveData = {};
        model.editingInactiveData = {};
        model.modifyActive = modifyActiveLocation;
        model.modifyInactive = modifyInactiveLocation;
        model.updateActive = updateActiveLocation;
        model.updateInactive = updateInactiveLocation;
        model.updateCancel = cancelUpdate;

        $scope.pageSizeOptions = [5, 10, 25, 50];
        $scope.pageSize = 10;
        $scope.activeLocationPageSize = 10;
        $scope.activeLocationCurrentPage = 1;
        $scope.inactiveLocationPageSize = 10;
        $scope.inactiveLocationCurrentPage = 1;
        $scope.activeSortAscending = true;
        $scope.inactiveSortAscending = true;

        model.pagination = {
            pageSize: $scope.pageSizeOptions[1]
        };

        var TAB_NAMES = {
            ACTIVE: "active",
            INACTIVE: "inactive"
        };

        $scope.TAB_NAMES = TAB_NAMES;
        var activeTab = TAB_NAMES.ACTIVE;

        init();

        function init() {
            loadActiveLocations();
            getAllLocations();
        }

        function resetLocations() {
            model.activeLocations = null;
            model.inactiveLocations = null;
        }

        function getAllLocations() {
            merchantGroupLocationsService.getAllLocations()
			    .then(response => setAllLocations(response));
        }

        function loadActiveLocations() {
            activeTab = TAB_NAMES.ACTIVE;
            notify.forHttp(merchantGroupLocationsService.getActiveLocations(
				$scope.activeLocationCurrentPage,
				$scope.activeLocationPageSize,
				$scope.activeSortAscending), { startTranslationId: 'LOCATIONS.FORM.LOADING_ACTIVE_LOCATIONS_TEXT' })
            .then(data => setActiveLocations(data));
        }

        function loadInactiveLocations() {
            activeTab = TAB_NAMES.INACTIVE;
            notify.forHttp(merchantGroupLocationsService.getInactiveLocations(
				$scope.inactiveLocationCurrentPage,
				$scope.inactiveLocationPageSize,
				$scope.inactiveSortAscending), { startTranslationId: 'LOCATIONS.FORM.LOADING_INACTIVE_LOCATIONS_TEXT' })
            .then(data => setInactiveLocations(data));
        }

        function setAllLocations(response) {
            model.allLocations = response.data;
        }

        function setActiveLocations(data) {
            resetLocations();
            model.activeLocations = data.items;
            model.activeLocations.filteredItems = data.filteredItems;
            model.activeLocations.totalItems = data.totalItems;
            for (var i = 0, length = model.activeLocations.length; i < length; i++) {
                model.editingActiveData[model.activeLocations[i].locationId] = false;
            }
        }

        function setInactiveLocations(data) {
            resetLocations();
            model.inactiveLocations = data.items;
            model.inactiveLocations.filteredItems = data.filteredItems;
            model.inactiveLocations.totalItems = data.totalItems;
            for (var i = 0, length = model.inactiveLocations.length; i < length; i++) {
                model.editingInactiveData[model.inactiveLocations[i].locationId] = false;
            }
        }

        function saveNewLocation() {
            notify.dismissErrorAlert();

            if (isDuplicateLocation(model.add.newLocationDescription)) {
                notify.showError("LOCATIONS.FORM.VALIDATION_TEXT.LOCATION_ALREADY_ADDED");
                resetAddLocationForm();
                return;
            }

            if (model.canManageLocations) {
                notify.forHttp(merchantGroupLocationsService.addLocation(model.add.newLocationDescription),
					{ startTranslationId: 'LOCATIONS.FORM.INSERTING_NEW_MERCHANT_GROUP_LOCATION' })
					.then(function () {
					    resetAddLocationForm();
					    init();
					});
            }
        }

        function isDuplicateLocation(locationDescription) {
            var isDupe = true;

            if (locationDescription && model.allLocations) {
                isDupe = model.allLocations.findIndex(l => l.location.toLowerCase() === locationDescription.toLowerCase()) > -1;
            }

            return isDupe;
        }

        function refreshActiveLocationsGrid() {
            loadActiveLocations();
            getAllLocations();
        }

        function refreshInactiveLocationsGrid() {
            loadInactiveLocations();
            getAllLocations();
        }

        function activeLocationPageChanged(newPageNumber) {
            if (newPageNumber) {
                $scope.activeLocationCurrentPage = newPageNumber;
                loadActiveLocations();
            }
        }

        function inactiveLocationPageChanged(newPageNumber) {
            if (newPageNumber) {
                $scope.inactiveLocationCurrentPage = newPageNumber;
                loadInactiveLocations();
            }
        }

        function activeLocationPageSizeChanged() {
            if (model.pagination.pageSize !== $scope.activeLocationPageSize) {
                $scope.activeLocationPageSize = model.pagination.pageSize;
                loadActiveLocations();
            }
        }

        function inactiveLocationPageSizeChanged() {
            if (model.pagination.pageSize !== $scope.inactiveLocationPageSize) {
                $scope.inactiveLocationPageSize = model.pagination.pageSize;
                loadInactiveLocations();
            }
        }

        function activeSortChanged() {
            $scope.activeSortAscending = !$scope.activeSortAscending;
            loadActiveLocations();
        }

        function inactiveSortChanged() {
            $scope.inactiveSortAscending = !$scope.inactiveSortAscending;
            loadInactiveLocations();
        }

        function resetAddLocationForm() {
            model.add.newLocationDescription = null;
        }

        function modifyActiveLocation(rowData) {
            model.editingActiveData[rowData.locationId] = true;
        }

        function modifyInactiveLocation(rowData) {
            model.editingInactiveData[rowData.locationId] = true;
        }

        function updateActiveLocation(rowData) {
            if (model.canManageLocations) {
                var selectedLocation = store.get(STORE_KEYS.SELECTED_LOCATIONS);
                if (model.activeLocations.length > 1) {
                    notify.forHttp(merchantGroupLocationsService.updateLocation(rowData),
                        { startTranslationId: 'LOCATIONS.FORM.SAVING_LOCATION_TEXT' })
                        .then(function () {
                            model.editingActiveData[rowData.locationId] = false;
                            if (!rowData.isActive) {
                                removeFromArray(model.activeLocations, rowData);
                            }
                            if (selectedLocation[0].location === rowData.location) {
                                store.remove(STORE_KEYS.SELECTED_LOCATIONS);
                            }

                            refreshActiveLocationsGrid();
                        });
                } else {
                    notify.showError('LOCATIONS.NOTIFICATION_MESSAGES.KEEP_ONE_ACTIVE_LOCATION');
                }
            }
        }

        function updateInactiveLocation(rowData) {
            if (model.canManageLocations) {
                notify.forHttp(merchantGroupLocationsService.updateLocation(rowData),
						{ startTranslationId: 'LOCATIONS.FORM.SAVING_LOCATION_TEXT' })
					.then(function () {
					    model.editingInactiveData[rowData.locationId] = false;
					    if (rowData.isActive) {
					        removeFromArray(model.inactiveLocations, rowData);
					    }
					    
					    refreshInactiveLocationsGrid();
					});
            }
        }

        function removeFromArray(array, item) {
            var index = array.indexOf(item);
            if (index > -1) {
                array.splice(index, 1);
            }
        }

        function cancelUpdate(rowData) {
            model.editingActiveData[rowData.locationId] = false;
            model.editingInactiveData[rowData.locationId] = false;
        }

        $scope.tabClass = function (tabName) {
            var cssClass = "";
            if (tabName === activeTab) {
                cssClass = "active";
            }
            return cssClass;
        }

        $scope.tabVisible = function (tabName) {
            return tabName === activeTab;
        }
    }
}());